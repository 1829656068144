<template>
    <canvas id="myCode" :width="width" :height="height" @click="drawPic"></canvas>
</template>

<script>
export default {
    name: 'myCode',
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    props: {
        fontSizeMin: {
            type: Number,
            default: 28
        },
        fontSizeMax: {
            type: Number,
            default: 42
        },
        backgroundColorMin: {
            type: Number,
            default: 240
        },
        backgroundColorMax: {
            type: Number,
            default: 254
        },
        colorMin: {
            type: Number,
            default: 10
        },
        colorMax: {
            type: Number,
            default: 60
        },
        lineColorMin: {
            type: Number,
            default: 70
        },
        lineColorMax: {
            type: Number,
            default: 230
        },
        dotColorMin: {
            type: Number,
            default: 50
        },
        dotColorMax: {
            type: Number,
            default: 255
        },
        width: {
            type: Number,
            default: 100
        },
        height: {
            type: Number,
            default: 40
        },
        modelValue: {
            type: String
        }
    },
    data () {
        return {
            identifyCode: '4567'
        }
    },
    mounted () {
        this.drawPic()
    },
    methods: {
        // 生成一个随机数
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        // 生成一个随机的颜色
        randomColor(min, max) {
            const r = this.randomNum(min, max)
            const g = this.randomNum(min, max)
            const b = this.randomNum(min, max)
            return 'rgb(' + r + ',' + g + ',' + b + ')'
        },
        drawPic() {
            this.identifyCode = this.randomNum(0, 9) + '' + this.randomNum(0, 9) + this.randomNum(0, 9) + this.randomNum(0, 9)
            this.$emit('change', this.identifyCode)
            const canvas = document.getElementById('myCode')
            const ctx = canvas.getContext('2d')
            ctx.textBaseline = 'bottom'
            // 绘制背景
            ctx.fillStyle = this.randomColor(this.backgroundColorMin, this.backgroundColorMax)
            ctx.fillRect(0, 0, this.width, this.height)
            // 绘制文字
            for (let i = 0; i < this.identifyCode.length; i++) {
                this.drawText(ctx, this.identifyCode[i], i)
            }
            this.drawLine(ctx)
            this.drawDot(ctx)
        },
        drawText(ctx, txt, i) {
            ctx.fillStyle = this.randomColor(this.colorMin, this.colorMax)
            ctx.font = this.randomNum(this.fontSizeMin, this.fontSizeMax) + 'px SimHei'
            const x = (i + 1) * (this.width / (this.identifyCode.length + 1))
            const y = this.randomNum(this.fontSizeMax, this.height - 5)
            var deg = this.randomNum(-45, 45)
            // 修改坐标原点和旋转角度
            ctx.translate(x, y)
            ctx.rotate(deg * Math.PI / 180)
            ctx.fillText(txt, 0, 0)
            // 恢复坐标原点和旋转角度
            ctx.rotate(-deg * Math.PI / 180)
            ctx.translate(-x, -y)
        },
        drawLine(ctx) {
            // 绘制干扰线
            for (let i = 0; i < 8; i++) {
                ctx.strokeStyle = this.randomColor(this.lineColorMin, this.lineColorMax)
                ctx.beginPath()
                ctx.moveTo(this.randomNum(0, this.width), this.randomNum(0, this.height))
                ctx.lineTo(this.randomNum(0, this.width), this.randomNum(0, this.height))
                ctx.stroke()
            }
        },
        drawDot(ctx) {
            // 绘制干扰点
            for (let i = 0; i < 100; i++) {
                ctx.fillStyle = this.randomColor(0, 255)
                ctx.beginPath()
                ctx.arc(this.randomNum(0, this.width), this.randomNum(0, this.height), 1, 0, 2 * Math.PI)
                ctx.fill()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>