<template>
    <div>
        <div class="secret">
                            <form action="">
                                <div class="flex just-start align-center secret-block">
                                    <div class="flex just-start secret-label">
                                        <span class="secret-xing">*</span>
                                        <label for="">手机号：</label>
                                    </div>
                                    <div>
                                        <input type="text" class="secret-input secret-placeholder" placeholder="请输入手机号" v-model="phone">
                                    </div>
                                </div>
                                <div class="flex just-start align-center secret-block">
                                    <div class="flex just-start secret-label">
                                        <span class="secret-xing">*</span>
                                        <label for="">验证码：</label>
                                    </div>
                                    <div class="relative">
                                        <input type="text" class="secret-input secret-placeholder" placeholder="请输入验证码" v-model="verifycode">
                                        <p class="secret-code" id="secret-code" @click="getCode">{{ code }}</p>
                                    </div>
                                </div>
                                <div class="flex just-start align-center secret-block">
                                    <div class="flex just-start secret-label">
                                        <span class="secret-xing">*</span>
                                        <label for="">新密码：</label>
                                    </div>
                                    <div>
                                        <el-input placeholder="请设置新密码" style="width: 340px;" v-model="new_password" show-password></el-input>
                                        <!-- <input type="text" class="secret-input" placeholder="请输入手机号" v-model="new_password"> -->
                                    </div>
                                </div>
                                <div class="flex just-start align-center secret-block">
                                    <div class="flex just-start secret-label">
                                        <span class="secret-xing">*</span>
                                        <label for="">新密码：</label>
                                    </div>
                                    <div>
                                        <el-input placeholder="请再次输入新密码" style="width: 340px;" v-model="repassword" show-password></el-input>
                                        <!-- <input type="text" class="secret-input" placeholder="请输入手机号" v-model="repassword"> -->
                                    </div>
                                </div>
                                <button type="button" @click="updatePasword">提交</button>
                            </form>
                        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            code: '获取验证码',
            flag: false,
            timer: null,
            phone: '',
            verifycode: '',
            new_password: '',
            repassword: '',
            num: 0
        }
    },
    methods: {
        getCode() {
            if(this.flag) {
                return
            }
            if(this.phone == '') {
                this.$message({
                    message: '请输入手机号',
                    type: 'warning'
                });
                return
            }
            this.$https.post('/api/Users/getSmsSend',{mobile: this.phone,type: 'modifyPwd' })
            .then(() => {
                this.flag = true
                this.num = 60
                this.djs()
            })
            .catch(() => {
                clearTimeout(this.timer)
                this.timer = null
                this.flag = false
                this.code = '获取验证码'
            })
        },
        djs() {
            if(this.num > 0) {
                this.num = --this.num
                this.code = this.num
                this.timer = setTimeout(this.djs,1000)
            }else {
                clearTimeout(this.timer)
                this.timer = null
                this.flag = false
                this.code = '获取验证码'
            }
        },
        updatePasword() {
            const loading = this.showLoading()
            const token = JSON.parse(localStorage.getItem('userInfo')).token
            const data = {
                token: token,
                code: this.verifycode,
                new_password: this.new_password,
                repassword: this.repassword
            }
            this.$https.post('/api/Users/modifyPwd',data).then(res => {
                this.$message({
                    message: res.message,
                    type: 'success'
                });
            }).finally(() => {
                loading.close()
            })
        }
    }
}
</script>