<template>
  <main class="agent_detail my-background">
    <section class="section1 index-container">
      <div class="crumbs">
        <img src="../../assets/img/z-icon1.png" />
        当前所在位置：<a href="index.html">旺点来</a> >
        <a href="agent.html">找代办</a> >
        <a class="active">{{ dbDetail.title }}</a>
      </div>
    </section>
    <section class="section2 index-container my-detail1">
      <p class="title">{{ dbDetail.title }}</p>
      <p class="text">
        更新时间：{{ dbDetail.update_time }}&emsp;&emsp;火热值：<b>{{
          dbDetail.fiery
        }}</b>
      </p>
      <ul>
        <li>
          <b>服务区域：</b><span>{{ dbDetail.city_name }}</span>
        </li>
        <li>
          <b>代办项目：</b>
          <div class="daiban2">
            <span
              v-for="(item, index) in dbDetail.wecando_ids_arr"
              :key="item.title"
              >{{ item.title }}&emsp;{{
                index < dbDetail.wecando_ids_arr.length - 1 ? "|" : ""
              }}&emsp;</span
            >
          </div>
        </li>
      </ul>
      <!-- <button @click="sydj()" class="my-button use">使用道具</button> -->
    </section>
    <section class="section3 index-container">
      <div class="left">
        <p class="top-title">更多介绍</p>
        <div class="content">
          <div class="content-container" style="line-height: 40px">
            <p v-html="dbDetail.service"></p>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right-item my-user">
          <div class="user-info">
            <img class="user-tx" :src="dbDetail.head_pic" alt="" />
            <div>
              <p class="user-name">发布人：{{ dbDetail.contacts }}</p>
            </div>
          </div>
          <button class="user-tel">{{ dbDetail.mobile }}</button>
          <p class="user-tip">联系我时，请说是旺点来上看到的，谢谢！</p>
        </div>
        <div class="right-item">
          <p class="right-title">旺点问答</p>
          <div class="my-list2 toggle">
            <div
              @click="activeId = index"
              v-for="(item, index) in questionslist1"
              :key="item.id"
              class="item"
            >
              <div class="item-top">
                <img
                  class="item-img"
                  src="../../assets/img/icon42.png"
                  alt=""
                />
                <p class="item-title">{{ item.title }}</p>
                <img
                  class="item-icon"
                  src="../../assets/img/z-icon4.png"
                  alt=""
                />
              </div>
              <div class="item-content" :class="{ show1: activeId == index }">
                {{ item.content }}{{ index }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Modal ref="prop-modal">
      <div class="prop modal">
        <div class="modal-container">
          <div class="modal-main block">
            <p class="prop-title">道具列表</p>
            <img
              src="@/assets/img/z-icon9.png"
              class="prop-close"
              @click="$refs['prop-modal'].manageModal(false)"
            />
            <div>
              <ul class="flex">
                <li v-for="item in propsList" :key="item.id">
                  <div class="flex just-start prop-intro">
                    <img :src="item?.thumb" />
                    <div class="prop-intro-text">
                      <p>{{ item.title }}</p>
                      <p>{{ item.coins }}旺点币</p>
                      <p>
                        {{ item.types == 2 ? "减少" : "增加"
                        }}{{ item.fiery }}火热值
                      </p>
                    </div>
                  </div>
                  <button @click="handleBuy(item)">立即购买</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </main>
</template>

<script>
import { getUrlData } from "@/util/utils";
import { Message } from "element-ui";

export default {
  name: "detail",
  data() {
    return {
      loading: null,
      dbDetail: {},
      questionslist1: [],
      activeId: 0,
      propsList: [],
    };
  },
  async created() {
    this.loading = this.showLoading();
    Promise.all([
      this.daibanDetail(),
      this.questionslist(),
      this.propslist(),
    ]).finally(() => {
      this.loading.close();
      this.loading = null;
    });
  },
  methods: {
    sydj() {
      if (this.$store.state.token == undefined) {
        Message.warning("请先登录！");
        return;
      }
      if (this.propsList.length > 0) {
        this.$refs["prop-modal"].manageModal(true);
      } else {
        Message.warning("道具更新中，敬请期待！");
      }
    },

    handleBuy(item) {
      let loading = this.showLoading();
      this.id = parseInt(getUrlData().id);
      this.token = JSON.parse(localStorage.getItem("userInfo"))?.token;

      this.$https
        .get("/api/Currency/props", {
          params: {
            token: this.token,
            id: item.id,
            common_id: this.id,
            typeid: 2,
          },
        })
        .then((res) => {
          Message.success("购买成功");
          this.daibanDetail(), this.$refs["prop-modal"].manageModal(false);
          console.log(res);
        })
        .finally(() => {
          loading.close();
        });
    },
    propslist() {
      let loading = this.showLoading();
      this.$https
        .get("/api/Currency/propslist", {
          params: {
            cat_id: 1,
          },
        })
        .then((res) => {
          this.propsList = res.data;
          console.log(res);
        })
        .finally(() => {
          loading.close();
        });
    },
    questionslist() {
      this.$https
        .get("/api/Currency/questionslist")
        .then((res) => {
          console.log(res.data);
          this.questionslist1 = res.data;
        })
        .finally(() => {
          // loading.close();
        });
    },
    daibanDetail() {
      this.id = parseInt(getUrlData().id);
      this.token = this.$store.state.token;
      let loading = this.showLoading();
      this.$https
        .get("/api/Daiban/daibanDetail", {
          params: {
            id: this.id,
            token: this.token,
          },
        })
        .then((res) => {
          // this.serversList = res.data;
          this.dbDetail = res.data;
          // this.dbDetail.update_time = timestampToTime(
          //   res.data.update_time || 0
          // );
          console.log(res.data);
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.show1 {
  display: block !important;
}
</style>