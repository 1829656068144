<template>
    <footer>
        <div class="index-container">
            <div class="top">
                <div class="list">
                    <div class="item">
                        <img class="item-img" src="../assets/img/icon21.png" alt="" />
                        <div>
                            <p class="item-title">专业平台</p>
                            <p class="item-text">全国性的平台，海量的交易信息</p>
                        </div>
                    </div>
                    <div class="item">
                        <img class="item-img" src="../assets/img/icon22.png" alt="" />
                        <div>
                            <p class="item-title">免费发布</p>
                            <p class="item-text">免费将您的转让信息推广至全国</p>
                        </div>
                    </div>
                    <div class="item">
                        <img class="item-img" src="../assets/img/icon23.png" alt="" />
                        <div>
                            <p class="item-title">买卖直联</p>
                            <p class="item-text">买卖双方直接交易，没有中间商</p>
                        </div>
                    </div>
                    <div class="item">
                        <img class="item-img" src="../assets/img/icon24.png" alt="" />
                        <div>
                            <p class="item-title">担保交易</p>
                            <p class="item-text">线上付款，线下交易，保安全</p>
                        </div>
                    </div>
                </div>
                <p class="links">
                    <span>友情链接：</span>
                    <a
                        :href="item.url"
                        target="_blank"
                        v-for="item,index in friendlylink"
                        :key="index">{{ item.title }}</a>
                </p>
            </div>
            <div class="bottom">
                <div class="left">
                    <div class="links">
                        <a href="about.html?id=2">关于我们</a>
                        <a href="about.html?id=3">联系我们</a>
                        <a href="about.html?id=4">法律声明</a>
                        <a href="about.html?id=5">政策法规</a>
                    </div>
                    <p class="left-text">{{ config.copyright }}&emsp;&ensp;<a :href="config.icp_url">{{ config.icp }}</a>&emsp;&ensp;<img src="../assets/img/icon25.png" alt="" ><a :href="config.police_icp_url">{{ config.police_icp }}</a></p>
                </div>
                <div class="right">
                    <img class="right-img" :src="config.wechat" alt="" >
                    <div class="line1">
                        <p class="right-title">扫码关注微信公众号</p>
                        <p class="right-text">专业的地址转让平台</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data () {
        return {
            friendlylink: [],
            config: {}
        }
    },
    mounted () {
        const data = {
            is_pc: 1
        }
        this.$https.post("/api/Index/index", data).then(({ data }) => {
            this.friendlylink = data.friendlylink
        })
        this.$https.post("/api/Currency/config").then(({ data }) => {
            this.config = data
        })
    }
}
</script>

<style lang="scss" scoped>

</style>