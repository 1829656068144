<template>
    <div>
        <div class="right-container">
                        <div class="right-container-nav">
                            <ul class="flex just-start">
                                <li :class="{active: active === 0}" @click="toAddress(0)">
                                    <a href="javascript:;" >我的提现</a>
                                </li>
                                <li :class="{active: active === 1}" @click="toAddress(1)">
                                    <a href="javascript:;" >我的收藏</a>
                                </li>
                                <li :class="{active: active === 2}" @click="toAddress(2)">
                                    <a href="javascript:;" >修改密码</a>
                                </li>
                            </ul>
                        </div>
                        <Index v-if="active === 0"></Index>
                        <Collect v-if="active === 1"></Collect>
                        <Password v-if="active === 2"></Password>
                    </div>
    </div>
</template>
<script>
import Index from './cashout/index.vue'
import Collect from './cashout/collect.vue'
import Password from './cashout/password.vue'
export default {
    components:{Index,Collect,Password},
    data() {
        return {
            active : 0
        }
    },
    methods: {
        toAddress(val) {
            this.active = val
        }
    }
}
</script>
