import Vue from 'vue'
import App from './App.vue'
import https from './util/http'
import store from './store'
import mixin from './util/mixin'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import DyComp from '@/components/Modal/index'
import "./util/delay"

import './assets/scss/common.scss'
import './assets/scss/index.scss'
import './assets/scss/style.scss'

Vue.config.productionTip = false

Vue.prototype.$https = https

Vue.mixin(mixin)
Vue.use(ElementUI)
Vue.use(DyComp)

new Vue({
  store,
  created() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {}
    this.$store.state.userInfo = userInfo
    this.$store.state.token = userInfo.token
  },
  render: h => h(App),
}).$mount('#app')
