import https from "./http"
function getRegionList(parent_id = 0, level = 1, typeid = 0) {
  const data = {
    parent_id,
    level,
    typeid
  }
  return new Promise((resolve) => {
    https.post("/api/Region/getRegionList", data).then(res => {
      resolve(res.data)
    })
  })
}

function copyObject(data) {
  return JSON.parse(JSON.stringify(data))
}

function arrToString(data) {
  let str = ''
  data.map(item => {
    str += item + ','
  })
  return str.slice(0, -1)
}

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0
}

function getUrlData() {
  let href = window.location.href
  let obj = {}
  if (href.indexOf('?') !== -1) {
    let query = href.substring(href.indexOf('?') + 1);
    let vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=')
      obj[pair[0]] = pair[1]
    }
  }
  return obj
}

function timestampToTime(timestamp) {
  console.log(timestamp.toString().length);
  let len = timestamp.toString().length
  timestamp = timestamp ? len == 10 ? timestamp * 1000 : timestamp : null;
  let date = new Date(timestamp);
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D =
    (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  let h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  let m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  let s =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}


//获取query
/**
 * @param key String
 */
function getQueryParameter(key) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(key);
}

export {
  getRegionList,
  copyObject,
  arrToString,
  isObjectEmpty,
  getUrlData,
  getQueryParameter,
  timestampToTime
}