<template>
  <main class="release my-background">
    <section class="section1 index-container">
      <div class="crumbs">
        <img src="../../../assets/img/z-icon1.png" />
        当前所在位置：<a href="index.html">旺点来</a> >
        <a href="agent.html">找代办</a> > <a class="active">发布代办</a>
      </div>
    </section>
    <section class="section3 index-container my-form">
      <p class="top-title">
        发布代办服务&emsp;&emsp;<span
          >您发布的信息均为免费，欢迎您成为旺点来的一员!</span
        >
      </p>
      <div class="item">
        <div class="item-title">
          <span>基础信息</span>
        </div>
        <div class="form">
          <div class="form-item">
            <div class="form-left tip">标题：</div>
            <div class="form-right">
              <el-input
                v-model="form.title"
                maxlength="30"
                class="w-340"
                placeholder="标题不得超出30个子"
              ></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left">缩略图：</div>
            <div class="form-right form-upload">
              <div class="imgs release-box">
                <div
                  class="img-item"
                  v-for="(item, index) in sendForm.images"
                  :key="index"
                >
                  <img class="wh100" :src="baseUrl + item" />
                  <p class="del" @click="del(index)">
                    <img src="../../../assets/img/icon48.png" />
                  </p>
                </div>
                <el-upload
                  class="img-upload"
                  :action="baseUrl + '/api/Currency/dyupload'"
                  name="dypic"
                  :show-file-list="false"
                  :on-success="upload"
                >
                  <img src="../../../assets/img/icon50.png" />
                  <p>上传</p>
                </el-upload>
              </div>

              <!-- <button class="my-button">
                <img src="../../../assets/img/icon51.png" />浏览<input
                  type="file"
                  @change="handleImg($event)"
                  class="button-absolute"
                />
              </button> -->
              <p class="tip">
                格式要求：jpg,gif,png,最多上传1个文件，单文件最大2MB
              </p>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">所在地区：</div>
            <div class="form-right">
              <mySelect
                @change="upDatatile"
                :modelValue="prvName"
                :data="prvList"
                width="216"
              ></mySelect>
              <mySelect
                @change="upDataCity"
                :modelValue="cityName"
                :data="cityList"
                width="216"
              ></mySelect>
              <mySelect
                @change="upDataArea"
                :modelValue="areaName"
                :data="areaList"
                width="216"
              ></mySelect>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">我能办：</div>
            <div class="form-right">
              <el-checkbox-group v-model="form.wecando_ids">
                <el-checkbox
                  v-for="item in serversList"
                  :key="item.orderid"
                  :label="item.orderid"
                  name="type"
                  >{{ item.title }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">服务介绍：</div>
            <div class="form-right">
              <el-input
                v-model="form.service"
                type="textarea"
                rows="6"
                resize="none"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="item-title">
          <span>联系方式</span>
        </div>
        <div class="form">
          <div class="form-item">
            <div class="form-left tip">联系电话：</div>
            <div class="form-right">
              <el-input
                v-model="form.mobile"
                placeholder="请输入手机号"
              ></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">联系人：</div>
            <div class="form-right">
              <el-input
                v-model="form.contacts"
                placeholder="请输入联系人"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="item submit">
        <div class="my-radio">
          <input type="checkbox" v-model="xy" id="item1" name="type" />
          <div class="checked"></div>
          <label for="item1"
            >我已阅读并同意<a target="_blank" href="about.html?id=1" class="xy"
              >《旺点来转让协议》</a
            ></label
          >
        </div>
        <button @click="handleFb" class="my-button">立即发布</button>
      </div>
    </section>
    <Modal ref="modal">
      <div class="release-modal">
        <img
          @click="$refs.modal.close(), getRegionList1()"
          class="close-icon"
          src="../../../assets/img/z-icon9.png"
          alt=""
        />
        <img class="icon" src="../../../assets/img/icon46.png" alt="" />
        <p>发布成功，请等待审核</p>
        <p>您还可以</p>
        <div class="btns">
          <button
            class="my-button"
            @click="$refs.modal.close(), getRegionList1()"
          >
            继续发布
          </button>
          <button class="my-button">
            <a :href="'personal.html?id=' + 2 + '&status=' + 0 + '&type=' + 1"
              >查看审核</a
            >
          </button>
          <button class="my-button"><a href="agent.html">返回列表</a></button>
        </div>
      </div>
    </Modal>
  </main>
</template>

<script>
import { getRegionList, getUrlData } from "@/util/utils";
import { Message } from "element-ui";

import mySelect from "@/components/mySelect.vue";
import Modal from "@/components/Modal";
export default {
  name: "release",
  components: { mySelect, Modal },
  data() {
    return {
      value1: null,
      value2: null,
      text1: null,
      form: {
        siteid: 0,
        token: "",
        title: "",
        picurl: [],
        province: "",
        city: "",
        district: "",
        wecando_ids: [],
        service: "",
        mobile: "",
        contacts: "",
      },
      prvList: [],
      cityList: [],
      areaList: [],

      loading: null,
      prvName: null,
      cityName: null,
      areaName: null,
      typePrevStatus: 0,

      xy: false,
      sendForm: {
        images: [],
      },
      serversList: [],
      mobileReg:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
    };
  },
  async mounted() {
    this.loading = this.showLoading();

    let query = getUrlData();

    if (query.id) {
      this.id = parseInt(query.id);
      this.getWeCanDo();
      this.hh();
    } else {
      Promise.all([this.getRegionList1(), this.getWeCanDo()]).finally(() => {
        this.typePrevStatus == 1;
        this.loading.close();
        this.loading = null;
      });
    }
  },
  methods: {
    hh() {
      let loading = this.showLoading();
      this.token = JSON.parse(localStorage.getItem("userInfo")).token;
      this.$https
        .get("/api/Daiban/daibanDetail", {
          params: {
            token: this.token,
            id: this.id,
          },
        })
        .then((res) => {
          console.log(res);
          let {
            city,
            contacts,
            content,
            district,
            id,
            mobile,
            province,
            title,
            service,
            wecando_ids,
            picurl,
          } = res.data;
          console.log(picurl);
          this.form.title = title;
          this.form.id = id;
          this.form.province = province;
          this.form.mobile = mobile;
          this.form.content = content;
          this.form.district = district;
          this.form.service = service;
          this.form.contacts = contacts;
          this.form.city = city;

          if (picurl.indexOf(",") == -1) {
            let ss = picurl;
            this.form.picurl.push(ss);
            this.sendForm.images = this.form.picurl;
          } else {
            this.form.picurl = picurl.split(",");
          }

          if (wecando_ids.indexOf(",") == -1) {
            this.form.wecando_ids.push(Number(wecando_ids));
          } else {
            this.form.wecando_ids = wecando_ids.split(",").map(Number);
          }
          this.getHadeui(province, city, district);
        })
        .finally(() => {
          loading.close();
        });
    },
    async getHadeui(province, city, district) {
      this.prvList = await getRegionList(0, 1, 0);
      this.cityList = await getRegionList(province, 2, 0);
      this.areaList = await getRegionList(city, 3, 0);

      this.prvName = province;
      this.cityName = city;
      this.areaName = district;
    },
    handleFb() {
      const reg = this.mobileReg;

      if (this.form.title == "") {
        Message.error("请输入标题");
        return;
      }
      if (this.sendForm.images.length == 0) {
        Message.error("请上传缩略图");
        return;
      } else {
        this.form.picurl = this.sendForm.images.toString();
      }

      if (this.form.province === "") {
        Message.error("请选择省份");
        return;
      }
      if (this.form.city == "" && Number(this.form.province) > 0) {
        Message.error("请选择城市");
        return;
      }
      if (this.form.wecando_ids.length == 0) {
        Message.error("请选择能办类别");
        return;
      }
      if (this.form.service == "") {
        Message.error("请输入服务介绍");
        return;
      }
      if (this.form.mobile == "") {
        Message.error("请输入手机号");
        return;
      } else if (!reg.test(this.form.mobile)) {
        Message.error("请输入正确的手机号");
        return;
      }
      if (this.form.contacts == "") {
        Message.error("请输入联系人");
        return;
      }
      if (this.xy == false) {
        Message.error("请勾选协议");
        return;
      }
      let url = "/api/Daiban/addDaiBan";
      if (this.id != undefined) {
        this.form.id = this.id;
        url = "/api/UsersCenter/editDaiBan";
      }
      let loading = this.showLoading();
      this.form.wecando_ids = this.form.wecando_ids.toString();
      this.form.token = JSON.parse(localStorage.getItem("userInfo"))?.token;

      if (this.form.province == 0) {
        this.form.province = -1;
      }

      this.$https
        .get(url, {
          params: {
            ...this.form,
          },
        })
        .then((res) => {
          // console.log(res);
          Message.success(res.message);
          this.$refs.modal.manageModal(true);
          Object.keys(this.form).forEach((item) => {
            this.form[item] = "";
          });

          this.prvName = null;
          this.cityName = null;
          this.areaName = null;
          this.prvList = [];
          this.cityList = [];
          this.areaList = [];
          this.sendForm.images = [];
          this.form.picurl = [];
          this.form.wecando_ids = [];
          this.xy = false;

          // setTimeout(() => {
          //   window.location.href = "/agent.html";
          // }, 2000);

          // this.serversList = res.data;
        })
        .finally(() => {
          loading.close();
        });
    },

    getWeCanDo() {
      //获取服务列表
      // let loading = this.showLoading();

      this.$https
        .get("/api/Daiban/getWeCanDo")
        .then((res) => {
          this.serversList = res.data;
        })
        .finally(() => {
          // loading.close();
        });
    },
    upDatatile(val) {
      console.log(val);
      this.prvName = val;
      this.typePrevStatus = 1;
      this.areaName = null;
      this.cityName = null;
      this.areaList = [];
      this.getRegionList();
      this.form.province = val;
      this.form.city = "";
      this.form.district = "";
    },
    upDataCity(val) {
      console.log(val);
      this.cityName = val;
      this.typePrevStatus = 2;
      this.areaName = null;
      this.getRegionList();
      this.form.city = val;
    },
    upDataArea(val) {
      this.areaName = val;
      this.form.district = val;

      this.typePrevStatus = 3;
      this.getRegionList();
    },
    async getRegionList1() {
      let prvList = await getRegionList(0, 1, 0);
      this.prvList = [{ id: 0, parent_id: 0, name: "全国" }, ...prvList];
    },
    getRegionList() {
      console.log(this.typePrevStatus);
      // let loading = this.showLoading();
      // console.log();
      this.$https
        .get("/api/Region/getRegionList", {
          params: {
            parent_id:
              this.typePrevStatus == 1
                ? this.prvName
                : this.typePrevStatus == 3
                ? this.prvName
                : this.cityName,
            level:
              this.typePrevStatus == 0
                ? 1
                : this.typePrevStatus == 1
                ? 2
                : this.typePrevStatus == 3
                ? 2
                : 3,
            typeid: 1,
          },
        })
        .then((res) => {
          if (this.typePrevStatus == 2) {
            this.areaList = res.data;
          } else {
            this.cityList = res.data;
          }
        })
        .finally(() => {
          // this.typePrevStatus = 0;
          // this.daibanList();
          // loading.close();
        });
    },
    upload(response) {
      this.sendForm.images.push(response.data);
    },
    del(index) {
      this.sendForm.images.splice(index, 1);
    },
    handleImg(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-textarea__inner) {
  font-family: "PingFang", "PingFang SC", "syht", "Microsoft YaHei", "黑体",
    "宋体", sans-serif;
}
.el-textarea {
  font-family: "PingFang", "PingFang SC", "syht", "Microsoft YaHei", "黑体",
    "宋体", sans-serif;
}
:deep(.el-input__inner) {
  font-family: "PingFang", "PingFang SC", "syht", "Microsoft YaHei", "黑体",
    "宋体", sans-serif;
}
.my-form .form-right.form-upload .tip {
  margin-left: 15px;
}
.my-form .form-right.form-upload {
  align-items: flex-end;
}
.release-box .img-upload {
  width: 86px;
  height: 32px;
  background: #ff722c;
  border-radius: 6px;
}
.release-box {
  display: flex;
  align-items: flex-end;
}
:deep(.release-box .img-upload .el-upload) {
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  height: 100%;
  > p {
    margin-left: 4px;
  }
}
.my-form .release-box.imgs .img-item {
  width: 80px;
  height: 80px;
  position: relative;
  margin-right: 10px;
  .del {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;

    justify-content: center;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.6);
    padding: 5px 0;
    > img {
      width: 16px;
    }
  }
}
.my-form .form-right.imgs .img-upload {
  width: 80px;
  height: 80px;
  margin-bottom: 0;
}
.release-box {
  padding-right: 0 !important;
}
.release-modal {
  width: 658px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 84px 0 74px;
  text-align: center;
  .icon {
    margin-bottom: 10px;
  }
  p {
    font-size: 22px;
    line-height: 1.3;
  }
  .btns {
    margin-top: 32px;
  }
  .my-button {
    width: 130px;
    height: 42px;
    border-radius: 21px;
    & + .my-button {
      margin-left: 10px;
    }
  }
}
</style>