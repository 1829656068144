<template>
    <div>
        <div>
                            <ul>
                                <li class="flex status-list" v-for="item in list" :key="item.id">
                                    <div class="status-info">
                                        <p>{{ item.title }}</p>
                                        <p>更新时间：<span class="c333333">{{ item.update_time }}</span></p>
                                    </div>
                                    <div class="flex">
                                        <button type="button" @click="getColletList(item)">道具</button>
                                        <button type="button" @click="cancelCollect(item)">删除</button>
                                    </div>
                                </li>
                                <!-- <li class="flex status-list">
                                    <div class="status-info">
                                        <p>浙江省杭州市上城区秋涛北路83号</p>
                                        <p>更新时间：<span class="c333333">2023-10-30</span></p>
                                    </div>
                                    <div class="flex">
                                        <button>道具</button>
                                        <button>删除</button>
                                    </div>
                                </li>
                                <li class="flex status-list">
                                    <div class="status-info">
                                        <p>浙江省杭州市上城区秋涛北路83号</p>
                                        <p>更新时间：<span class="c333333">2023-10-30</span></p>
                                    </div>
                                    <div class="flex">
                                        <button>道具</button>
                                        <button>删除</button>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                        <div class="mt25">
                            <MyPagination :total="count" @change="change"></MyPagination>
                        </div>
                        <Modal ref="collect-modal">
            <div class="prop modal">
                <div class="modal-container">
                <div class="modal-main block">
                    <p class="prop-title">道具列表</p>
                    <img src="@/assets/img/z-icon9.png" class="prop-close"  @click="$refs['collect-modal'].manageModal(false)">
                    <div>
                        <ul class="flex">
                            <li v-for="item in toolList" :key="item.id">
                                <div class="flex just-start prop-intro">
                                    <img :src="item.thumb">
                                    <div class="prop-intro-text">
                                        <p>{{ item.title }}</p>
                                        <p>{{item.coins * 1}}旺点币</p>
                                        <p>增加{{item.fiery}}火热值</p>
                                    </div>
                                </div>
                                <button type="button" @click="buy(item.id)">立即购买</button>
                            </li>
                        </ul>
                    </div>
                </div>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import Bus from "@/util/bus";
export default {
    data() {
        return {
            list: [],
            page: 1,
            count: 0,
            toolList: [],
            toolObject: null
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            const loading = this.showLoading()
            const token = JSON.parse(localStorage.getItem('userInfo')).token
            const url = `/api/Address/collectlist?page=${this.page}&token=${token}`
            this.$https.get(url).then(res => {
                this.list = res.data
                this.count = res.count
            }).finally(() => {
                loading.close()
            })
        },
        change(index) {
            this.page = index
            this.getList()
        },
        cancelCollect(item) {
            const token = JSON.parse(localStorage.getItem('userInfo')).token
            const data = {
                token,
                typeid: 1,
                address_id: item.id
            }
            this.$alert('确认删除收藏？', {
            confirmButtonText: '确定',
            confirmButtonClass: 'confirm_button',
            callback: action => {
                if(action == 'confirm') {
                    this.$https.post('/api/Address/collect',data).then((res) => {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.getList()
                })
                }
            }
            });
            
        },
        getColletList(item) {
            this.$https.get('/api/Currency/propslist?cat_id=1').then(res => {
                this.$refs['collect-modal'].manageModal(true)
                this.toolObject = item.id
                this.toolList = res.data
            })
        },
        buy(id) {
            if(!this.toolObject) {
                this.$message({
                    message: '缺少使用对象',
                    type: 'warning'
                });
                return
            }
            const loading = this.showLoading()
            const token = JSON.parse(localStorage.getItem('userInfo')).token
            const data = {
                id: id,
                common_id: this.toolObject,
                token,
                typeid: 1
            }
            this.$https.post('/api/Currency/props',data).then(res => {
                this.$refs['collect-modal'].manageModal(false)
                Bus.$emit('cash')
                this.$message({
                    message: res.message,
                    type: 'success'
                });
            }).finally(() => {
                loading.close()
            })
        }
    }
}
</script>