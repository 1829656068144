<template>
    <div>
        <div class="news-detail">
        <div class="index-container">
            <div class="crumbs">
                <img src="@/assets/img/z-icon1.png">
                当前所在位置：<a href="/">旺点来</a> 
                &gt;<a href="/news.html">新闻资讯</a>
                &gt;<a href="javascript:;">{{ info.title }}</a>
            </div>
            <div class="flex">
                <Left></Left>
                <div class="news-detail-main">
                    <div class="detail-main-top">
                        <p class="detail-main-title">{{ info.title }}</p>
                        <p class="detail-main-date">日期： {{ info.add_time }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;浏览次数：{{ info.hits }}</p>
                    </div>
                    <div class="detail-main-content">
                        <div class="detail-main-content-article">
                            <div v-html="info.content"></div>
                        </div>
                        <div class="flex detail-main-content-button">
                            <button class="detail-main-content__button">
                                <a href="/address.html">
                                    我要买地址
                                </a>
                            </button>
                            <button class="detail-main-content__button">
                                <a href="/address_release.html">我要卖地址</a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import Left from './component/left.vue'
export default {
    components: {Left},
    data() {
        return {
            id: 0,
            info: null,
            hot: []
        }
    },
    created() {
        this.id = this.getQueryParameter('id') || 0
        this.getDetail()
        //获取热门资讯
        this.getNewsSideList(1,6).then(res => {
            this.hot = res.data
        })
    },
    methods: {
        getNewsSideList(type,limit) {
            return this.$https.get('/api/News/list?posid=2&page=1&limit='+limit+'&type='+type)
        },
        getDetail() {
            const loading = this.showLoading()
            this.$https.get('/api/News/detail?id='+this.id).then(res => {
                this.info = res.data.info
            }).finally(() => {
                loading.close()
            })
        },
        //获取query
        getQueryParameter(key) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            return urlParams.get(key);
        }
    }
}
</script>