<template>
    <div>
        <div class="personal">
        <div class="index-container">
            <!-- <Crumbs></Crumbs> -->
            <div class="crumbs">
                <img src="@/assets/img/z-icon1.png">
                当前所在位置：<a href="/">旺点来</a> 
                    &gt;<a href="/personal.html" >个人中心</a>
            </div>
            <div class="flex">
                <Left v-if="showleft"></Left>
                <div class="right">
                    <div class="flex right-nav just-start">
                        <a href="javascript:;" :class="{active: totalActive===0}" @click="toTotalPage(0)">我的发布</a>
                        <a href="javascript:;" :class="{active: totalActive===1}" @click="toTotalPage(1)">我的订单</a>
                        <a href="javascript:;" :class="{active: totalActive===2}" @click="toTotalPage(2)">其他管理</a>
                    </div>
                    <Index v-if="active===0" @update-active="updateActive" :id="id" :pagestatus="status"></Index>
                    <IndexOther v-if="[1,2,3].includes(active)" :active.sync="outerActive" :id="id" :pagestatus="status" @update-active="updateActive"></IndexOther>
                    <Order v-if="active === 4"></Order>
                    <CashOut v-if="active === 5"></CashOut>
                </div>
            </div>
        </div>
        
    </div>
    </div>
</template>
<script>

import Left from "./components/left.vue"
import Index from "./components/index.vue"
import IndexOther from './components/indexOther.vue'
import Order from './components/order.vue'
import CashOut from './components/cashout.vue'
import Bus from '@/util/bus'
export default {
    components: {Left,Index,IndexOther,Order,CashOut},
    data() {
        return {
            active: 0,
            outerActive: 1,
            totalActive: 0,
            showleft: true,
            id: 0,
            status: 1
        }
    },
    created() {
        const val = this.getQueryParameter('val')
        if(val) {
            this.totalActive = Number(val)
            const type = this.getQueryParameter('type')
            if(type) {
                this.active = Number(type)
            }
        }else {
            const type = this.getQueryParameter('type')
            if(type) {
                this.active = Number(type)
                const id = this.getQueryParameter('id')
                this.id = id
                const status = this.getQueryParameter('status')
                this.status = status
            }
        }
        Bus.$on('lefttopage',(val,type) => {
            window.location.href = '/personal.html?type='+type+'&val='+val
        })
        Bus.$on('recharge',() => {
            window.location.href = '/recharge.html'
        })
        Bus.$on('balancerecharge',() => {
            window.location.href = '/balance_recharge.html'
        })
        Bus.$on('updateleft',() => {
            console.log('updateleft')
            this.showleft = false
            this.$nextTick(() => {
                this.showleft = true
            })
        })
    },
    methods: {
        updateActive(val) {
            //将id置空，后面用来判断我的发布后面三个模块是点击跳转还是其他页面跳转
            this.id = null
            this.active = val
            this.outerActive = val
            if([1,2,3].includes(val)) {
                this.totalActive = 0
            }else if(val === 4) {
                this.totalActive = 1
            }else {
                this.totalActive = 0
            }
        },
        toTotalPage(val) {
            if(val === 1) {
                this.active = 4
                this.totalActive = val
            }else if (val === 2) {
                this.active = 5
                this.totalActive = val
            }else {
                this.active = 0
                this.totalActive = val
            }
        },
        //获取query
        getQueryParameter(key) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            return urlParams.get(key);
        }
    }
}
</script>
<style>
.showleft {
    width: 364px;
    height: 266px;
    background-color: #fff;
}
</style>