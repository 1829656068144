<template>
    <div>
        <div class="right-container">
                        <div class="right-container-nav">
                            <ul class="flex just-start">
                                <li :class="{'active':(active==1)}">
                                    <a href="javascript:;" @click="switchTap(1)">购买订单</a>
                                </li>
                                <li :class="{'active':(active==2)}">
                                    <a href="javascript:;" @click="switchTap(2)">售出订单</a>
                                </li>
                            </ul>
                        </div>
                        <div class="flex just-start button-nav"  id="button-nav">
                            <div :class="['button',{'active': status == 0}]" @click="swichStatus(0)">全部</div>
                            <div :class="['button',{'active': status == 1}]" @click="swichStatus(1)">待付款</div>
                            <div :class="['button',{'active': status == 2}]" @click="swichStatus(2)">待发货</div>
                            <div :class="['button',{'active': status == 3}]" @click="swichStatus(3)">待收货</div>
                            <div :class="['button',{'active': status == 4}]" @click="swichStatus(4)">交易完成</div>
                            <div :class="['button','w81',{'active': status == 5}]" @click="swichStatus(5)">申请退款中</div>
                            <div :class="['button',{'active': status == 6}]" @click="swichStatus(6)">已退款</div>
                        </div>
                        <div>
                            <ul>
                                <li class="right-order" v-for="item in list" :key="item.id">
                                    <a href="javascript:;" @click="toOrder(item.id,item)">
                                        <div class="flex right-order-top">
                                            <p>订单号：{{ item.order_sn }}</p>
                                            <p>{{ item.pay_status_name }}</p>
                                        </div>
                                        <div class="right-order-bottom" v-if="active == 1">
                                            {{active == 1 ? '卖家':'买家'}}：<span>{{ item.sell_user_name }} {{ item.sell_user_mobile }}</span>价格：<span>¥{{ item.price }}</span>下单时间：<span>{{ item.add_time_name }}</span>
                                        </div>
                                        <div class="right-order-bottom" v-if="active == 2">
                                            {{active == 1 ? '卖家':'买家'}}：<span>{{ item.user_id }} {{ item.user_mobile }}</span>价格：<span>¥{{ item.price }}</span>下单时间：<span>{{ item.add_time_name }}</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="mt25">
                            <MyPagination :total="count" @change="change"></MyPagination>
                        </div>
                    </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list: [],
            active: 1,
            status: 0,
            page: 1,
            count: 0
        }
    },
    created() {
        this.getSellList()
    },
    methods: {
        switchTap(val) {
            this.active = val
            this.status = 0
            this.page = 1
            this.count = 0
            this.list = []
            this.getSellList()
        },
        getSellList() {
            const loading = this.showLoading()
            const baseUrl = this.active === 1? '/api/UsersCenter/orders_buy' : '/api/UsersCenter/orders_sell'
            const token = JSON.parse(localStorage.getItem('userInfo')).token
            const url = `${baseUrl}?user_status=${this.status}&token=${token}&page=${this.page}`
            this.$https.get(url).then(res => {
                this.list = res.data
                this.count = Number(res.count)
            }).finally(() => {
                loading.close()
            })
        },
        swichStatus(val) {
            this.status = val
            this.getSellList()
        },
        toOrder(id,item) {
            const url = this.active == 1 ? '/address_bargain.html?id='+id : '/address_bargain.html?type=1&id='+id
            if(this.active == 1) {
                window.location.href= url
            }else if ((this.active == 2 && [5,6,7,8].includes(item.status)) || (this.active == 2 && item.status == 4 && item.pay_status == 3)){
                window.location.href= url
            }else {
                return
            }
            
        },
        change(index) {
            this.page = index
            this.getSellList()
        }
    }
}
</script>