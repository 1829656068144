<template>
    <main class="about my-background">
        <section class="section1 index-container">
            <div class="crumbs">
                <img src="@/assets/img/z-icon1.png">
                当前所在位置：<a href="/">旺点来</a> > <a class="active">{{ info.title }}</a>
            </div>
        </section>
        <section class="section2 index-container" v-html="info.content"></section>
    </main>
</template>

<script>
import { getUrlData } from '@/util/utils'
export default {
    name: 'about',
    data () {
        return {
            info: {}
        }
    },
    mounted () {
        let query = getUrlData()
        this.$https.post("/api/Currency/onlypage", { id: query.id }).then(({ data }) => {
            this.info = data
        })
    }
}
</script>

<style lang="scss" scoped>

</style>