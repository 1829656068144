<template>
    <div class="news-detail-left">
                    <div class="detail-left-block">
                        <div class="flex align-center">
                            <p class="detail-left-list__p">转让地址</p>
                            <div class="detail-left-list-more">
                                <a href="/address.html" class="flex align-center">
                                    <p>查看更多</p>
                                    <img src="@/assets/img/z-icon20.png">
                                </a>
                            </div>
                        </div>
                        <div class="detail-left-list">
                            <ul class="flex">
                                <li v-for="item in hotAddresNewsCate" :key="item.id">
                                    <a :href="'/address.html?keyword='+item.title">
                                        {{ item.title }}
                                    </a>
                                </li>
                            </ul>
                        </div> 
                    </div> 
                    <div class="detail-left-hot">
                        <div class="flex align-center">
                            <p class="left-hot__p">热门资讯排行</p>
                            <div class="left-hot-more">
                                <a :href="'/new_list.html?type=1&title=热门资讯排行'" class="flex align-center">
                                    <p>查看更多</p>
                                    <img src="@/assets/img/z-icon20.png">
                                </a>
                            </div>
                        </div>
                        <div class="left-hot-list">
                            <ul>
                                <li :class="['flex','just-start','align-center',{'active': index < 3}]" v-for="(item,index) in hot" :key="item.id">
                                    <a :href="'/new_detail.html?id='+item.id">
                                        <span>{{ index + 1 }}</span>
                                        <p>{{ item.title }}</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="detail-left-block">
                        <div class="flex align-center">
                            <p class="detail-left-list__p">转让地址热门关键词</p>
                        </div>
                        <div class="detail-left-list">
                            <ul class="flex">
                                <li v-for="item in addressHotSign" :key="item.id">
                                    <a :href="'/address.html?keyword='+item.title">
                                        {{ item.title }}
                                    </a>
                                </li>
                            </ul>
                        </div> 
                    </div> 
                </div>
</template>
<script>
    export default {
        data() {
            return {
                hot: [],
                address: [],
                addressHotSign: [],
                hotAddresNewsCate: []
            }
        },
        created() {
            //获取热门资讯
            this.getNewsSideList(1,6).then(res => {
                this.hot = res.data
            })
            this.getAddressHotSign()
            //获取转让地址
            // this.getNewsSideList(2,10).then(res => {
            //     this.address = res.data
            // })
            this.getCateList()
        },
        methods: {
            getCateList() {
                this.$https.post('/api/Currency/categorylist',{typeid: 9}).then(res => {
                    this.hotAddresNewsCate = res.data
                })
            },
            getNewsSideList(type,limit) {
                return this.$https.get('/api/News/list?posid=2&page=1&limit='+limit+'&type='+type)
            },
            //获取地址热门标签
            getAddressHotSign() {
                return this.$https.post('/api/Currency/categorylist',{typeid: 10}).then(res => {
                    this.addressHotSign = res.data
                })
            }
        }
    }
</script>