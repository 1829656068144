<template>
    <div>
        <div class="personal">
        <div class="index-container">
            <!-- <Crumbs></Crumbs> -->
            <div class="crumbs">
                <img src="@/assets/img/z-icon1.png">
                当前所在位置：<a href="/">旺点来</a> 
                    &gt;<a href="/recharge.html" >充值</a>
            </div>
            <div class="flex">
                <Left></Left>
                <div class="right">
                    <div class="flex right-nav just-start">
                        <a href="javascript:;" class="active">充值</a>
                    </div>
                    <div class="right-container">
                        <div class="right-container-nav">
                            <p class="restcoin">剩余旺点币：<span>{{ userinfo.coins.includes('万')? userinfo.coins : userinfo.coins * 1 }}</span>枚</p>
                        </div>
                        <div class="right-package">
                            <p class="package-tip">旺点币套餐：</p>
                            <div class="package-options">
                                <ul class="flex" id="package-ul">
                                    <li :class="{'active': coinActive == 1}" @click="switchCoin(1)">
                                        <p>6枚旺点币</p>
                                        <p>¥6</p>
                                        <p>￥1.00/枚</p>
                                    </li>
                                    <li :class="{'active': coinActive == 2}" @click="switchCoin(2)">
                                        <p>30枚旺点币</p>
                                        <p>¥30</p>
                                        <p>￥1.00/枚</p>
                                    </li>
                                    <li :class="{'active': coinActive == 3}" @click="switchCoin(3)">
                                        <p>100枚旺点币</p>
                                        <p>¥100</p>
                                        <p>￥1.00/枚</p>
                                    </li>
                                    <li :class="{'active': coinActive == 4}" @click="switchCoin(4)">
                                        <p>200枚旺点币</p>
                                        <p>¥200</p>
                                        <p>￥1.00/枚</p>
                                    </li>
                                </ul>
                            </div>
                            <p class="any-tip">任意金额：</p>
                            <input type="text" class="any__input" placeholder="¥ 请输入金额" @focus="hideCoinActive" v-model="anyMoney" >
                            <div class="right-pay">
                                <p class="pay-tip">选择支付方式：</p>
                                <ul class="flex just-start" id="pay-ul">
                                    <li :class="['flex','align-center',{'active': payActive == 1}]" @click="switchPay(1)">
                                        <img src="@/assets/img/z-icon5.png">
                                        <p>支付宝支付</p>
                                    </li>
                                    <li :class="['flex','align-center',{'active': payActive == 2}]" @click="switchPay(2)">
                                        <img src="@/assets/img/z-icon6.png">
                                        <p>微信支付</p>
                                    </li>
                                    <li :class="['flex','align-center',{'active': payActive == 3}]" @click="switchPay(3)">
                                        <img src="@/assets/img/z-icon7.png">
                                        <p>余额支付</p>
                                    </li>
                                </ul>
                                <button class="pay-btn" @click="pay">立即支付</button>
                            </div>
                            <div class="right-intro">
                                <div class="right-intro-txt">
                                    <p>旺点币使用说明：</p>
                                    <p>1）旺点币可在本平台购买道具、获取电话使用</p>
                                    <p>2）旺点币不支持退款、提现或转让</p>
                                    <p>3）如遇充值问题，请拨打客服电话{{ setting || '' }}</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal ref="pay-modal">
      <div class="prop modal">
        <div class="modal-container">
          <div class="modal-main wx-pay-block">
            <p class="wx-pay-title">支付</p>
            <img
              src="@/assets/img/z-icon9.png"
              class="wx-pay-close"
              @click="$refs['pay-modal'].manageModal(false)"
            />
            <div>
                <img :src="wxPayImg" class="wx-pay-img">
                <p class="wx-pay-text">在线支付</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    </div>
</template>
<script>
import Left from './components/left.vue'
import Bus from '@/util/bus'
import { getQueryParameter } from '@/util/utils'
export default {
    components: {Left},
    data() {
        return {
            coinActive: 1,
            payActive: 1,
            anyMoney: '',
            userinfo: null,
            timer: null,
            wxPayImg: '',
            setting: null
        }
    },
    created() {
        this.$https.get('/api/Currency/config').then(res => {
            this.setting = res.data.contacts
        })
        const val = getQueryParameter('val')
        if(val) {
            this.totalActive = Number(val)
            const type = getQueryParameter('type')
            if(type) {
                this.active = Number(type)
            }
        }
        const userinfo = JSON.parse(localStorage.getItem('userallinfo'))
        if(userinfo) {
            this.userinfo = userinfo
        }
        Bus.$on('lefttopage',(val,type) => {
            window.location.href = '/personal.html?type='+type+'&val='+val
        })
        Bus.$on('recharge',() => {
            window.location.href = '/recharge.html'
        })
        Bus.$on('balancerecharge',() => {
            window.location.href = '/balance_recharge.html'
        })
        Bus.$on('updateuserinfo',userinfo => {
            this.userinfo = userinfo
        })
        Bus.$on('updatecoins',() => {
            const userinfo = JSON.parse(localStorage.getItem('userallinfo'))
            if(userinfo) {
                this.userinfo = userinfo
            }
        })
    },
    methods: {
        switchCoin(val) {
            this.coinActive = val
        },
        switchPay(val) {
            this.payActive = val
        },
        hideCoinActive() {
            this.coinActive = 0
        },
        pay() {
            const loading = this.showLoading()
            if(this.coinActive == 0 && (this.anyMoney == '' || this.anyMoney == 0)) {
                this.$message({
                    message: '请输入金额',
                    type: 'warning'
                });
                return
            }
            const token = JSON.parse(localStorage.getItem('userInfo')).token
            const data = {
                price: this.coinActive == 0 ? this.anyMoney : this.coinActive == 1 ? 6 : this.coinActive == 2 ? 30 : this.coinActive == 3 ? 100 : 200,
                type: 2,
                token: token
            }
            this.$https.post('/api/UsersCenter/createOrders',data).then(res => {
                const {order_id} = res.data
                const data = {
                    token,
                    order_id,
                    return_url: window.pageUrl + "/personal.html",
                    type: 1
                }
                const balancePayData = {
                    price: this.coinActive == 0 ? this.anyMoney : this.coinActive == 1 ? 6 : this.coinActive == 2 ? 30 : this.coinActive == 3 ? 100 : 200,
                    token: token
                }
                const url = this.payActive == 1 ? '/api/Alipay/pay2' : this.payActive == 2 ? '/api/Weixinpay/pcPay' : '/api/UsersCenter/balancePay'
                this.$https.post(url,this.payActive == 1 || this.payActive == 2 ? data : balancePayData).then(res => {
                    if(this.payActive == 1) {
                        window.open(res)
                        this.getPayStatus(order_id)
                    }else if(this.payActive == 2) {
                        this.$refs['pay-modal'].manageModal(true)
                        // window.open(window.baseUrl + '/' + res.data.url)
                        this.wxPayImg = window.baseUrl + '/' + res.data.url
                        this.getPayStatus(order_id)
                    }
                    else {
                        this.$message({
                            message: res.message,
                            type: 'success'
                        });
                        Bus.$emit('cash')
                    }
                    // Bus.$emit('updateleft')
                })
            }).finally(() => {
                loading.close()
            })
        },
        getPayStatus(order_id) {
            const token = JSON.parse(localStorage.getItem('userInfo')).token
            const data = {
                token,
                order_id
            }
            this.$https.post('/api/Weixinpay/pay_status_check',data).then(res => {
                console.log(res.data.pay_status)
                if(res.data.pay_status == 3) {
                    if(this.payActive == 2) { //微信支付
                        this.$refs['pay-modal'].manageModal(false)
                    }
                    Bus.$emit('cash')
                    clearTimeout(this.timer)
                    this.timer = null
                }else {
                    this.timer = setTimeout(() => {
                        this.getPayStatus(order_id)
                    },10000)
                }
            })
        }
    },
    mounted() {
    }
}
</script>