<template>
  <div>
    <div class="personal">
      <div class="index-container">
        <!-- <Crumbs></Crumbs> -->
        <div class="crumbs">
          <img src="@/assets/img/z-icon1.png" />
          当前所在位置：<a href="/">旺点来</a> &gt;<a href="javascript:;"
            >余额充值</a
          >
        </div>
        <div class="flex">
          <Left></Left>
          <div class="right">
            <div class="flex right-nav just-start">
              <a href="javascript:;" class="active">账户余额充值</a>
            </div>
            <div class="right-container">
              <div class="right-container-nav">
                <p class="restcoin">
                  账户余额：¥<span>{{ userinfo.balance.includes('万')? userinfo.balance : userinfo.balance * 1 }}</span>
                </p>
              </div>
              <div class="right-package">
                <div class="flex just-start align-center pay-money">
                  <p>充值金额：</p>
                  <input
                    type="text"
                    placeholder="¥ 请输入提现金额"
                    v-model="price"
                  />
                </div>
                <div class="right-pay">
                  <p class="pay-tip">选择支付方式：</p>
                  <ul class="flex just-start" id="pay-ul">
                    <li
                      class="flex align-center"
                      :class="{ active: active == 0 }"
                      @click="switchActive(0)"
                    >
                      <img src="@/assets/img/z-icon5.png" />
                      <p>支付宝支付</p>
                    </li>
                    <li
                      class="flex align-center"
                      :class="{ active: active == 1 }"
                      @click="switchActive(1)"
                    >
                      <img src="@/assets/img/z-icon6.png" />
                      <p>微信支付</p>
                    </li>
                    <!-- <li class="flex align-center" :class="{'active': active==2}" @click="switchActive(2)">
                                        <img src="@/assets/img/z-icon7.png">
                                        <p>余额支付</p>
                                    </li> -->
                  </ul>
                  <button class="pay-btn" @click="pay">立即支付</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal ref="pay-modal">
      <div class="prop modal">
        <div class="modal-container">
          <div class="modal-main wx-pay-block">
            <p class="wx-pay-title">支付</p>
            <img
              src="@/assets/img/z-icon9.png"
              class="wx-pay-close"
              @click="$refs['pay-modal'].manageModal(false)"
            />
            <div>
                <img :src="wxPayImg" class="wx-pay-img">
                <p class="wx-pay-text">在线支付</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import MyPagination from "@/components/myPagination.vue";
import Left from "./components/left";
import Bus from "@/util/bus";
// import { getQueryParameter } from "@/util/utils";
export default {
  components: { MyPagination, Left },
  data() {
    return {
      userinfo: {
        balance: "",
      },
      active: 0,
      price: "",
      timer: null,
      wxPayImg: ''
    };
  },
  created() {
    const userinfo = JSON.parse(localStorage.getItem('userallinfo'))
    if(userinfo) {
      this.userinfo = userinfo
    }
    Bus.$on('lefttopage',(val,type) => {
      window.location.href = '/personal.html?type='+type+'&val='+val
    })
    Bus.$on('recharge',() => {
      window.location.href = '/recharge.html'
    })
    Bus.$on('balancerecharge',() => {
      window.location.href = '/balance_recharge.html'
    })
    Bus.$on('updatecoins',() => {
        const userinfo = JSON.parse(localStorage.getItem('userallinfo'))
        if(userinfo) {
            this.userinfo = userinfo
        }
    })
  },
  // pay() {
  //   const loading = this.showLoading();
  //   const token = JSON.parse(localStorage.getItem("userInfo")).token;
  //   const data = {
  //     token,
  //     type: 3,
  //     price: Number(this.price),
  //   };
  //   this.$https
  //     .post("/api/UsersCenter/createOrders", data)
  //     .then((res) => {
  //       const { order_id } = res.data;
  //       const payData = {
  //         token,
  //         order_id,
  //         type: 1,
  //       };
  //       const url =
  //         this.active == 0 ? "/api/Alipay/pay2" : "/api/Weixinpay/pcPay";
  //       this.$https.post(url, payData).then((payRes) => {
  //         window.open(payRes);
  //       });
  //     })
  //     .finally(() => {
  //       loading.close();
  //     });
  // },
  methods: {
    switchActive(val) {
      this.active = val;
    },
    pay() {
      const loading = this.showLoading();
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      const data = {
        token,
        type: 3,
        price: Number(this.price),
      };
      this.$https
        .post("/api/UsersCenter/createOrders", data)
        .then((res) => {
          const { order_id } = res.data;
          const payData = {
            token,
            order_id,
            return_url: window.pageUrl + "/personal.html",
            type: 1,
          };
          const url =
            this.active == 0 ? "/api/Alipay/pay2" : "/api/Weixinpay/pcPay";
          this.$https.post(url, payData).then((payRes) => {
            if(this.active == 0) {
              window.open(payRes);
              this.getPayStatus(order_id)
            }else {
              this.$refs['pay-modal'].manageModal(true)
              this.wxPayImg = window.baseUrl + '/' + payRes.data.url
              // window.open(window.baseUrl + '/' + payRes.data.url);
              this.getPayStatus(order_id)
            }
          });
        })
        .finally(() => {
          loading.close();
        });
    },
    getPayStatus(order_id) {
            const token = JSON.parse(localStorage.getItem('userInfo')).token
            const data = {
                token,
                order_id
            }
            this.$https.post('/api/Weixinpay/pay_status_check',data).then(res => {
                console.log(res.data.pay_status)
                if(res.data.pay_status == 3) {
                  if(this.active == 1) {
                    this.$refs['pay-modal'].manageModal(false)
                  }
                    Bus.$emit('cash')
                    clearTimeout(this.timer)
                    this.timer = null
                }else {
                    this.timer = setTimeout(() => {
                        this.getPayStatus(order_id)
                    },5000)
                }
            })
        }
  },
};
</script>