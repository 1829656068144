<template>
  <div>
    <div class="right-container">
      <div class="right-container-nav">
        <ul class="flex just-start">
          <li>
            <a href="javascript:;" @click="toAddress">我发布的地址</a>
          </li>
          <li :class="{ active: showActive == 1 }" @click="showActiveFn(1)">
            <a href="javascript:;">我发布的求购</a>
          </li>
          <li :class="{ active: showActive == 2 }" @click="showActiveFn(2)">
            <a href="javascript:;">我发布的代办</a>
          </li>
          <li :class="{ active: showActive == 3 }" @click="showActiveFn(3)">
            <a href="javascript:;">我发布的点子</a>
          </li>
        </ul>
      </div>
      <div class="flex just-start button-nav" id="button-nav">
        <div
          class="button"
          :class="{ active: status == 1 }"
          @click="switchStatus(1)"
        >
          已通过
        </div>
        <div
          class="button"
          :class="{ active: status == 0 }"
          @click="switchStatus(0)"
        >
          待审核
        </div>
        <div
          class="button"
          :class="{ active: status == 2 }"
          @click="switchStatus(2)"
        >
          被退回
        </div>
      </div>
      <div>
        <ul>
          <li class="flex status-list" v-for="item in list" :key="item.id">
            <div class="status-info">
              <p>{{ item.title }}</p>
              <p>
                更新时间：<span class="c333333">{{ item.update_time }}</span>
              </p>
            </div>
            <div class="flex">
              <button @click="handleExit(item)">修改</button>
              <button
                modal-button
                modal-id="prop-modal"
                v-if="status == 1"
                @click="getToolList(item.id)"
              >
                道具
              </button>
              <button @click="deleteItem(item)">删除</button>
            </div>
          </li>
          <!-- <li class="flex status-list">
                                    <div class="status-info">
                                        <p>浙江省杭州市上城区秋涛北路83号</p>
                                        <p>更新时间：<span class="c333333">2023-10-30</span></p>
                                    </div>
                                    <div class="flex">
                                        <button>修改</button>
                                        <button modal-button modal-id="prop-modal">道具</button>
                                        <button>删除</button>
                                    </div>
                                </li>
                                <li class="flex status-list">
                                    <div class="status-info">
                                        <p>浙江省杭州市上城区秋涛北路83号</p>
                                        <p>更新时间：<span class="c333333">2023-10-30</span></p>
                                    </div>
                                    <div class="flex">
                                        <button>修改</button>
                                        <button modal-button modal-id="prop-modal">道具</button>
                                        <button>删除</button>
                                    </div>
                                </li>
                                <li class="flex status-list">
                                    <div class="status-info">
                                        <p>浙江省杭州市上城区秋涛北路83号</p>
                                        <p>更新时间：<span class="c333333">2023-10-30</span></p>
                                    </div>
                                    <div class="flex">
                                        <button>修改</button>
                                        <button modal-button modal-id="prop-modal">道具</button>
                                        <button>删除</button>
                                    </div>
                                </li> -->
        </ul>
      </div>
      <div class="mt25">
        <MyPagination :total="count" @change="change"></MyPagination>
      </div>
    </div>
    <Modal ref="otherprop-modal">
      <div class="prop modal">
        <div class="modal-container">
          <div class="modal-main block">
            <p class="prop-title">道具列表</p>
            <img
              src="@/assets/img/z-icon9.png"
              class="prop-close"
              @click="$refs['otherprop-modal'].manageModal(false)"
            />
            <div>
              <ul class="flex">
                <li v-for="item in toolList" :key="item.id">
                  <div class="flex just-start prop-intro">
                    <img :src="item.thumb" />
                    <div class="prop-intro-text">
                      <p>{{ item.title }}</p>
                      <p>{{ item.coins * 1 }}旺点币</p>
                      <p>增加{{ item.fiery }}火热值</p>
                    </div>
                  </div>
                  <button type="button" @click="buy(item.id)">立即购买</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal ref="delete-modal">
      <div class="prop modal">
        <div class="modal-container">
          <div class="modal-main block block2">
            <p class="prop-title">温馨提示</p>
            <img
                src="@/assets/img/z-icon9.png"
                class="prop-close"
                @click="$refs['delete-modal'].manageModal(false)"
              />
              <div class="delete-text">
                是否删除?
              </div>
              <div class="flex just-center align-center">
                <button type="button"  @click="deleteItems(itemObj)">确定</button>
                <button type="button" class="orange" @click="$refs['delete-modal'].manageModal(false)">取消</button>
              </div>
          </div>
        </div>
      </div>
    </Modal> 
  </div>
</template>
<script>
import Bus from "@/util/bus";
export default {
  props: ["active","id","pagestatus"],
  data() {
    return {
      showActive: 1,
      status: 1,
      list: [],
      count: 0,
      page: 1,
      toolObject: null,
      itemObj: null,
      toolList: [],
    };
  },
  created() {
    if(this.id && this.id != 0) {
      this.showActive = this.id
      this.status = this.pagestatus
    }else {
      this.showActive = this.active;
    }
    this.getList();
  },
  methods: {
    handleExit(item) {
      if (this.showActive == 1) {
        window.location.href = "/buy_release.html?id=" + item.id;
      } else if (this.showActive == 2) {
        window.location.href = "/agent_release.html?id=" + item.id;
      } else {
        window.location.href = "/syb_release.html?id=" + item.id;
      }
    },
    toAddress() {
      this.$emit("update-active", 0);
    },
    showActiveFn(val) {
      this.showActive = val;
      this.status = 1;
      this.page = 1;
      this.getList();
    },
    getList() {
        const loading = this.showLoading()
        const token = JSON.parse(localStorage.getItem('userInfo')).token
        const baseUrl = this.showActive == 1 ? '/api/Wantbuy/getlist' : this.showActive == 3 ? '/api/Ideas/getlist' : '/api/UsersCenter/daibanList'
        // const url = `${baseUrl}/?page=${this.page}&audit=${this.status}&token=${token}&status=${this.status}`
        let data = {
            page: this.page,
            token
        }
        if(this.showActive == 3 || this.showActive == 2) {
            data = Object.assign(data,{audit:this.status })
        }else {
            data = Object.assign(data,{status:this.status })
        }
        this.$https.post(baseUrl,data).then(res => {
            this.list = res.data
            this.count = Number(res.count)
        }).finally(() => {
            loading.close()
        })
    },
    switchStatus(val) {
      this.page = 1;
      this.status = val;
      this.getList();
    },
    deleteItem(item) {
      this.$refs['delete-modal'].manageModal(true)
      this.itemObj = item
    },
    deleteItems(item) {
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
            const baseUrl =
              this.showActive == 1
                ? "/api/Wantbuy/delete"
                : this.showActive == 3
                ? "/api/Ideas/delete"
                : "/api/UsersCenter/delDaiBan";
            const data = {
              id: Number(item.id),
              token,
            };
            this.$https.post(baseUrl, data).then((res) => {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.$refs['delete-modal'].manageModal(false)
              this.getList();
            });
      
    },
    change(index) {
      this.page = index;
      this.getList();
    },
    //获取道具列表
    getToolList(id) {
      this.$https.get("/api/Currency/propslist?cat_id=1").then((res) => {
        this.toolList = res.data;
        this.toolObject = id;
        this.$refs["otherprop-modal"].manageModal(true);
        Bus.$emit('cash')
      });
    },
    buy(id) {
      if (!this.toolObject) {
        this.$message({
          message: "缺少使用对象",
          type: "warning",
        });
        return;
      }
      const loading = this.showLoading();
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      const data = {
        id: id,
        common_id: this.toolObject,
        token,
        typeid: this.showActive == 1 ? 3 : this.showActive == 2 ? 2 : 4,
      };
      this.$https
        .post("/api/Currency/props", data)
        .then((res) => {
          this.$refs["otherprop-modal"].manageModal(false);
          Bus.$emit("cash");
          this.$message({
            message: res.message,
            type: "success",
          });
        })
        .finally(() => {
          loading.close();
        });
    },
  },
  watch: {
    props: function (val) {
      console.log(val);
    },
    // active: function(val) {
    //     this.showActive = val
    // },
    deep: true,
  },
};
</script>