<template>
  <div>
    <div class="crumbs">
      <img src="@/assets/img/z-icon1.png" />
      当前所在位置：<a href="/">旺点来</a>
      <!-- <template v-for="(item,index) in routeList" >
                    &gt;<router-link :to="item.path" :key="index">{{ item.name }}</router-link>
                </template> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "Crumbs",
  data() {
    return {
      name: "",
      routeList: [],
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //     this.routeList.unshift({
    //         name: this.$route.meta.name,
    //         path: this.$route.fullPath
    //     })
    //     if(this.$route.matched.length == 1) {
    //         return
    //     }else {
    //         this.getRouteList(this.$route.matched[this.$route.matched.length - 1].parent)
    //     }
    // })
  },
  methods: {
    // getRouteList(route) {
    //     if(route) {
    //         this.routeList.unshift({
    //             name: route.meta.name,
    //             path: route.path
    //         })
    //         this.getRouteList(route.parent)
    //     }else {
    //         return
    //     }
    // }
  },
  watch: {
    // $route(newRoute) {
    //     this.routeList = []
    //     this.routeList.unshift({
    //         name: newRoute.meta.name,
    //         path: newRoute.fullPath
    //     })
    //     if(newRoute.matched.length == 1) {
    //         return
    //     }else {
    //         this.getRouteList(newRoute.matched[newRoute.matched.length - 1].parent)
    //     }
    // }
  },
};
</script>