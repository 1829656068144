<template>
    <div>
        <div class="news">
        <div class="index-container">
            <div class="news-nav">
                <div class="flex news-left">
                    <div class="header-select-options">
                    </div>
                    <div class="news-nav-swiper">
                        <div class="swiper-container swiper-index">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="item in banner" :key="item.id">
                                    <img class="wh100" :src="item.picurl" >
                                </div>
                            </div>
                            <div class="swiper-button swiper-button-prev"></div>
                            <div class="swiper-button swiper-button-next"></div>
                            <div class="swiper-pagination swiper-index-pagination"></div>
                        </div>
                    </div>
                    <user-card style="margin-top: 10px;"></user-card>
                    <!-- <div class="news-nav-right">
                        <img src="@/assets/img/z-icon2.png" class="news-nav-head">
                        <p class="news-nav-phone">18637284624</p>
                        <p class="news-nav__p">如何买地址？如何卖地址？</p>
                        <button class="news-nav__button">个人中心</button>
                    </div> -->
                </div>
            </div>
            <div class="flex news-block">
                <div class="news-block-left">
                    <div class="flex align-center">
                        <p class="block-left__p">转让地址</p>
                        <!-- <p class="block-left__p">关键词搜索</p> -->
                        <div class="block-left-more">
                            <a href="/address.html" class="flex align-center">
                                <p>查看更多</p>
                                <img src="@/assets/img/z-icon20.png">
                            </a>
                        </div>
                        
                    </div>
                    <div class="block-left-list">
                            <ul class="flex">
                                <template v-for="item in hotAddresNewsCate">
                                    <li :key="item.id">
                                        <!-- <a :href="'/address.html?province='+item.id">
                                            {{ item.title }}
                                        </a> -->
                                        <a :href="'/address.html?keyword='+item.title">
                                            {{ item.title }}
                                        </a>
                                    </li>
                                </template>
                            </ul>
                        </div>
                </div>  
                <div class="news-block-main">
                    <p class="block-main__p">转让地址新闻动态</p>
                    <div class="block-main-swiper">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <template v-for="(item,index) in hotAddressNewsList">
                                    <div class="swiper-slide" :key="item.id" v-if="index < 5">
                                        <a :href="'/new_detail.html?id='+item.id">
                                            <img :src="item.picurl">
                                        </a>
                                    </div>
                                </template>
                            </div>
                            <!-- 如果需要分页器 -->
                            <!-- <div class="swiper-pagination block-main-pagination"></div> -->
                        </div>
                        <div class="block-main-swiper-pagination" >
                            <p>{{ swiperTitle }}</p>
                            <div class="swiper-pagination block-main-pagination"></div>
                        </div>
                    </div>
                    <div class="block-main-li">
                        <ul class="flex">
                            <template v-for="(item,index) in hotAddressNewsList">
                                <li :key="item.id" v-if="index >= 5">
                                    <a :href="'/new_detail.html?id='+item.id">
                                        {{ item.title }}
                                    </a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
                <div class="news-block-right">
                    <p class="block-right__p">转让地址热门关键词</p>
                    <div class="block-right-list">
                        <ul class="flex">
                            <li v-for="item in addressHotSign" :key="item.id">
                                <a :href="'/address.html?keyword='+item.title">{{ item.title }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="news-ad">
                <div class="swiper-ad swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="item in adList" :key="item">
                            <img :src="item" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex news-hot">
                <div class="hot-left">
                    <p class="hot-left__p">热门资讯</p>
                    <div class="hot-left-list">
                        <ul>
                            <li :class="{'active':(hotActive == index)}" v-for="(item,index) in hotCate" :key="item.id" @mouseenter="hotMouseEnter(index,item)">
                                <a :href="'/new_list.html?cateid='+item.id+'&title='+item.name" class="flex align-center">
                                    <p>{{ item.name }}</p>
                                    <div class="img"></div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="hot-main" id="hotmain">
                    <div class="hot-main-top" v-if="hotList.length > 0">
                        <a :href="'/new_detail.html?id='+hotList[0].id" class="flex just-start">
                            <img :src="hotList[0].picurl" class="hot-main-litpic">
                            <div class="hot-main-info">
                                <p class="hot-main-time">{{ hotList[0].add_time }}</p>
                                <p class="hot-main-title">{{ hotList[0].title }}</p>
                                <p class="hot-main-desc">{{ hotList[0].description }}</p>
                                <div class="hot-main-more">
                                    <a :href="'/new_detail.html?id='+hotList[0].id" class="flex just-start align-center">
                                        <p>查看详情</p>
                                        <img src="@/assets/img/z-icon28.png">
                                    </a>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="hot-main-list">
                        <ul class="flex">
                            <template v-for="(item,index) in hotList">
                                <li :key="item.id" v-if="index > 0">
                                    <a :href="'/new_detail.html?id='+item.id">{{ item.title }}</a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
                <div class="hot-right">
                    <p class="hot-right__p">热门资讯排行</p>
                    <div class="hot-right-list">
                        <ul>
                            <li v-for="(item,index) in hot" :key="item.id">
                                <a :href="'/new_detail.html?id='+item.id" class="flex just-start">
                                    <span :class="{'active': index < 3}">{{ index + 1 }}</span>
                                    <p>{{ item.title }}</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="flex news-hot">
                <div class="hot-left">
                    <p class="hot-left__p">转让地址热点</p>
                    <div class="hot-left-list">
                        <ul>
                            <li :class="{'active':(hotAddressActive == index)}" v-for="(item,index) in hotAddressCate" :key="item.id" @mouseenter="hotAddressMouseEnter(index,item)">
                                <a :href="'/new_list.html?cateid='+item.id+'&title='+item.name" class="flex align-center">
                                    <p>{{ item.name }}</p>
                                    <div class="img"></div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="hot-main" id="addressmain">
                    <div class="hot-main-top" v-if="hotAddressList.length > 0">
                        <a :href="'/new_detail.html?id='+hotAddressList[0].id" class="flex just-start">
                            <img :src="hotAddressList[0].picurl" class="hot-main-litpic">
                            <div class="hot-main-info">
                                <p class="hot-main-time">{{ hotAddressList[0].add_time }}</p>
                                <p class="hot-main-title">{{ hotAddressList[0].title }}</p>
                                <p class="hot-main-desc">{{ hotAddressList[0].description }}</p>
                                <div class="hot-main-more">
                                    <a :href="'/new_detail.html?id='+hotAddressList[0].id" class="flex just-start align-center">
                                        <p>查看详情</p>
                                        <img src="@/assets/img/z-icon28.png">
                                    </a>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="hot-main-list">
                        <ul class="flex">
                            <template v-for="(item,index) in hotAddressList">
                                <li :key="item.id" v-if="index > 0">
                                    <a :href="'/new_detail.html?id='+item.id">{{ item.title }}</a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
                <div class="hot-right">
                    <p class="hot-right__p">热门转让地址排行</p>
                    <div class="hot-right-list">
                        <ul>
                            <li v-for="(item,index) in address" :key="item.id">
                                <a :href="'/new_detail.html?id='+item.id" class="flex just-start align-center">
                                    <span :class="{'active': index < 3}">{{ index + 1 }}</span>
                                    <p>{{ item.title }}</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import 'swiper/css/swiper.css'
import Swiper from 'swiper'
import userCard from '@/components/userCard.vue'
export default {
    components: { userCard },
    data() {
        return {
            banner: [],
            hot: [],
            address: [],
            hotCate: [],
            hotAddressCate: [],
            hotAddresNewsCate: [],//转让地址新闻分类
            hotActive: 0,
            hotAddressActive: 0,
            hotList: [],//热门资讯列表
            hotAddressList: [],//热门地址列表
            hotAddressNewsList: [],//转让地址新闻动态
            loading: null,
            addressHotSign: [],
            swiperTitle: '',
            adList: []
        }
    },
    created() {
        // this.getList()
        this.loading = this.showLoading()
        Promise.all([
            this.getCateList(),
            this.getNewsList(1,6).then(res => {
                this.hot = res.data
            }),
            this.getNewsList(2,6).then(res => {
                this.address = res.data
            }),
            this.getAddressHotSign(),
            this.getAddressCateList(),
            this.getAddressList(),
            this.getBanner(),
            this.getAd()
        ]).finally(() => {
            this.loading.close()
            this.loading = null
        })
        // this.getCateList()
        // //获取热门资讯排行
        // this.getNewsList(1,6).then(res => {
        //     this.hot = res.data
        // })
        // //获取热门地址排行
        // this.getNewsList(2,6).then(res => {
        //     this.address = res.data
        // })
    },
    mounted() {
        // const that = this
        // this.$nextTick(() => {
            // new Swiper(".block-main-swiper .swiper-container", {
            //     speed: 1000,
            //     autoplay: {
            //         delay: 3000,
            //     //     disableOnInteraction: false
            //     },
            //     // loop: true,
            //     pagination: {
            //         el: ".block-main-pagination"
            //     },
            //     on: {
            //         slideChange: function() {
            //             that.swiperTitle = that.hotAddressNewsList[this.activeIndex].title
            //         }
            //     }
            // });
            // new Swiper(".swiper-index", {
            //     speed: 1000,
            //     autoplay: {
            //         delay: 3000,
            //         // disableOnInteraction: false
            //     },
            //     // loop: true,
            //     navigation: {
            //         nextEl: '.swiper-button-next',
            //         prevEl: '.swiper-button-prev',
            //     },
            //     pagination: {
            //         el: ".swiper-index-pagination"
            //     }
            // })
        
        // })
    },
    methods: {
        getBanner() {
            this.$https.get('/api/Currency/banner?is_pc=1').then(res => {
                this.banner = res.data
                this.$nextTick(() => {
                    new Swiper(".swiper-index", {
                        speed: 1000,
                        autoplay: {
                            delay: 3000,
                            disableOnInteraction: false
                        },
                        loop: true,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        pagination: {
                            el: ".swiper-index-pagination"
                        }
                    })
                })
            })
        },
        getAddressCateList() {
                this.$https.post('/api/Currency/categorylist',{typeid: 9}).then(res => {
                    this.hotAddresNewsCate = res.data
                })
            },
        //220热门资讯 221转让地址热点 222转让地址新闻动态
        getCateList() {
            this.$https.get('/api/News/category').then(res => {
                res.data.forEach(item => {
                    if(item.id == 220) {
                        this.hotCate = item.child
                    }else if(item.id == 221) {
                        this.hotAddressCate = item.child
                    } 
                })
            }).then(() => {
                Promise.all([this.getList(this.hotCate[0]),this.getList(this.hotAddressCate[0])])
            })
        },
        getAddressList() {
            let loading = null
            const that = this
            if(!this.loading) {
                loading = this.showLoading()
            }
            return this.$https.get('/api/News/list?page=1&limit=13&catid=223&posid=2').then(res => {
                this.hotAddressNewsList = res.data
                this.swiperTitle = this.hotAddressNewsList.length ? this.hotAddressNewsList[0].title : ''
                this.$nextTick(() => {
                    new Swiper(".block-main-swiper .swiper-container", {
                        speed: 1000,
                        autoplay: {
                            delay: 3000,
                        //     disableOnInteraction: false
                        },
                        // loop: true,
                        pagination: {
                            el: ".block-main-pagination"
                        },
                        on: {
                            slideChange: function() {
                                that.swiperTitle = that.hotAddressNewsList[this.activeIndex].title
                            }
                        }
                    });
                })
            }).finally(() => {
                if(loading) {
                    loading.close()
                }
            })
        },
        getList(obj) {
            let loading = null
            if(!this.loading) {
                loading = this.showLoading({
                    target: obj.parentid == 220 ? document.getElementById('hotmain') : document.getElementById('addressmain')
                })
            }
            const url = `/api/News/list?page=1&limit=9&catid=${obj.id}&posid=2`
            return this.$https.get(url).then(res => {
                if(obj.parentid == 220) {
                    this.hotList = res.data
                }else if(obj.parentid == 221) {
                    this.hotAddressList = res.data
                }
            }).finally(() => {
                if(loading) {
                    loading.close()
                }
            })
        },
        getNewsList(type,limit) {
            const url = `/api/News/list?page=1&limit=${limit}&type=${type}`
            return this.$https.get(url)
        },
        //获取地址热门标签
        getAddressHotSign() {
            return this.$https.post('/api/Currency/categorylist',{typeid: 10}).then(res => {
                this.addressHotSign = res.data
            })
        },
        hotMouseEnter(index,obj) {
            this.hotActive = index
            this.getList(obj)
        },
        hotAddressMouseEnter(index,obj) {
            this.hotAddressActive = index
            this.getList(obj)
        },
        getAd() {
            const data = {
                type: 4,
                is_pc: 1
            }
            this.$https.post('/api/Currency/banner',data).then(res => {
                this.adList = res.data
                this.$nextTick(() => {
                    new Swiper ('.swiper-ad', {
                        direction: 'vertical', // 垂直切换选项
                        autoplay: {
                            delay: 3000,
                            disableOnInteraction: false
                        },
                        loop: true, // 循环模式选项
                    }) 
                })
            })
        }
    }
}
</script>