<template>
  <div id="app" :style="'background:'+ '#f7f7f7'">
    <Header></Header>
    <component v-bind:is="MainName"></component>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import Index from './pages/Home.vue';
import Address from './pages/address/index.vue';
import AddressDetail from './pages/address/detail.vue';
import AddressRelease from './pages/address/child/release.vue';
import AddressBargain from './pages/address/child/bargain.vue';
import Agent from './pages/agent/index.vue';
import AgentDetail from './pages/agent/detail.vue';
import AgentRelease from './pages/agent/child/release.vue';
import Buy from './pages/buy/index.vue';
import BuyRelease from './pages/buy/child/release.vue';
import BuyDetail from './pages/buy/detail.vue';
import Syb from './pages/syb/index.vue';
import SybDetail from './pages/syb/detail.vue';
import SybRelease from './pages/syb/submit.vue';
import News from './pages/news/index.vue';
import NewList from './pages/news/list.vue'
import NewDetail from './pages/news/detail.vue'
import Coo from './pages/coo/index.vue';

import Personal from './pages/personal/index.vue';
// import Cashout from "./pages/personal/cashout.vue";
// import Collect from "./pages/personal/collect.vue";
import Evaluate from "./pages/personal/evaluate.vue";
// import Order from "./pages/personal/order.vue";
// import Password from "./pages/personal/password.vue";
import Recharge from "./pages/personal/recharge.vue";
import BalanceRecharge from "./pages/personal/balanceRecharge.vue";
import About from "./pages/about/index.vue"
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Index,
    Address,
    AddressDetail,
    AddressRelease,
    AddressBargain,
    Agent,
    AgentDetail,
    AgentRelease,
    Buy,
    BuyDetail,
    BuyRelease,
    Syb,
    SybDetail,
    SybRelease,
    News,
    NewList,
    NewDetail,
    Coo,
    Personal,
    // Cashout,
    // Collect,
    Evaluate,
    // Order,
    // Password,
    Recharge,
    BalanceRecharge,
    About
  },
  data () {
    return {
      MainName: ''
    }
  },
  mounted () {
    if (this.pathname === '/') {
      this.MainName = 'Index'
      return
    }
    let pathname = this.pathname.slice(1, this.pathname.indexOf('.html'))
    if (pathname.includes("_")) {
      pathname = pathname.split("_")
    } else {
      pathname = [pathname]
    }
    let name = ''
    pathname.map(item => {
      name += item.slice(0, 1).toUpperCase() + item.slice(1).toLowerCase()
    })
    this.MainName = name
  }
}
</script>

<style>
</style>
