<template>
  <main class="release my-background">
    <section class="section1 index-container">
      <div class="crumbs">
        <img src="../../../assets/img/z-icon1.png" />
        当前所在位置：<a href="/">旺点来</a> >
        <a href="address.html">找地址</a> > <a class="active">发布地址</a>
      </div>
    </section>
    <section class="section2 index-container my-list3">
      <template v-for="(item, index) in arr1">
        <div class="item" :key="index">
          <div class="item-container">
            <div class="item-content">{{ index + 1 }}</div>
          </div>
          <p class="item-text">{{ item }}</p>
        </div>
        <span
          :key="index + arr1.length"
          v-if="index + 1 !== arr1.length"
        ></span>
      </template>
    </section>
    <section class="section3 index-container my-form">
      <p class="top-title">
        发布地址信息&emsp;&emsp;<span
          >您发布的信息均为免费，欢迎您成为旺点来的一员!</span
        >
      </p>
      <div class="item">
        <div class="item-title">
          <span>基础信息</span>
        </div>
        <div class="form">
          <div class="form-item">
            <div class="form-left">编号：</div>
            <div class="form-right">
              <el-input
                v-model="sendForm.address_sn"
                class="w-340"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">地址名称：</div>
            <div class="form-right">
              <el-input
                v-model="sendForm.title"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">地址类型：</div>
            <div class="form-right">
              <el-radio-group v-model="sendForm.cat_id">
                <el-radio
                  v-for="item in catArr"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">所在地区：</div>
            <div class="form-right">
              <mySelect
                :data="provinceArr"
                labelName="name"
                valueName="id"
                v-model="sendForm.province"
                width="216"
              ></mySelect>
              <mySelect
                :data="cityArr"
                labelName="name"
                valueName="id"
                v-model="sendForm.city"
                width="216"
              ></mySelect>
              <mySelect
                :data="areaArr"
                labelName="name"
                valueName="id"
                v-model="sendForm.district"
                width="216"
              ></mySelect>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">期限：</div>
            <div class="form-right">
              <el-input
                v-model="sendForm.term"
                placeholder="请输入租赁时长，若为售卖地址，默认无期限"
              ></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">热门标签：</div>
            <div class="form-right">
              <el-checkbox-group v-model="sendForm.hottags">
                <el-checkbox
                  v-for="item in hottagsArr"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.title }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">地址性质：</div>
            <div class="form-right">
              <mySelect
                :data="natureArr"
                labelName="title"
                valueName="id"
                v-model="sendForm.nature_id"
                width="216"
              ></mySelect>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">地址描述：</div>
            <div class="form-right">
              <el-input
                v-model="sendForm.address_desc"
                type="textarea"
                rows="6"
                resize="none"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="item-title">
          <span>入驻信息</span>
        </div>
        <div class="form">
          <div class="form-item">
            <div class="form-left tip">入驻类型：</div>
            <div class="form-right">
              <el-input
                v-model="sendForm.settled_type"
                placeholder="请输入入驻行业类型"
              ></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">入驻行业描述：</div>
            <div class="form-right">
              <el-input
                v-model="sendForm.settled_desc"
                type="textarea"
                rows="6"
                resize="none"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="item-title">
          <span>税务信息</span>
        </div>
        <div class="form">
          <div class="form-item">
            <div class="form-left tip">纳税类型：</div>
            <div class="form-right">
              <el-checkbox-group v-model="sendForm.tax_type">
                <el-checkbox
                  v-for="item in taxArr"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">这个地址能否开发票：</div>
            <div class="form-right">
              <el-radio-group v-model="sendForm.is_invoice">
                <el-radio :label="1">可以</el-radio>
                <el-radio :label="0">不可以</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">这个地址能否出口退税：</div>
            <div class="form-right">
              <el-radio-group v-model="sendForm.is_rebate">
                <el-radio :label="1">可以</el-radio>
                <el-radio :label="0">不可以</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="item-title">
          <span>地址展示</span>
        </div>
        <div class="form">
          <div class="form-item">
            <div class="form-left">地址照片：</div>
            <div class="form-right imgs">
              <div
                class="img-item"
                v-for="(item, index) in sendForm.images"
                :key="index"
              >
                <img class="wh100" :src="item.includes('http') ? item : baseUrl + item" />
                <p class="del" @click="del(index)">
                  <img src="../../../assets/img/icon48.png" />
                </p>
              </div>
              <el-upload
                class="img-upload"
                :action="baseUrl + '/api/Currency/dyupload'"
                name="dypic"
                :show-file-list="false"
                :on-success="upload"
              >
                <img src="../../../assets/img/icon47.png" />
                <p>上传照片</p>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="item-title">
          <span>转让信息</span>
        </div>
        <div class="form">
          <div class="form-item">
            <div class="form-left tip">出售模式：</div>
            <div class="form-right">
              <el-radio-group v-model="sendForm.sell_model">
                <el-radio
                  v-for="item in sellArr"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </div>
          </div>
          <div class="form-item" v-if="sendForm.sell_model == 3">
            <div class="form-left tip">一口价：</div>
            <div class="form-right">
              <el-input
                v-model="sendForm.price"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
          <div class="form-item" v-if="sendForm.sell_model == 4">
            <div class="form-left tip">起购价设置：</div>
            <div class="form-right">
              <el-input
                v-model="sendForm.price"
                placeholder="请输入起拍价"
              ></el-input>
            </div>
          </div>
          <div class="form-item" v-if="sendForm.sell_model == 4">
            <div class="form-left tip">抢购时间设置：</div>
            <div class="form-right">
              <el-date-picker
                v-model="sendForm.start_time"
                type="datetime"
                prefix-icon="span"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择开始时间"
              >
              </el-date-picker>
              <el-date-picker
                v-model="sendForm.end_time"
                type="datetime"
                prefix-icon="span"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择结束时间"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="item-title">
          <span>联系方式</span>
        </div>
        <div class="form">
          <div class="form-item">
            <div class="form-left tip">联系电话：</div>
            <div class="form-right">
              <el-input
                maxlength="11"
                v-model="sendForm.mobile"
                placeholder="请输入手机号"
              ></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">联系人：</div>
            <div class="form-right">
              <el-input
                v-model="sendForm.contacts"
                placeholder="请输入联系人"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="item submit">
        <div class="my-radio">
          <input v-model="sendForm.xy" type="checkbox" id="item1" name="type" />
          <div class="checked"></div>
          <label for="item1"
            >我已阅读并同意<a target="_blank" href="about.html?id=1" class="xy">《旺点来转让协议》</a></label
          >
        </div>
        <button class="my-button" @click="submit">立即发布</button>
      </div>
    </section>
    <Modal ref="modal">
      <div class="release-modal">
        <img
          @click="$refs.modal.close()"
          class="close-icon"
          src="../../../assets/img/z-icon9.png"
          alt=""
        />
        <img class="icon" src="../../../assets/img/icon46.png" alt="" />
        <p>发布成功，请等待审核</p>
        <p>您还可以</p>
        <div class="btns">
          <button class="my-button" @click="$refs.modal.close();reset()">
            继续发布
          </button>
          <button class="my-button" @click="lookExamine">查看审核</button>
          <button class="my-button"><a href="address.html">返回列表</a></button>
        </div>
      </div>
    </Modal>
  </main>
</template>

<script>
import mySelect from "@/components/mySelect.vue";
import Modal from "@/components/Modal";
import { getRegionList, copyObject, arrToString, getUrlData } from "@/util/utils";
import { Message } from "element-ui";
export default {
  name: "release",
  components: { mySelect, Modal },
  data() {
    return {
      catArr: [],
      taxArr: [],
      sellArr: [],
      natureArr: [],
      hottagsArr: [],
      arr1: [
        "免费发布",
        "平台展示",
        "用户购买",
        "在线支付",
        "线下发货",
        "买家确认收货",
        "订单完成",
        "申请提现",
      ],
      provinceArr: [],
      cityArr: [],
      areaArr: [],
      sendForm: {
        tax_type: [],
        hottags: [],
        sell_model: 0,
        images: [],
        province: null,
        city: null,
        district: null,
        is_invoice: 1,
        is_rebate: 1
      },
      id: null,
      oneStatus: true,
      oneStatus1: true
    };
  },
  async mounted() {
    this.provinceArr = await getRegionList();
    const urls = [];
    const typeid = [0, 1, 2];
    typeid.map((item) => {
      urls.push(this.$https.post("/api/Address/category", { typeid: item }));
    });
    urls.push(this.$https.post("/api/Currency/categorylist", { typeid: 2 }));
    urls.push(this.$https.post("/api/Currency/categorylist", { typeid: 3 }));
    const loading = this.showLoading();
    Promise.all(urls)
      .then((res) => {
        this.catArr = res[0].data;
        this.taxArr = res[1].data;
        this.sendForm.tax_type.push(res[1].data[0].id)
        this.sellArr = res[2].data;
        this.natureArr = res[3].data;
        this.hottagsArr = res[4].data;
      })
      .finally(() => {
        loading.close();
      });
    let query = getUrlData()
    if (query.id) {
      this.id = parseInt(query.id)
      this.getDetail()
    }
    // const textObj = {
    //   tax_type: [1],
    //   hottags: [3, 4, 9],
    //   sell_model: 3,
    //   images: [
    //     "/upload//2023/12-05/0cd9638a5a48cd85da35f1aaa03f9d7f.jpg",
    //     "/upload//2023/12-05/46041979777615173a930102359a6a59.jpg",
    //     "/upload//2023/12-05/575bd90ddcd4fb217982d85017e8b208.jpg",
    //   ],
    //   address_sn: "732545621",
    //   title: "浙江省拱墅区复地壹中心A座4楼412室",
    //   cat_id: 1,
    //   province: 11,
    //   city: 124,
    //   district: 1304,
    //   term: "",
    //   nature_id: 3,
    //   address_desc:
    //     "杭州经济技术开发区，是1993年4月经国务院批准设立的国家级开发区，是全国唯一集工业园区、高教园区、出口加工区于一体的国家级开发区，委托管理下沙和白杨两个街道，辖区人口约45万人。\n杭州经济技术开发区是中国唯一的集产业园区、出口加工区、高教园区于一体的国家级开发区，拥有浙江省最大的高教园区。\n2019年，位列国家级经开区综合排名全国第14。",
    //   settled_type:
    //     "工业    商贸   食品生产   食品销售    科技   文化    危险化学品",
    //   settled_desc:
    //     "化工、生物科技类：化工和生物科技类公司较多，可以形成产业聚集效应，降低企业运营成本，提高竞争力。",
    //   is_invoice: 1,
    //   is_rebate: 0,
    //   price: "500000",
    //   mobile: "13888888888",
    //   contacts: "王先生",
    // };
    // this.sendForm = textObj;
  },
  methods: {
    submit() {
      const loading = this.showLoading({
        target: ".my-form .item.submit .my-button",
      });
      const formData = copyObject(this.sendForm);
      if (!formData.xy) {
        Message.error("请勾选协议！");
        loading.close();
        return;
      }
      delete formData.xy;
      formData.hottags = arrToString(formData.hottags);
      formData.images = arrToString(formData.images);
      formData.tax_type = arrToString(formData.tax_type);
      if (formData.sell_model == 1 || formData.sell_model == 2) {
        delete formData.price;
        delete formData.start_time;
        delete formData.end_time;
      } else if (formData.sell_model == 3) {
        delete formData.start_time;
        delete formData.end_time;
      }
      formData.token = this.$store.state.token;
      if (this.id) {
        formData.id = this.id
      }
      this.$https
        .post("/api/Address/addAddress", formData)
        .then(() => {
          this.$refs.modal.manageModal(true);
        })
        .finally(() => {
          loading.close();
        });
    },
    del(index) {
      this.sendForm.images.splice(index, 1);
    },
    upload(response) {
      if (response.errcode == 1) {
        this.sendForm.images.push(response.data);
      } else {
        Message.error(response.message)
      }
    },
    getDetail () {
      const loading = this.showLoading();
      const data = {
        id: this.id,
        token: this.$store.state.token
      }
      this.$https.post("/api/Address/getdetail", data).then(async ({ data }) => {
        let obj = data
        obj.hottags = obj.hottags.map(item => {
          return parseInt(item)
        })
        this.cityArr = await getRegionList(obj.province, 2);
        this.areaArr = await getRegionList(obj.city, 3);
        this.sendForm = obj
      }).finally(() => {
        loading.close();
      });
    },
    lookExamine () {
      location.replace("personal.html?id=0&status=0&type=0")
    },
    reset () {
      this.sendForm = {
        tax_type: [],
        hottags: [],
        sell_model: 0,
        images: [],
        province: null,
        city: null,
        district: null,
        is_invoice: 1,
        is_rebate: 1
      }
      this.sendForm.tax_type.push(this.taxArr[0].id)
    }
  },
  watch: {
    async "sendForm.province"(newValue) {
      if (this.id && this.oneStatus) {
        this.oneStatus = false
        return
      }
      this.cityArr = [];
      this.areaArr = [];
      this.sendForm.city = null;
      this.sendForm.district = null;
      if (newValue) {
        this.cityArr = await getRegionList(newValue, 2);
      }
    },
    async "sendForm.city"(newValue) {
      if (this.id && this.oneStatus1) {
        this.oneStatus1 = false
        return
      }
      this.areaArr = [];
      this.sendForm.district = null;
      if (newValue) {
        this.areaArr = await getRegionList(newValue, 3);
      }
    }
    // ,'sendForm.tax_type' (newValue) {
    //   if (newValue.length > 1) {
    //     this.sendForm.tax_type.shift()
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
.release-modal {
  width: 658px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 84px 0 74px;
  text-align: center;
  .icon {
    margin-bottom: 10px;
  }
  p {
    font-size: 22px;
    line-height: 1.3;
  }
  .btns {
    margin-top: 32px;
  }
  .my-button {
    width: 130px;
    height: 42px;
    border-radius: 21px;
    & + .my-button {
      margin-left: 10px;
    }
  }
}
</style>