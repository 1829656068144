<template>
    <div class="right-item">
        <p class="right-title">历史评价</p>
        <div class="my-evaluate">
            <div
                class="evaluate-item"
                v-for="item in list"
                :key="item.id">
                <div class="evaluate-top">
                    <div class="evaluate-img">
                        <img class="evaluate-tx" :src="item.head_pic" alt="">
                    </div>
                    <div class="evaluate-info">
                        <p class="evaluate-name">{{ item.uid }}</p>
                        <p class="evaluate-time">{{ item.add_time }}</p>
                    </div>
                    <el-rate class="evaluate-star" :disabled="true" :value="item.stars || 0"></el-rate>
                </div>
                <div class="evaluate-content">{{ item.content }}</div>
            </div>
            <myPagination
                type="small"
                class="pagination"
                :total="total"
                :pageSize="3"
                v-model="sendData.page"
                :pagerCount="pagerCount"></myPagination>
        </div>
    </div>
</template>

<script>
import myPagination from '@/components/myPagination.vue'
export default {
    name: 'evaluate',
    components: { myPagination },
    props: {
        id: Number
    },
    data () {
        return {
            pagerCount: 3,
            total: 0,
            list: [],
            sendData: {
                publish_id: null,
                typeid: 1,
                page: 1 
            }
        }
    },
    methods: {
        getList () {
            const data = this.sendData
            this.$https.post("/api/Users/evaluatelist", data).then(res => {
                this.list = res.data
                this.total = res.count
            })
        }
    },
    watch: {
        'id' (newValue) {
            this.sendData.publish_id = newValue
            this.getList()
        },
        'sendData.page' () {
            this.getList()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>