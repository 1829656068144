<template>
    <div>
        <div class="commercial">
        <div class="index-container">
            <div class="crumbs">
                <img src="@/assets/img/z-icon1.png">
                当前所在位置：<a href="/">旺点来</a> 
                &gt;<a href="javascript:;">商务合作</a>
                <!-- <template v-for="(item,index) in routeList" >
                    &gt;<router-link :to="item.path" :key="index">{{ item.name }}</router-link>
                </template> -->
            </div>
            <div class="commercial-container">
                <p class="commercial__p">在线提交</p>
                <div class="commercial-tip">
                    <div class="flex just-start">
                        <p class="commercial-tip-label">合作对象：</p>
                        <div class="commercial-tip-input">
                            <p>1.从事收购公司的中介</p>
                            <p>2.专业的财务公司</p>
                        </div>
                    </div>
                    <div class="flex just-start">
                        <p class="commercial-tip-label">合作概括：</p>
                        <div class="commercial-tip-input">
                            <p>旺点来平台转让信息资源丰富，各类型的公司层出不穷，为您长期从事收购事业，提供强有力的渠道保障！</p>
                        </div>
                    </div>
                </div>
                <form action="" class="commercial-form">
                    <div class="commercial-form-block">
                        <label for="">
                            <span>*</span>姓名：
                        </label>
                        <input type="text" placeholder="请输入" class="commercial__input"  v-model="queryForm.title" >
                    </div>
                    <div class="commercial-form-block">
                        <label for="">
                            <span>*</span>电话：
                        </label>
                        <input type="text" placeholder="请输入" class="commercial__input" v-model="queryForm.mobile" >
                    </div>
                    <div class="commercial-form-block commercial-form-textarea">
                        <label for="">
                            <span>*</span>需求：
                        </label>
                        <textarea type="text" placeholder="简单描述您收购公司的类型及要求！" placeholder-style="font-size: 16px;font-family: '微软雅黑'" class="commercial__textarea" v-model="queryForm.content" ></textarea>
                    </div>
                    <button type="button" class="commercial-form__button" @click="submit">提交</button>
                </form>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    name: 'Coo',
    data() {
        return {
            queryForm: {
                title: '',
                mobile: '',
                content: ''
            }
        }
    },
    methods: {
        submit() {
            const loading = this.showLoading()
            this.$https.post('/api/Cooperation/addCooperation',this.queryForm).then(res => {
                this.$message({
                    message: res.message,
                    type: 'success'
                });
                this.queryForm = {
                    title: '',
                    mobile: '',
                    content: ''
                }
            }).finally(() => {
                loading.close()
            })
        }
    }
}
</script>