<template>
    <div>
        <div class="flex just-start button-nav">
                            <div :class="['button',{'active': active == 0}]" @click="active=0">
                                <a href="javascript:;">申请提现</a>
                            </div>
                            <div :class="['button',{'active': active == 1}]" @click="active=1">
                                <a href="javascript:;">提现记录</a>
                            </div>
                        </div>
                        <!-- <router-view></router-view> -->
                        <Apply v-if="active == 0"></Apply>
                        <Record v-else></Record>
    </div>
</template>
<script>
import Apply from "../cashout/apply.vue"
import Record from "../cashout/record.vue"
export default {
    components: {Apply,Record},
    data() {
        return {
            active: 0
        }
    }
}
</script>