<template>
  <div class="buy my-background">
    <section class="section1 index-container my-screen">
      <div class="screen-item screen-select">
        <p class="label"><span>求购区域</span>:</p>
        <div>
          <mySelect
            @change="upDatatile"
            :modelValue="prvName"
            :data="prvList"
            width="176"
            placeholder="---请选择省---"
          ></mySelect>
          <mySelect
            @change="upDataCity"
            :modelValue="cityName"
            :data="cityList"
            width="176"
            placeholder="---请选择市---"
          ></mySelect>
          <mySelect
            @change="upDataArea"
            :modelValue="areaName"
            :data="areaList"
            width="176"
            placeholder="---请选择区---"
          ></mySelect>
        </div>
      </div>
      <div class="screen-item screen-search">
        <button @click="list()" class="my-button">搜索</button>
      </div>
    </section>
    <section class="section2 index-container my-sort">
      <div class="left">
        <div
          class="item"
          :class="{ active: item.id == sort && sort_order == 1 }"
          @click="toggles(item.id)"
          v-for="item in sortData"
          :key="item.id"
        >
          <span>{{ item.name }}</span>
          <img src="../../assets/img/icon35.png" alt="" />
        </div>
        <!-- <div class="item active" @click="sort = 1">
          <span>按时间排序</span>
          <img src="../../assets/img/icon35.png" alt="" />
        </div>
        <div class="item" @click="sort = 2">
          <span>按热度排序</span>
          <img src="../../assets/img/icon35.png" alt="" />
        </div> -->
      </div>
      <a @click="handleUrl()" class="right">免费发布求购</a>
    </section>
    <section class="section3 index-container">
      <div class="my-list4">
        <a
          :href="'buy_detail.html?id=' + item.id"
          class="item"
          v-for="item in datailList"
          :key="item.id"
        >
          <div class="item-top">
            <div class="item-title my-hot">
              <p class="text">{{ item.title }}</p>
              <img class="icon" src="../../assets/img/icon26.png" alt="" />
              <span class="hot">火热值：{{ item.fiery }}</span>
            </div>
            <p class="item-user">发布人：{{ item.contacts }}</p>
          </div>
          <div class="item-bottom">
            <p class="item-info">
              求购区域：<span>{{ item.city }}</span
              >&emsp;&emsp;更新时间：<span>{{ item.update_time }}</span>
            </p>
            <p class="item-tel">
              <img src="../../assets/img/icon28.png" alt="" />联系电话：<b>{{
                item.mobile
              }}</b>
            </p>
          </div>
        </a>
      </div>
      <myPagination
        :total="total"
        class="pagination"
        v-model="page"
      ></myPagination>
    </section>
  </div>
</template>

<script>
import mySelect from "@/components/mySelect.vue";
import myPagination from "@/components/myPagination.vue";
import { getRegionList } from "@/util/utils";
import { Message } from "element-ui";

export default {
  name: "buy",
  components: { mySelect, myPagination },
  data() {
    return {
      sortData: [
        {
          id: 1,
          name: "按时间排序",
        },
        {
          id: 2,
          name: "按热度排序",
        },
      ],
      // sendData: {
      //   keyword: "",
      //   cat_id: 0,
      //   province: 0,
      //   price_id: 0,
      //   nature_id: 0,
      //   hottags: 0,
      //   sell_model: 0,
      //   sort: 1,
      //   page: 1,
      // },
      page: 1,
      loading: null,
      prvList: [],
      cityList: [],
      areaList: [],
      datailList: [], //详情列表数组
      serversList: [], //服务列表
      prvName: null,
      cityName: null,
      areaName: null,
      typePrevStatus: 0,
      total: 0,
      sort: 2,
      sort_order: 1,
    };
  },
  async created() {
    this.loading = this.showLoading();
    Promise.all([this.getRegionList1(), this.list()]).finally(() => {
      this.loading.close();
      this.loading = null;
      this.typePrevStatus == 1;
    });
  },
  watch: {
    // sort(news) {
    //   this.sort = news;
    //   this.list();
    // },
    page(news) {
      this.page = news;
      this.list();
    },
  },
  methods: {
    handleUrl() {
      if (this.$store.state.token == undefined) {
        Message.error("请先登录！");
        return;
      }

      window.location.href = "buy_release.html";
    },
    toggles(id) {
      this.sort = id;
      if (this.sort_order == 1) {
        this.sort_order = 2;
      } else if (this.sort_order == 2) {
        this.sort_order = 1;
      }
      this.list();
    },
    list() {
      let loading = this.showLoading();

      this.$https
        .get("/api/Wantbuy/list", {
          params: {
            province: this.prvName ? this.prvName : "",
            city: this.cityName ? this.cityName : "",
            district: this.areaName ? this.areaName : "",
            sort: this.sort,
            sort_order: this.sort_order,
            page: this.page,
            // wecando_id: this.val1.length > 0 ? this.val1.toString() : "",
          },
        })
        .then((res) => {
          this.datailList = res.data;
          this.total = res.count;
        })
        .finally(() => {
          loading.close();
        });
    },
    upDatatile(val) {
      this.prvName = val;
      this.typePrevStatus = 1;
      this.areaName = null;
      this.cityName = null;
      this.areaList = [];

      this.getRegionList();
    },
    upDataCity(val) {
      console.log(val);
      this.cityName = val;
      this.typePrevStatus = 2;
      this.areaName = null;
      this.getRegionList();
    },
    upDataArea(val) {
      this.areaName = val;
      this.typePrevStatus = 2;
      this.getRegionList();
    },
    getRegionList() {
      this.$https
        .get("/api/Region/getRegionList", {
          params: {
            parent_id: this.typePrevStatus == 1 ? this.prvName : this.cityName,
            level:
              this.typePrevStatus == 0 ? 1 : this.typePrevStatus == 1 ? 2 : 3,
            typeid: 0,
          },
        })
        .then((res) => {
          if (this.typePrevStatus == 2) {
            this.areaList = res.data;
          } else {
            this.cityList = res.data;
          }
        })
        .finally(() => {
          // this.daibanList();
        });
    },
    async getRegionList1() {
      let prvList = await getRegionList(0, 1, 0);
      this.prvList = [{ id: -1, parent_id: 0, name: "全国" }, ...prvList];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>