<template>
  <div>
    <div class="right-container">
      <div class="right-container-nav">
        <ul class="flex just-start">
          <li class="active">
            <a href="javascript:;" @click="toAddress(0)">我发布的地址</a>
          </li>
          <li>
            <a href="javascript:;" @click="toAddress(1)">我发布的求购</a>
          </li>
          <li>
            <a href="javascript:;" @click="toAddress(2)">我发布的代办</a>
          </li>
          <li>
            <a href="javascript:;" @click="toAddress(3)">我发布的点子</a>
          </li>
        </ul>
      </div>
      <div class="flex just-start button-nav" id="button-nav">
        <div
          class="button"
          :class="{ active: active == 1 }"
          @click="switchStatus(1)"
        >
          已通过
        </div>
        <div
          class="button"
          :class="{ active: active == 0 }"
          @click="switchStatus(0)"
        >
          待审核
        </div>
        <div
          class="button"
          :class="{ active: active == 2 }"
          @click="switchStatus(2)"
        >
          被退回
        </div>
      </div>
      <div>
        <ul>
          <li class="flex status-list" v-for="item in list" :key="item.id">
            <div class="status-info">
              <p>{{ item.title }}</p>
              <p>
                地址状态：<span
                  :class="[
                    item.status == 0
                      ? 'green'
                      : item.status == 1
                      ? 'red'
                      : 'blue',
                  ]"
                  >{{
                    item.status == 0
                      ? "出售中"
                      : item.status == 1
                      ? "交易中"
                      : "已售出"
                  }}</span
                ><span class="status-time"
                  >更新时间：<span>{{ item.update_time }}</span></span
                >
              </p>
            </div>
            <div class="flex">
              <button
                :class="{ 'gray': item.status == 2 }"
                @click="toFix(item.id, item)"
              >
                修改
              </button>
              <button
                :class="{ 'gray': item.status == 1 || item.status == 2 }"
                @click="getToolList(item.id, item)"
                v-if="active == 1"
              >
                道具
              </button>
              <button
                :class="{ 'gray': item.status == 1 || item.status == 2 }"
                @click="deleteItem(item)"
              >
                删除
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="mt25">
        <MyPagination :total="count" @change="change"></MyPagination>
      </div>
    </div>
    <Modal ref="prop-modal">
      <div class="prop modal">
        <div class="modal-container">
          <div class="modal-main block">
            <p class="prop-title">道具列表</p>
            <img
              src="@/assets/img/z-icon9.png"
              class="prop-close"
              @click="$refs['prop-modal'].manageModal(false)"
            />
            <div>
              <ul class="flex">
                <li v-for="item in toolList" :key="item.id">
                  <div class="flex just-start prop-intro">
                    <img :src="item.thumb" />
                    <div class="prop-intro-text">
                      <p>{{ item.title }}</p>
                      <p>{{ item.coins * 1 }}旺点币</p>
                      <p>增加{{ item.fiery }}火热值</p>
                    </div>
                  </div>
                  <button type="button" @click="buy(item.id)">立即购买</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal ref="delete-modal">
      <div class="prop modal">
        <div class="modal-container">
          <div class="modal-main block block2">
            <p class="prop-title">温馨提示</p>
            <img
                src="@/assets/img/z-icon9.png"
                class="prop-close"
                @click="$refs['delete-modal'].manageModal(false)"
              />
              <div class="delete-text">
                是否删除?
              </div>
              <div class="flex just-center align-center">
                <button type="button" @click="deleteItems(itemObjId)">确定</button>
                <button type="button" class="orange" @click="$refs['delete-modal'].manageModal(false)">取消</button>
              </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Bus from "@/util/bus";
export default {
  props:['id','pagestatus'],
  data() {
    return {
      submit: 0,
      status: 1, //0-待审核,1-已通过,2-被退回
      queryForm: {
        pageSize: 1,
        total: 10,
      },
      active: 1,
      page: 1,
      list: [],
      count: 0,
      toolList: [],
      itemObjId: null,
      toolObject: null,
    };
  },
  created() {
    if(this.id == 0) {
      this.active = this.pagestatus
      this.status = this.pagestatus
    }
    this.getList();
  },
 
    methods: {
      toAddress(val) {
        if (val != 0) {
          this.$emit("update-active", val);
        }
      },
      switchStatus(val) {
        this.active = val;
        this.page = 1;
        this.status = val;
        this.getList();
      },
      getList() {
        const loading = this.showLoading()
        const token = JSON.parse(localStorage.getItem("userInfo")).token;
        const baseUrl = "/api/Address/getlist";
        // const baseUrl = this.active == 0 ? '/api/Address/getlist' : this.active == 1 ? '/api/Wantbuy/getlist' : this.active == 3 ? '/api/Ideas/getlist' : '/api/Daiban/daibanList'
        const url = `${baseUrl}/?page=${this.page}&audit=${this.status}&token=${token}`;
        this.$https.get(url).then((res) => {
          this.list = res.data;
          this.count = Number(res.count);
        }).finally(() => {
          loading.close()
        })
      },
      toFix(id, item) {
        if (item.status == 2) {
          return;
        }
        window.location.href = "/address_release.html?id=" + id;
        // toFix(id) {
        //     window.location.href = '/address_release.html?id='+id
      },
      change(index) {
        this.page = index;
        this.getList();
      },
      deleteItem(item) {
        if (item.status == 1 || item.status == 2) {
          return;
        }
        this.itemObjId = item.id
        this.$refs['delete-modal'].manageModal(true)
      },
      deleteItems(id) {
            const loading = this.showLoading();
            const token = JSON.parse(localStorage.getItem("userInfo")).token;
            const data = {
              token,
              id,
            };
            this.$https
              .post("/api/Address/delete", data)
              .then((res) => {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.$refs['delete-modal'].manageModal(false)
                this.getList();
              })
              .finally(() => {
                loading.close();
              });
      },
      //获取道具列表
      getToolList(id, item) {
        if (item.status == 1 || item.status == 2) {
          return;
        }
        this.$https.get("/api/Currency/propslist?cat_id=1").then((res) => {
          this.toolList = res.data;
          this.toolObject = id;
          this.$refs["prop-modal"].manageModal(true);
          Bus.$emit('cash')
        });
      },
      buy(id) {
        if (!this.toolObject) {
          this.$message({
            message: "缺少使用对象",
            type: "warning",
          });
          return;
        }
        const loading = this.showLoading();
        const token = JSON.parse(localStorage.getItem("userInfo")).token;
        const data = {
          id: id,
          common_id: this.toolObject,
          token,
          typeid: 1,
        };
        this.$https
          .post("/api/Currency/props", data)
          .then((res) => {
            this.$refs["prop-modal"].manageModal(false);
            this.$message({
              message: res.message,
              type: "success",
            });
            Bus.$emit('cash')
          })
          .finally(() => {
            loading.close();
          });
      },
    },
  

  // getList() {
  //   const token = JSON.parse(localStorage.getItem("userInfo")).token;
  //   const baseUrl = "/api/Address/getlist";
  //   // const baseUrl = this.active == 0 ? '/api/Address/getlist' : this.active == 1 ? '/api/Wantbuy/getlist' : this.active == 3 ? '/api/Ideas/getlist' : '/api/Daiban/daibanList'
  //   const url = `${baseUrl}/?page=${this.page}&audit=${this.status}&token=${token}`;
  //   this.$https.get(url).then((res) => {
  //     this.list = res.data;
  //     this.count = Number(res.count);
  //   });
  // },
  // toFix(id) {
  //   window.location.href = "/address_release.html?id=" + id;
  // },
  // change(index) {
  //   this.page = index;
  //   this.getList();
  // },
  // deleteItem(id, item) {
  //   if (item.status == 1 || item.status == 2) {
  //     return;
  //   }
  //   const loading = this.showLoading();
  //   const token = JSON.parse(localStorage.getItem("userInfo")).token;
  //   const data = {
  //     token,
  //     id,
  //   };
  //   this.$https
  //     .post("/api/Address/delete", data)
  //     .then((res) => {
  //       this.$message({
  //         message: res.message,
  //         type: "success",
  //       });
  //       this.getList();
  //     })
  //     .finally(() => {
  //       loading.close();
  //     });
  // },
  // //获取道具列表
  // getToolList(id, item) {
  //   if (item.status == 1 || item.status == 2) {
  //     return;
  //   }
  //   this.$https.get("/api/Currency/propslist?cat_id=1").then((res) => {
  //     this.toolList = res.data;
  //     this.toolObject = id;
  //     this.$refs["prop-modal"].manageModal(true);
  //   });
  // },
  // buy(id) {
  //   if (!this.toolObject) {
  //     this.$message({
  //       message: "缺少使用对象",
  //       type: "warning",
  //     });
  //     return;
  //   }
  //   const loading = this.showLoading();
  //   const token = JSON.parse(localStorage.getItem("userInfo")).token;
  //   const data = {
  //     id: id,
  //     common_id: this.toolObject,
  //     token,
  //     typeid: 1,
  //   };
  //   this.$https
  //     .post("/api/Currency/props", data)
  //     .then((res) => {
  //       this.$refs["prop-modal"].manageModal(false);
  //       this.$message({
  //         message: res.message,
  //         type: "success",
  //       });
  //     })
  //     .finally(() => {
  //       loading.close();
  //     });
  // },
  // },
};
</script>