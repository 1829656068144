<template>
  <div>
    <div class="personal">
      <div class="index-container">
        <!-- <Crumbs></Crumbs> -->
        <div class="crumbs">
          <img src="@/assets/img/z-icon1.png" />
          当前所在位置：<a href="/">旺点来</a> &gt;<a href="/evaluate.html"
            >评价</a
          >
        </div>
        <div class="flex">
          <Left></Left>
          <div class="right">
            <div class="flex right-nav just-start">
              <p class="evalute-text">评价</p>
            </div>
            <div class="right-container">
              <div>
                <div
                  class="evalute-block"
                  v-for="(item, index) in list"
                  :key="item.id"
                >
                  <div class="flex just-start align-center">
                    <img :src="item.head_pic" class="evalute-head" />
                    <div class="flex align-center evalute-info">
                      <div>
                        <div class="flex align-center">
                          <p class="evalute-info-name">{{ item.name }}</p>
                          <div class="flex start-group">
                            <template v-for="(start, index) in 5">
                              <div
                                class="start"
                                :class="{ flash: index < item.stars }"
                                v-if="item.stars != '' || item.stars != 0"
                                :key="index"
                              ></div>
                            </template>
                            <!-- <div class="start flash"></div>
                                                    <div class="start flash"></div>
                                                    <div class="start flash"></div>
                                                    <div class="start"></div>
                                                    <div class="start"></div> -->
                          </div>
                        </div>
                        <p class="evalute-info-time">{{ item.add_time }}</p>
                      </div>
                      <div
                        class="flex just-start align-center"
                        v-if="item.hide == 0"
                      >
                        <p class="evalute-green">公开中</p>
                        <img
                          src="@/assets/img/z-icon17.png"
                          class="evalute-icon"
                          @click="deleteEva(item.id, index)"
                        />
                      </div>
                      <div
                        class="flex just-start align-center"
                        v-if="item.hide == 1"
                      >
                        <p class="evalute-red">已隐藏</p>
                        <button
                          class="evalute-look"
                          @click="lookeva(item.id, index)"
                        >
                          查看
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="evalute-desc">
                    {{ item.content }}
                  </div>
                </div>
              </div>
              <div class="mt25">
                <MyPagination
                  :total="count"
                  :pageSize="8"
                  @change="change"
                ></MyPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal ref="evalute-look">
      <!-- <div class="modal" id="evalute-look">
                                                <div class="model-container"> -->
      <div class="modal-main evalute-modal-main">
        <img
          src="@/assets/img/z-icon9.png"
          class="evalute-close"
          @click="$refs['evalute-look'].manageModal(false)"
        />
        <p class="evalute-modal-tip">温馨提示</p>
        <div class="evalute-modal-content">
          擦除评论需要<span>{{ hideinfo.hide_coins }}</span
          >枚旺点币<br />当前剩余<span>{{ hideinfo.user_coins * 1 }}</span
          >枚
        </div>
        <div class="flex evalute-modal-button">
          <!-- <button type="button">充值</button> -->
          <button
            v-if="hideinfo.user_coins * 1 > hideinfo.hide_coins"
            @click="hideEva"
          >
            确定
          </button>
          <a href="/recharge.html" v-else>充值</a>
          <button @click="$refs['evalute-look'].manageModal(false)">
            返回
          </button>
        </div>
        <!-- </div>
                                                </div> -->
      </div>
    </Modal>
  </div>
</template>
<script>
import MyPagination from "@/components/myPagination.vue";
import Left from "./components/left.vue";
import Bus from "@/util/bus";
import { getQueryParameter } from "@/util/utils";
export default {
  components: { MyPagination, Left },
  data() {
    return {
      page: 1,
      count: 0,
      list: [],
      hideinfo: {},
      evaObject: null,
      currentIndex: null,
    };
  },
  created() {
    const val = getQueryParameter("val");
    if (val) {
      this.totalActive = Number(val);
      const type = getQueryParameter("type");
      if (type) {
        this.active = Number(type);
      }
    }
    Bus.$on("lefttopage", (val, type) => {
      window.location.href = "/personal.html?type=" + type + "&val=" + val;
    });
    Bus.$on("recharge", () => {
      window.location.href = "/recharge.html";
    });
    Bus.$on("balancerecharge", () => {
      window.location.href = "/balance_recharge.html";
    });
    this.getList();
  },
  methods: {
    getList() {
      const loading = this.showLoading();
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      const data = {
        token,
        typeid: 2,
        page: this.page,
      };
      this.$https
        .post("/api/Users/evaluatelist", data)
        .then((res) => {
          this.list = res.data;
          this.count = res.count;
        })
        .finally(() => {
          loading.close();
        });
    },
    change(index) {
      this.page = index;
      this.getList();
    },
    deleteEva(id, index) {
      const loading = this.showLoading();
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      const data = {
        isbuy: 0,
        token,
        id,
      };
      this.evaObject = {
        id,
        index,
      };
      this.currentIndex = index;
      this.$https
        .post("/api/Users/hide_evaluate", data)
        .then((res) => {
          this.hideinfo = res.data;

          this.$refs["evalute-look"].manageModal(true);
          Bus.$emit('cash')
        })
        .finally(() => {
          loading.close();
        });
    },
    lookeva(id, index) {
      const loading = this.showLoading();
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      const data = {
        id,
        token,
      };
      this.$https
        .post("/api/Users/evaluate", data)
        .then((res) => {
          // this.list[index] = {...res.data}
          this.$set(
            this.list,
            index,
            Object.assign(this.list[index], { ...res.data })
          );
          console.log(this.list);
        })
        .finally(() => {
          loading.close();
        });
    },
    hideEva() {
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      const data = {
        token,
        id: this.evaObject.id,
        isbuy: 1,
      };
      this.$https.post("/api/Users/hide_evaluate", data).then(() => {
        Bus.$emit("cash");
        this.getList();
        this.$refs["evalute-look"].manageModal(false);
        // this.list[this.currentIndex] = Object.assign(
        //   this.list[this.currentIndex],
        //   {
        //     add_time: "**********",
        //     content: "******************",
        //     head_pic: window.baseUrl + "/static/img/hide_head.png",
        //     hide: 1,
        //     stars: "",
        //   }
        // );
      });
    },
  },
};
</script>