<template>
    <div>
        <div>
                            <div class="flex just-start">
                                <div class="apply-money">
                                    账户余额：<span class="f14">¥</span><span>{{ userinfo.balance.includes('万') ? userinfo.balance : userinfo.balance * 1 }}</span>
                                </div>
                                <div class="apply-money">
                                    保证金：<span class="f14">¥</span><span>{{ String(userinfo.earnest_money).includes('万') ? userinfo.earnest_money : userinfo.earnest_money * 1 }}</span>
                                </div>
                            </div>
                            <div class="apply-way">
                                <label for="zfb" class="flex just-start align-center" @click.stop="handleChange(1)">
                                    <input type="radio" name="zf" id="zfb" value="1" class="zfradio" v-model="check" />
                                    <div class="flex just-start align-center apply-way-list">
                                        <p class="apply-way-label">支付宝：</p>
                                        <button class="flex just-start align-center apply-way-select" @click="addZfb(1)" v-if="!isEditZfb">
                                            <img src="@/assets/img/z-icon16.png">
                                            <p>添加支付宝</p>
                                        </button>
                                        <div class="flex zfbaccount" v-if="isEditZfb">
                                            <div>
                                                <p>姓名：<span id="zfname-show">{{ zfbInfo.name }}</span></p>
                                                <p>账号：<span id="zfaccount-show">{{ zfbInfo.card_num }}</span></p>
                                            </div>
                                            <p class="zfbaccount-edit" @click="addZfb(2)">编辑</p>
                                        </div>
                                        <Modal ref="zf-modal">
                                            <div class="modal zfmodal" id="zf-modal">
                                                <div class="modal-container">
                                                    <div class="modal-main zf-main">
                                                        <img src="@/assets/img/z-icon9.png" class="zf-main-close" @click="$refs['zf-modal'].manageModal(false)">
                                                        <p class="zf-main-title">支付宝账户</p>
                                                        <form action="">
                                                            <div class="flex just-start align-center">
                                                                <label for="">姓名：</label>
                                                                <div class="relative">
                                                                    <input type="text" placeholder="请输入支付宝姓名" id="zf-name" v-model="zfbInfoCopy.name">
                                                                    <!-- <p class="red animated shake">姓名不能为空</p> -->
                                                                </div>
                                                            </div>
                                                            <div class="flex just-start align-center">
                                                                <label for="">账号：</label>
                                                                <div class="relative">
                                                                    <input type="text" placeholder="请输入支付宝账号" id="zf-account" v-model="zfbInfoCopy.card_num">
                                                                    <!-- <p class="red animated shake">账号不能为空</p> -->
                                                                </div>
                                                            </div>
                                                            <button type="button" class="zf-main-button" id="confirm-zfb"  @click="zfbConfirm">确定</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                        
                                    </div>
                                </label>
                                <label for="yhk" class="flex just-start align-center" data-type="2" @click.stop="handleChange(2)">
                                    <input type="radio" name="zf" id="yhk" value="2" class="zfradio" v-model="check"/>
                                    <div class="flex just-start align-center apply-way-list">
                                        <p class="apply-way-label">银行卡：</p>
                                        <button class="flex just-start align-center apply-way-select"  @click="addYhk(1)" v-if="!isEditYhk">
                                            <img src="@/assets/img/z-icon16.png">
                                            <p>添加银行卡</p>
                                        </button>
                                        <div class="flex zfbaccount" v-if="isEditYhk">
                                            <div>
                                                <p>姓名：<span id="yhname-show">{{ yhkInfo.name }}</span></p>
                                                <p>账号：<span id="yhaccount-show">{{ yhkInfo.card_num }}</span></p>
                                            </div>
                                            <p class="zfbaccount-edit" @click="addYhk(2)">编辑</p>
                                        </div>
                                        <Modal ref="yhk-modal">
                                            <div class="modal zfmodal" id="yhk-modal">
                                            <div class="modal-container">
                                                <div class="modal-main zf-main">
                                                    <img src="@/assets/img/z-icon9.png" class="zf-main-close" @click="$refs['yhk-modal'].manageModal(false)">
                                                    <p class="zf-main-title">银行卡账户</p>
                                                    <form action="">
                                                        <div class="flex just-start align-center">
                                                            <label for="">姓名：</label>
                                                            <input type="text" placeholder="请输入持卡人姓名" id="yh-name" v-model="yhkInfoCopy.name">
                                                        </div>
                                                        <div class="flex just-start align-center">
                                                            <label for="">账号：</label>
                                                            <input type="text" placeholder="请输入银行卡号" id="yh-account" v-model="yhkInfoCopy.card_num">
                                                        </div>
                                                        <button type="button" class="zf-main-button"  id="confirm-yhk"  @click="yhkConfirm">确定</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        </Modal>
                                        
                                    </div>
                                </label>
                            </div>
                            <div class="flex just-start apply-cash">
                                <p class="apply-cash-label">提现金额：</p>
                                <div>
                                    <div class="flex align-center">
                                        <input type="text" class="apply-cash-input" placeholder="¥ 请输入提现金额" v-model="price">
                                        <p class="allapply-text" @click="allapply">全部提现</p>
                                    </div>
                                    <p class="apply-cash-tip">本次可提现金额<span>{{ userinfo.balance.includes('万') ? userinfo.balance : userinfo.balance * 1 }}</span>元</p>
                                    <button class="apply-cash-button" @click="cash">确认提现</button>
                                </div>
                            </div>
                        </div>
    </div>
</template>
<script>
    import Bus from "@/util/bus";
    export default {
        data() {
            return {
                isEditZfb: false, //用来显示账号，还是“添加支付宝”
                isEditYhk: false, //用来显示账号，还是“添加银行卡”
                isEdit: false, //用来判断是否为编辑 1为添加 2为编辑
                check: 1,
                zfbInfo: {
                    id: '',
                    name: '',
                    card_num: ''
                },
                zfbInfoCopy: {
                    id: '',
                    name: '',
                    card_num: ''
                },
                yhkInfo: {
                    id: '',
                    name: '',
                    card_num: ''
                },
                yhkInfoCopy: {
                    id: '',
                    name: '',
                    card_num: ''
                },
                price: '',
                userinfo: null,
                zfbPlaceholder: '请输入支付宝姓名',
                yhkPlaceholder: '请输入支付宝账号'
            }
        },
        created() {
            const userinfo = JSON.parse(localStorage.getItem('userallinfo'))
            if(userinfo) {
                this.userinfo = userinfo
            }
            Bus.$on('updateuserinfo',(userinfo) => {
                console.log(userinfo)
                this.userinfo = userinfo
            })
            this.getPayAccount(1).then(res => {
                //res.data为空时返回[]
                if(Object.prototype.toString.call(res.data) != '[object Array]') {
                    this.zfbInfo = {
                    id: res.data.id,
                    name: res.data.name,
                    card_num: res.data.card_num
                    }
                    if(this.zfbInfo.name != '' && this.zfbInfo.card_num != '') {
                        this.isEditZfb = true
                    }
                }
                
            })
            this.getPayAccount(2).then(res => {
                if(Object.prototype.toString.call(res.data) != '[object Array]') {
                    this.yhkInfo = {
                        id: res.data.id,
                        name: res.data.name,
                        card_num: res.data.card_num
                    }
                    if(this.yhkInfo.name != '' && this.yhkInfo.card_num != '') {
                        this.isEditYhk = true
                    }
                    }
            })
        },
        methods: {
            closeZfbConfirm() {
                this.$refs['zf-modal'].manageModal(false)
            },
            zfbConfirm() {
                if(this.zfbInfoCopy.name == '' || this.zfbInfoCopy.card_num == '') {
                    this.$message({
                        message: '姓名或账号不能为空',
                        customClass: 'applyclass',
                        type: 'warning'
                    });
                    return
                }else {
                    const loading = this.showLoading()
                    const token = JSON.parse(localStorage.getItem('userInfo')).token
                    const data = Object.assign(this.zfbInfoCopy,{type:this.check},{token})
                    const url = this.isEdit ? '/api/UsersCenter/editUserBankCard' : '/api/UsersCenter/addUserBankCard'
                    this.$https.post(url,data).then(res => {
                        this.$message({
                            message: res.message,
                            type: 'success'
                        });
                        this.zfbInfo = {...this.zfbInfoCopy,id: res.data.id}
                    }).finally(() => {
                        loading.close()
                    })
                    this.isEditZfb = true
                    this.closeZfbConfirm()
                }
            },
            closeYhkConfirm() {
                this.$refs['yhk-modal'].manageModal(false)
            },
            yhkConfirm() {
                if(this.yhkInfoCopy.name == '' || this.yhkInfoCopy.card_num == '') {
                    console.log(123)
                    this.$message({
                        message: '姓名或账号不能为空',
                        customClass: 'applyclass',
                        type: 'warning'
                    });
                    return
                }else {
                    const loading = this.showLoading()
                    const token = JSON.parse(localStorage.getItem('userInfo')).token
                    const data = Object.assign(this.yhkInfoCopy,{type:this.check},{token})
                    const url = this.isEdit ? '/api/UsersCenter/editUserBankCard' : '/api/UsersCenter/addUserBankCard'
                    this.$https.post(url,data).then(res => {
                        this.$message({
                            message: res.message,
                            type: 'success'
                        });
                        this.yhkInfo = {...this.yhkInfoCopy,id:res.data.id}
                    }).finally(() => {
                        loading.close()
                    })
                    this.isEditYhk = true
                    this.closeYhkConfirm()
                }
            },
            handleChange(val) {
                this.check = val
            },
            cash() {
                if(this.check == 1) {
                    if(this.zfbInfo.name == '' || this.zfbInfo.card_num == '') {
                        this.$message({
                            message: '请填写完整支付宝信息',
                            type: 'error'
                        });
                        return
                    }
                }else if(this.check == 2) {
                    if(this.yhkInfo.name == '' || this.yhkInfo.card_num == '') {
                        this.$message({
                            message: '请填写完整银行卡信息',
                            type: 'error'
                        });
                        return
                    }
                }
                const loading = this.showLoading()
                const token = JSON.parse(localStorage.getItem('userInfo')).token
                const data = Object.assign({price:this.price},{token,type: this.check,common_id: this.check == 1 ? this.zfbInfo.id : this.yhkInfo.id})
                this.$https.post('/api/UsersCenter/applyCash',data).then(res => {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    // this.userinfo.balance = this.userinfo.balance - this.price
                    Bus.$emit('cash',this.price)
                }).finally(() => {
                    loading.close()
                })
            },
            allapply() {
                const token = JSON.parse(localStorage.getItem('userInfo')).token
                const data = {
                    token
                }
                this.$https.post('/api/Users/getUserInfo',data).then(res => {
                    this.price = res.data.balance_original * 1
                })
            },
            getPayAccount(type) {
                const token = JSON.parse(localStorage.getItem('userInfo')).token
                const data = {
                    token,
                    type
                }
                return this.$https.post('/api/UsersCenter/getUserBankCard',data)
            },
            addYhk(num) {
                if(num == 2) {
                    this.isEdit = true
                }
                this.$refs['yhk-modal'].manageModal(true)
                this.yhkInfoCopy = {...this.yhkInfo}
            },
            addZfb(num) {
                if(num == 2) {
                    this.isEdit = true
                }
                this.$refs['zf-modal'].manageModal(true)
                this.zfbInfoCopy = {...this.zfbInfo}
            }
        },
    }
</script>