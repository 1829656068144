import Vue from "vue"

const on = (function() {
    if (document.addEventListener) {
      return function(element, event, handler) {
        if (element && event && handler) {
          element.addEventListener(event, handler, false)
        }
      }
    } else if (document.attachEvent) {
      return function(element, event, handler) {
        if (element && event && handler) {
          element.attachEvent('on' + event, handler)
        }
      }
    } else {
      return function(element, event, handler) {
        if (element && event && handler) {
          element['on' + event] = handler
        }
      }
    }
})()

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args)
        }, delay)
    }
}

const throttle  = (fn, delay) => {
    let timer = null;
    return function () {
        const context = this;
        const args = arguments;
        if (timer == null) {
            fn.apply(context, args)
            timer = setTimeout(() => {
                clearTimeout(timer)
                timer = null
            }, delay)
        }
    }
}

Vue.directive('throttle', {
    inserted: function (el, binding) {
        let delayTime = el.getAttribute("delay-time") || 1000
        on(el, 'click', throttle(function () {
            binding.value()
        }, delayTime))
    }
})

export default Vue.directive('debounce', {
    inserted: function (el, binding) {
        let delayTime = el.getAttribute("delay-time") || 500
        on(el, 'click', debounce(function () {
            binding.value()
        }, delayTime))
    }
})