<template>
    <div class="modal" v-if="modalStatus">
        <div class="modal-container">
            <div class="modal-main">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Modal",
    data () {
        return {
            modalStatus: false
        }
    },
    methods: {
        manageModal (type) {
            this.modalStatus = type
        },
        close () {
            this.modalStatus = false
        }
    }
}
</script>

<style lang="scss" scoped>

</style>