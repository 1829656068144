<template>
    <div>
        <div>
                            <ul>
                                <li class="right-order" v-for="item in list" :key="item.id">
                                    <div class="flex right-order-tixian">
                                        <p>{{item.type_name}}{{item.user_mobile}}/{{ item.user_name }}</p>
                                    </div>
                                    <div class="right-order-tixian-bottom">
                                        提现金额：<span>¥{{ item.price.includes('万') ? item.price : item.price * 1 }}</span>申请时间：<span>{{ item.add_time }}</span>完成时间：<span>{{ item.done_time }}</span>
                                    </div>
                                </li>
                                <!-- <li class="right-order">
                                    <div class="flex right-order-tixian">
                                        <p>支付宝13458473656897990/林先生</p>
                                    </div>
                                    <div class="right-order-tixian-bottom">
                                        提现金额：<span>¥500000</span>申请时间：<span>2023-10-30  15:45</span>完成时间：<span>2023-10-30  15:45</span>
                                    </div>
                                </li>
                                <li class="right-order">
                                    <div class="flex right-order-tixian">
                                        <p>支付宝13458473656897990/林先生</p>
                                    </div>
                                    <div class="right-order-tixian-bottom">
                                        提现金额：<span>¥500000</span>申请时间：<span>2023-10-30  15:45</span>完成时间：<span>2023-10-30  15:45</span>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                        <div class="mt25">
                            <MyPaginationVue :total="count" @change="change"></MyPaginationVue>
                        </div>
    </div>
</template>
<script>
import MyPaginationVue from '@/components/myPagination.vue'
export default {
    components: {MyPaginationVue},
    data() {
        return {
            list: [],
            page: 1,
            count: 0
        }
    },
    created() {
        this.getCashList()
    },
    methods: {
        formateTime(val) {
            const date = new Date(val * 1000)
            const year = date.getFullYear()
            const month = date.getMonth() + 1
            const day = date.getDate()
            const h = date.getHours()
            const d = date.getMinutes()
            return `${year}-${month}-${day} ${h}:${d}`
        },
        getCashList() {
            const loading = this.showLoading()
                const token = JSON.parse(localStorage.getItem('userInfo')).token
                this.$https.post('/api/UsersCenter/getUserTakeMoneyList',{token,page:this.page}).then(res => {
                    this.list = res.data
                    this.count = res.count
                }).finally(() => {
                    loading.close()
                })
            },
        change(index) {
            this.page = index
            this.getCashList()
        }
    }
}
</script>