<template>
    <div class="right-item">
        <p class="right-title">旺点问答</p>
        <div class="my-list2 toggle">
            <div
                class="item"
                :class="{active: item.id == id}"
                @click="toggle(item.id)"
                v-for="item in list"
                :key="item.id">
                <div class="item-top">
                    <img class="item-img" src="@/assets/img/icon42.png" alt="">
                    <p class="item-title">{{ item.title }}</p>
                    <img class="item-icon" src="@/assets/img/z-icon4.png" alt="">
                </div>
                <div class="item-content">{{ item.content }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'answers',
    data () {
        return {
            id: 0,
            list: []
        }
    },
    mounted () {
        this.getList()
    },
    methods: {
        toggle (id) {
            if (this.id !== id) {
                this.id = id
            } else {
                this.id = 0
            }
        },
        getList () {
            this.$https.post("/api/Currency/questionslist").then(({ data }) => {
                this.list = data
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>