<template>
  <div>
    <div class="syb">
      <div class="index-container">
        <div class="flex just-start align-center syb-link">
          <p>点子类型：</p>
          <ul class="flex just-start align-center">
            <li
              @click="
                active = index;
                form.cat_id = item.id;
              "
              v-for="(item, index) in dztype"
              :key="item.id"
              :class="{ active: index == active }"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div class="flex just-start align-center syb-link">
          <p>点子类型：</p>
          <ul class="flex just-start align-center">
            <li
              @click="
                active1 = index;
                form.industry_id = item.id;
              "
              v-for="(item, index) in dztype1"
              :key="item.id"
              :class="{ active: index == active1 }"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div class="flex just-start align-center syb-link">
          <p>点子状态：</p>
          <ul class="flex just-start align-center">
            <li
              @click="
                active2 = index;
                form.type = item.id;
              "
              :class="{ active: index == active2 }"
              v-for="(item, index) in dzstatus"
              :key="item.id"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div class="flex just-start align-center syb-sort">
          <div class="left flex">
            <div
              style="align-items: center"
              class="item flex kk0124"
              :class="{
                active: item.id == form.sort && form.sort_type == 1,
              }"
              @click="toggles(item.id)"
              v-for="item in sortData"
              :key="item.id"
            >
              <span style="margin-right: 20px">{{ item.name }}</span>
              <img src="../../assets/img/icon35.png" alt="" />
            </div>
          </div>

          <!-- <div @click="form.sort = 2" class="flex align-center">
            <p>按火热值排序</p>
            <img src="@/assets/img/z-icon29.png" />
          </div> -->
        </div>
        <div class="syb-con">
          <div
            @click="handleDetail(item)"
            v-for="item in dzlist"
            :key="item.id"
            :href="'/syb_detail.html?id=' + item.id"
            class="flex just-start align-center syb-list"
          >
            <div class="syb-list-left">
              <p class="flex just-start align-center syb-list-title">
                <span>{{ item.cat_name }}</span
                >{{ item.title }}
              </p>
              <p class="syb-list-type">
                行业类别：<span>{{ item.industry_name }}</span
                >发布时间：<span>{{ item.update_time }}</span>
              </p>
            </div>
            <div class="flex just-start align-center syb-list-status">
              <div class="p014">
                当前状态：<span class="red" :class="{ gong: item.type == 2 }">{{
                  item.type_name
                }}</span>
                <p v-if="item.type_name != '我发布的' && item.type != 2">
                  <img
                    v-if="item.type != 3"
                    src="@/assets/img/z-icon30.png"
                    alt=""
                    srcset=""
                  />
                  <img
                    style="width: 20px"
                    v-else
                    src="@/assets/img/z-icon31.png"
                    alt=""
                    srcset=""
                  />
                </p>

                <p
                  class="close_open"
                  v-if="item.type_name != '我发布的' && item.type != 2"
                >
                  <span class="span1" :class="{ gray: item.type == 3 }">{{
                    item.pay_name
                  }}</span>
                </p>
              </div>
            </div>
            <div class="flex just-start align-center syb-list-num">
              <img src="@/assets/img/icon26.png" />
              <p>火热值：{{ item.fiery }}</p>
            </div>
          </div>
        </div>
        <Modal ref="zf-modal">
          <div class="modal zfmodal" id="zf-modal">
            <div class="modal-container">
              <div class="modal-main zf-main" style="height: auto">
                <img
                  src="@/assets/img/z-icon9.png"
                  class="zf-main-close"
                  @click="$refs['zf-modal'].manageModal(false)"
                />
                <p class="zf-main-title">点子解锁</p>
                <form action="">
                  <div class="flex just-start align-center">
                    <div class="relative" style="margin: 20px 0 35px 0">
                      点子尚未解锁，是否解锁，解锁后不支持撤销！
                    </div>
                  </div>

                  <div class="flex">
                    <button
                      @click="
                        $refs['zf-modal'].manageModal(false);
                        jsId = '';
                      "
                      type="button"
                      class="zf-main-button"
                      style="margin-bottom: 20px"
                      id="confirm-zfb"
                    >
                      取消
                    </button>
                    <button
                      @click="ideas_unlock()"
                      type="button"
                      class="zf-main-button"
                      style="margin-bottom: 20px"
                      id="confirm-zfb"
                    >
                      确定
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
        <myPagination :total="total" v-model="form.page"></myPagination>
      </div>
    </div>
  </div>
</template>
<script>
import myPagination from "@/components/myPagination.vue";
import { Message } from "element-ui";

export default {
  components: { myPagination },
  data() {
    return {
      sortData: [
        {
          id: 1,
          name: "按时间排序",
        },
        {
          id: 2,
          name: "按热度排序",
        },
      ],
      page: 1,
      total: 0,
      loading: null,
      dztype: [],
      dztype1: [],
      active: 0,
      active1: 0,
      active2: 0,
      typeid: 7,
      dzlist: [],
      jsId: "",
      token: "",
      zfbInfo: {
        title: "1",
        card_num: "001",
      },
      dzstatus: [
        {
          id: 0,
          title: "不限",
        },
        {
          id: 1,
          title: "锁定",
        },
        {
          id: 2,
          title: "公开",
        },
      ],
      form: {
        siteid: "",
        cat_id: "",
        industry_id: "",
        type: 0,
        sort: 2,
        sort_type: 1,
        page: 1,
      },
    };
  },
  created() {
    this.loading = this.showLoading();
    Promise.all([
      this.categorylist(),
      this.categorylist1(),
      this.ideasList(),
    ]).finally(() => {
      this.loading.close();
      this.loading = null;
      this.typePrevStatus == 1;
    });
  },
  watch: {
    "form.cat_id"(news) {
      this.form.cat_id = news;
      this.ideasList();
    },
    "form.industry_id"(news) {
      this.form.industry_id = news;
      this.ideasList();
    },
    "form.type"(news) {
      this.form.type = news;
      this.ideasList();
    },

    "form.page"(news) {
      this.form.page = news;
      this.ideasList();
    },

    // "form.sort"(news) {
    //   this.form.type = news;
    // },
  },
  methods: {
    toggles(id) {
      this.form.sort = id;
      if (this.form.sort_type == 1) {
        this.form.sort_type = 2;
      } else if (this.form.sort_type == 2) {
        this.form.sort_type = 1;
      }
      this.ideasList();
    },
    ideas_unlock() {
      let loading = this.showLoading();
      this.token = JSON.parse(localStorage.getItem("userInfo"))?.token;

      this.$https
        .get("/api/Ideas/ideas_unlock", {
          params: {
            id: this.jsId,
            token: this.token,
          },
        })
        .then((res) => {
          Message.success(res.message);
          this.$refs["zf-modal"].manageModal(false);

          this.ideasList();
          console.log(res);
        })
        .finally(() => {
          loading.close();
        });
    },

    handleDetail(item) {
      if (this.$store.state.token == undefined && item.type == 1) {
        Message.error("请先登录");
        return;
      }

      if (item.type == 1) {
        this.jsId = item.id;
        this.$refs["zf-modal"].manageModal(true);
        return;
      }

      window.location.href = "/syb_detail.html?id=" + item.id;
    },
    categorylist1() {
      let loading = this.showLoading();

      this.$https
        .get("/api/Currency/categorylist", {
          params: {
            typeid: 8,
          },
        })
        .then((res) => {
          this.dztype1 = [{ id: 0, title: "不限" }, ...res.data];
        })
        .finally(() => {
          loading.close();
        });
    },
    categorylist() {
      let loading = this.showLoading();

      this.$https
        .get("/api/Currency/categorylist", {
          params: {
            typeid: this.typeid,
          },
        })
        .then((res) => {
          this.dztype = [{ id: 0, title: "不限" }, ...res.data];
        })
        .finally(() => {
          loading.close();
        });
    },
    ideasList() {
      let loading = this.showLoading();
      this.token = JSON.parse(localStorage.getItem("userInfo"))?.token;
      this.form.siteid = JSON.parse(
        localStorage.getItem("userallinfo")
      )?.siteid;

      this.form.token = this.token;
      this.$https
        .get("/api/Ideas/list", {
          params: {
            ...this.form,
          },
        })
        .then((res) => {
          this.total = res.count;
          this.dzlist = res.data;
        })
        .finally(() => {
          loading.close();
          // @click="$refs['zf-modal'].manageModal(true)"
        });
    },
  },
};
</script>

<style  scoped>
.syb .syb-con .syb-list .syb-list-status {
  width: 330px;
}
.syb-list {
  cursor: pointer;
}
/* .my-sort .left img  */
.syb .syb-sort img {
  vertical-align: middle;

  width: 22px;
  transform: rotate(180deg);
}
.syb .syb-sort .item:hover {
  cursor: pointer;
  color: #ff722c;
}
.syb .syb-sort .active img {
  transform: rotate(0deg);
}
</style>