<template>
  <main class="release my-background">
    <section class="section1 index-container">
      <div class="crumbs">
        <img src="../../../assets/img/z-icon1.png" />
        当前所在位置：<a href="index.html">旺点来</a> >
        <a href="Buy.html">求购</a> >
        <a class="active">发布求购</a>
      </div>
    </section>
    <section class="section3 index-container my-form">
      <p class="top-title">
        发布求购信息&emsp;&emsp;<span>免费发布您的需求，大家帮忙一起找！</span>
      </p>
      <div class="item">
        <div class="item-title">
          <span>基础信息</span>
        </div>
        <div class="form">
          <div class="form-item">
            <div class="form-left tip">标题：</div>
            <div class="form-right">
              <el-input
                v-model="form.title"
                class="w-340"
                placeholder="请填写求购标题"
              ></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">求购地区：</div>
            <div class="form-right">
              <mySelect
                @change="upDatatile"
                :modelValue="prvName"
                :data="prvList"
                width="216"
              ></mySelect>
              <mySelect
                @change="upDataCity"
                :modelValue="cityName"
                :data="cityList"
                width="216"
              ></mySelect>
              <mySelect
                @change="upDataArea"
                :modelValue="areaName"
                :data="areaList"
                width="216"
              ></mySelect>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">求购详情：</div>
            <div class="form-right">
              <el-input
                v-model="form.content"
                type="textarea"
                rows="6"
                resize="none"
                placeholder="请输入求购描述，字数限制100以内"
                maxlength="100"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="item-title">
          <span>联系方式</span>
        </div>
        <div class="form">
          <div class="form-item">
            <div class="form-left tip">联系电话：</div>
            <div class="form-right">
              <el-input
                v-model="form.mobile"
                placeholder="请输入手机号"
              ></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">联系人：</div>
            <div class="form-right">
              <el-input
                v-model="form.contacts"
                placeholder="请输入联系人"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="item submit">
        <div class="my-radio">
          <input type="checkbox" v-model="xy" id="item1" name="type" />
          <div class="checked"></div>
          <label for="item1"
            >我已阅读并同意<a target="_blank" href="about.html?id=1" class="xy"
              >《旺点来转让协议》</a
            ></label
          >
        </div>
        <button @click="handleFb()" class="my-button">立即发布</button>
      </div>
    </section>
    <Modal ref="modal">
      <div class="release-modal">
        <img
          @click="$refs.modal.close(), getRegionList1()"
          class="close-icon"
          src="../../../assets/img/z-icon9.png"
          alt=""
        />
        <img class="icon" src="../../../assets/img/icon46.png" alt="" />
        <p>发布成功，请等待审核</p>
        <p>您还可以</p>
        <div class="btns">
          <button
            class="my-button"
            @click="$refs.modal.close(), getRegionList1()"
          >
            继续发布
          </button>
          <button class="my-button">
            <a :href="'personal.html?id=' + 1 + '&status=' + 0 + '&type=' + 1"
              >查看审核</a
            >
          </button>
          <button class="my-button">
            <a href="Buy.html">返回列表</a>
          </button>
        </div>
      </div>
    </Modal>
  </main>
</template>

<script>
import { getRegionList, getUrlData } from "@/util/utils";
import { Message } from "element-ui";

import mySelect from "@/components/mySelect.vue";
import Modal from "@/components/Modal";
export default {
  name: "release",
  components: { mySelect, Modal },
  data() {
    return {
      value1: null,
      value2: null,
      text1: null,
      form: {
        siteid: 0,
        token: "",
        title: "",
        province: "",
        city: "",
        district: "",
        id: "",
        content: "",
        mobile: "",
        contacts: "",
      },
      prvList: [],
      cityList: [],
      areaList: [],
      loading: null,
      prvName: null,
      cityName: null,
      areaName: null,
      typePrevStatus: 0,
      xy: false,
      mobileReg:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
    };
  },
  mounted() {
    this.loading = this.showLoading();

    let query = getUrlData();

    if (query.id) {
      this.id = parseInt(query.id);
      this.hh();
    } else {
      Promise.all([this.getRegionList1()]).finally(() => {
        this.loading.close();
        this.loading = null;
        this.typePrevStatus == 1;
      });
    }
  },
  methods: {
    hh() {
      let loading = this.showLoading();
      this.token = JSON.parse(localStorage.getItem("userInfo")).token;
      this.$https
        .get("/api/Wantbuy/getdetail", {
          params: {
            token: this.token,
            id: this.id,
          },
        })
        .then((res) => {
          console.log(res);
          let {
            city,
            contacts,
            content,
            district,
            id,
            mobile,
            province,
            title,
          } = res.data;
          this.form.title = title;
          this.form.id = id;
          this.form.province = province;
          this.form.mobile = mobile;
          this.form.content = content;
          this.form.district = district;
          this.form.contacts = contacts;
          this.form.city = city;
          this.getHadeui(province, city, district);
        })
        .finally(() => {
          loading.close();
        });
    },
    async getHadeui(province, city, district) {
      this.prvList = await getRegionList(0, 1, 0);
      this.cityList = await getRegionList(province, 2, 0);
      this.areaList = await getRegionList(city, 3, 0);

      this.prvName = province;
      this.cityName = city;
      this.areaName = district;
    },
    // /api/Wantbuy/getdetail
    handleFb() {
      const reg = this.mobileReg;

      if (this.form.title == "") {
        Message.error("请输入标题");
        return;
      }

      if (this.form.province === "") {
        Message.error("请选择省份");
        return;
      }
      if (this.form.city == "" && this.form.province > 0) {
        Message.error("请选择城市");
        return;
      }

      if (this.form.content == "") {
        Message.error("请输入求购详情");
        return;
      }
      if (this.form.mobile == "") {
        Message.error("请输入手机号");
        return;
      } else if (!reg.test(this.form.mobile)) {
        Message.error("请输入正确的手机号");
        return;
      }
      if (this.form.contacts == "") {
        Message.error("请输入联系人");
        return;
      }
      if (this.xy == false) {
        Message.error("请勾选协议");
        return;
      }

      let loading = this.showLoading();

      this.form.token = JSON.parse(localStorage.getItem("userInfo")).token;
      this.form.siteid = JSON.parse(
        localStorage.getItem("userallinfo")
      )?.siteid;
      if (this.form.province == 0) {
        this.form.province = -1;
      }
      this.$https
        .get("/api/Wantbuy/wantbuy", {
          params: {
            ...this.form,
          },
        })
        .then((res) => {
          // console.log(res);
          Message.success(res.message);
          this.$refs.modal.manageModal(true);

          Object.keys(this.form).forEach((item) => {
            this.form[item] = "";
          });

          this.prvList = [];
          this.cityList = [];
          this.areaList = [];
          this.prvName = null;
          this.cityName = null;
          this.areaName = null;
          this.xy = false;

          // setTimeout(() => {
          //   window.location.href = "/agent.html";
          // }, 2000);

          // this.serversList = res.data;
        })
        .finally(() => {
          loading.close();
        });
    },
    upDatatile(val) {
      this.prvName = val;
      this.typePrevStatus = 1;
      this.areaName = null;
      this.cityName = null;
      this.areaList = [];

      this.getRegionList();
      this.form.province = val;
      this.form.city = "";
      this.form.district = "";
    },
    upDataCity(val) {
      console.log(val);
      this.cityName = val;
      this.typePrevStatus = 2;
      this.areaName = null;
      this.form.city = val;

      this.getRegionList();
    },
    upDataArea(val) {
      this.areaName = val;
      this.form.district = val;

      this.typePrevStatus = 2;
      this.getRegionList();
    },
    async getRegionList1() {
      let prvList = await getRegionList(0, 1, 0);
      this.prvList = [{ id: 0, parent_id: 0, name: "全国" }, ...prvList];
    },
    getRegionList() {
      // let loading = this.showLoading();

      this.$https
        .get("/api/Region/getRegionList", {
          params: {
            parent_id: this.typePrevStatus == 1 ? this.prvName : this.cityName,
            level:
              this.typePrevStatus == 0 ? 1 : this.typePrevStatus == 1 ? 2 : 3,
            typeid: 0,
          },
        })
        .then((res) => {
          if (this.typePrevStatus == 2) {
            this.areaList = res.data;
          } else {
            this.cityList = res.data;
          }
        })
        .finally(() => {
          // loading.close();
          // loading = null;
          // this.daibanList();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-textarea__inner) {
  font-family: "PingFang", "PingFang SC", "syht", "Microsoft YaHei", "黑体",
    "宋体", sans-serif;
}
.el-textarea {
  font-family: "PingFang", "PingFang SC", "syht", "Microsoft YaHei", "黑体",
    "宋体", sans-serif;
}
:deep(.el-input__inner) {
  font-family: "PingFang", "PingFang SC", "syht", "Microsoft YaHei", "黑体",
    "宋体", sans-serif;
}
.release-modal {
  width: 658px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 84px 0 74px;
  text-align: center;
  .icon {
    margin-bottom: 10px;
  }
  p {
    font-size: 22px;
    line-height: 1.3;
  }
  .btns {
    margin-top: 32px;
  }
  .my-button {
    width: 130px;
    height: 42px;
    border-radius: 21px;
    & + .my-button {
      margin-left: 10px;
    }
  }
}
</style>