<template>
    <div class="user-card line1">
        <img class="user-tx" :src="$store.state.token ? userInfo.picurl : empy" alt="" >
        <p class="user-title" v-if="!$store.state.token">Hi 你好</p>
        <p class="user-title" v-else>{{ $store.state.userInfo.mobile }}</p>
        <a href="about.html?id=7" class="user-text">如何买地址？如何卖地址？</a>
        <template v-if="!$store.state.token">
            <button class="my-button user-btn1" @click="login(1)">登录</button>
            <button class="my-button plain user-btn2" @click="login(4)">注册</button>
        </template>
        <button v-else class="my-button user-btn1"><a href="personal.html">个人中心</a></button>
    </div>
</template>

<script>
import Bus from '../util/bus'
export default {
    name: 'userCard',
    data () {
        return {
            userInfo: {},
            empy: require('../assets/img/icon6.png')
        }
    },
    mounted () {
        if (this.$store.state.token) {
            this.getUserInfo()
        }
        Bus.$on('getUserInfo', () => {
            this.getUserInfo()
        })
    },
    methods: {
        getUserInfo () {
            const data = {
                token: this.$store.state.token
            }
            this.$https.post("/api/Users/getUserInfo", data).then(res => {
                localStorage.setItem('userallinfo',JSON.stringify(res.data))
                this.userInfo = res.data
            })
        },
        login (type) {
            Bus.$emit('login', type)
        }
    }
}
</script>

<style lang="scss" scoped>
.user-card{
    width: 230px;
    height: 360px;
    background: url(../assets/img/img2.jpg) no-repeat center center/cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.user-tx{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 19px;
}
.user-title{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
}
.user-text{
    font-size: 14px;
    color: #2687fa;
    margin-bottom: 17px;
}
.user-btn1,.user-btn2{
    width: 130px;
    height: 42px;
    border-radius: 21px;
}
.user-btn2{
    margin-top: 10px;
}
</style>