<template>
  <div>
    <div class="left">
      <div class="left-info" ref="leftinfo" id="leftinfo">
        <div class="flex just-start align-center">
          <img :src="userinfo.picurl" />
          <div class="left-info-grade">
            <p>ID：{{ userinfo.id }}</p>
            <p>
              信誉分：<span>{{userinfo.credit_score}}分 {{ userinfo.credit_score <= 2 ? '极差' : userinfo.credit_score > 2 && userinfo.credit_score <= 4 ? '较差' : userinfo.credit_score > 4 && userinfo.credit_score <= 6 ? '一般' : userinfo.credit_score > 6 && userinfo.credit_score <= 8 ? '良好' : '极好' }}</span>
            </p>
          </div>
        </div>
        <div class="flex left-coin">
          <div class="left-coin-box">
            <p>
              <span>{{ userinfo.coins.includes('万') ? userinfo.coins : userinfo.coins * 1 }}</span
              >枚
            </p>
            <p>旺点币</p>
            <button>
              <a href="javascript:;" @click="recharge">充值</a>
            </button>
          </div>
          <div class="left-coin-box">
            <p>
              ¥<span>{{ userinfo.balance.includes('万') ? userinfo.balance : userinfo.balance * 1 }}</span>
            </p>
            <p>账户余额</p>
            <div class="flex coin-box-button">
              <button>
                <a href="javascript:;" @click="balancerecharge">充值</a>
              </button>
              <button>
                <a href="javascript:;" @click="cashapply">提现</a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="left-comment">
        <div class="flex align-center comment-bar">
          <p>我的评价</p>
          <a href="/evaluate.html">查看更多</a>
          <!-- <a href="/evaluate.html" @click="getToolList">使用道具</a> -->
          <!-- <button type="button" @click="getToolList">使用道具</button> -->
        </div>
        <div class="comment-list">
          <ul>
            <li v-for="item in list" :key="item.id">
              <div class="flex">
                <div class="flex just-start align-center">
                  <img :src="item.head_pic" class="comment-list-head" />
                  <div class="comment-user">
                    <p>{{ item.name }}</p>
                    <p>{{ item.add_time }}</p>
                  </div>
                </div>
                <div class="flex left-start" v-if="item.stars">
                  <div
                    class="start"
                    :class="{ flash: index < item.stars }"
                    v-for="(start, index) in 5"
                    :key="index"
                  ></div>
                </div>
              </div>
              <div class="comnent-gray">
                {{ item.content }}
              </div>
            </li>
          </ul>
        </div>
        <div class="mt25">
          <MyPagination
            :total="count"
            :pageSize="3"
            :pagerCount="pagerCount"
            @change="change"
          ></MyPagination>
        </div>
      </div>
    </div>
    <Modal ref="sideprop-modal">
      <div class="prop modal">
        <div class="modal-container">
          <div class="modal-main block">
            <p class="prop-title">道具列表</p>
            <img
              src="@/assets/img/z-icon9.png"
              class="prop-close"
              @click="$refs['sideprop-modal'].manageModal(false)"
            />
            <div>
              <ul class="flex">
                <li v-for="item in toolList" :key="item.id">
                  <div class="flex just-start prop-intro">
                    <img :src="item.thumb" />
                    <div class="prop-intro-text">
                      <p>{{ item.title }}</p>
                      <p>{{item.coins * 1}}旺点币</p>
                      <p>增加{{item.fiery}}火热值</p>
                    </div>
                  </div>
                  <button>立即购买</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
// import MyPagination from '@/components/myPagination.vue'
import Bus from "@/util/bus";
export default {
  // components: {MyPagination},
  data() {
    return {
      userinfo: {
        picurl: '',
        credit_score: '',
        coins: '',
        balance: ''
      },
      page: 1,
      count: 0,
      list: [],
      toolList: [],
      pagerCount: 3
    };
  },
  created() {
    // const userinfo = JSON.parse(localStorage.getItem("userallinfo"));
    // this.userinfo = userinfo;
    Bus.$on('cash',() => {
      // this.userinfo.balance = this.userinfo.balance - price
      this.getUserInfo().then(() => {
        const userinfo = JSON.parse(localStorage.getItem('userallinfo'))
        // console.log('----')
        // console.log(userinfo)
        // console.log('----')
        Bus.$emit('updateuserinfo',userinfo)
      })
    })
    this.getList();
    this.getUserInfo()
  },
//   computed: {
//     userinfo() {
//         return JSON.parse(localStorage.getItem('userallinfo'))
//     }
//   },
  methods: {
    cashapply() {
      Bus.$emit("lefttopage", 2, 5);
    },
    recharge() {
      Bus.$emit("recharge");
    },
    balancerecharge() {
      Bus.$emit("balancerecharge");
    },
    getList() {
      const loading = this.showLoading();
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      const data = {
        token,
        typeid: 1,
        page: this.page,
      };
      this.$https
        .post("/api/Users/evaluatelist", data)
        .then((res) => {
          this.list = res.data;
          this.count = res.count;
        })
        .finally(() => {
          loading.close();
        });
    },
    change(index) {
      this.page = index;
      this.getList();
    },
    //获取道具列表
    getToolList() {
      this.$https.get("/api/Currency/propslist?cat_id=1").then((res) => {
        this.toolList = res.data
        this.$refs['sideprop-modal'].manageModal(true)
      });
    },
    getUserInfo() {
        const loading = this.showLoading()
        const token = JSON.parse(localStorage.getItem('userInfo')).token
        return this.$https.get('/api/Users/getUserInfo?token='+token).then(res => {
            this.userinfo = res.data
            if(this.userinfo.picurl == '') {
              this.userinfo.picurl = require('../../../assets/img/icon6.png')
            }
            localStorage.setItem('userallinfo',JSON.stringify(res.data))
            Bus.$emit('updatecoins')
        }).finally(() => {
          setTimeout(() => {
            loading.close()
          },500)
        })
    }
  },
};
</script>