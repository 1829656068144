import axios from "axios"
import { Message } from "element-ui"
import store from "@/store"

window.baseUrl = 'https://www.wds88.com'
window.pageUrl = 'https://www.wds88.com'

const https = axios.create({
    headers: {
        'Content-Type': 'application/json'
    },
    baseURL: window.baseUrl,
    timeout: 10000
})

https.interceptors.request.use(config => {
    // 剔除值为null或undefined的键
    for(let key in config.data){
        if(config.data[key]===null||config.data[key]===undefined){
            delete config.data[key];
        }
    }

    const form = new FormData()
    for (const key in config.data) {
        if (key == 'token' && config.data[key] == undefined) {
            config.data[key] = ''
        }
        form.append(key, config.data[key])
    }
    config.data = form
    return config
}, error => {
  Message.error('操作失败')
  return Promise.reject(error)
})

https.interceptors.response.use(response => {
    if (response.status == 200) {
        let res = response.data
        let arr = ['/api/Alipay/pay2','/api/Weixinpay/pcPay']
        if (arr.includes(response.config.url)) {
            return res
        }
        if (res.errcode == 1) {
            return res
        } else {
            if (res.errcode == -100 || res.errcode == -200) {
                localStorage.removeItem('userInfo')
                localStorage.removeItem('userallinfo')
                store.state.userInfo = {}
                store.state.token = null
                Message.error('登录状态异常，请重新登录')
                return Promise.reject(res)
            }
            Message.error(res.message)
            return Promise.reject(res)
        }
    } else {
        Message.error('网络请求失败')
    }
}, error => {
    Message.error('操作失败')
    return Promise.reject(error)
})

export default https