<template>
  <div class="syb-detail">
    <div class="index-container">
      <div class="crumbs">
        <img src="../../assets/img/z-icon1.png" />
        当前所在位置：<a href="index.html">旺点来</a> >
        <a href="syb.html">创业点子</a> >
        <a class="active">{{ dzdetailInfo.title }}</a>
      </div>

      <div class="flex">
        <div class="syb-left">
          <div class="syb-con">
            <div class="flex align-center syb-con-top">
              <div class="syb-con-title">
                <p class="flex just-start align-center">
                  <span>{{ dzdetailInfo.cat_name }}</span
                  >{{ dzdetailInfo.title }}
                </p>
                <p class="syb-con-title-time">
                  发布时间：{{
                    dzdetailInfo.update_time
                  }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;火热值：<span>{{
                    dzdetailInfo.fiery
                  }}</span>
                </p>
              </div>
              <button @click="sydj()">使用道具</button>
            </div>
            <div class="syb-con-container">
              <p v-html="dzdetailInfo.content"></p>
            </div>
          </div>
          <div class="syb-eva">
            <p class="syb-eva-tip">评论</p>
            <div class="syb-list-block">
              <div class="eva-con">
                <div class="sub-box">
                  <textarea
                    v-model="pllist.content"
                    class="sub-box__textarea"
                    placeholder="欢迎输入您的想法一起交流..."
                  ></textarea>
                  <div class="flex sub-box-button">
                    <button @click="handleFb()">发布</button>
                  </div>
                </div>
              </div>
              <div class="eva-list-block">
                <div
                  class="eva-list"
                  v-for="item in commentList"
                  :key="item.id"
                >
                  <div class="flex just-start align-center eva-list-user">
                    <img :src="item?.head_pic" />
                    <p>{{ item.userid }}</p>
                  </div>
                  <div>
                    <div class="eva-block">
                      <p class="eva-block__p">
                        {{ item.content }}
                      </p>
                      <div class="flex align-center eva-block-info">
                        <p class="eva-time">{{ item.add_time }}</p>
                        <div class="flex align-center">
                          <div
                            @click="handleDz(item)"
                            class="flex align-center eva-good"
                            :class="{ active: item.is_like == 1 }"
                          >
                            <img
                              :class="{ filter50: item.is_like == 0 }"
                              src="@/assets/img/z-icon33.png"
                            />
                            <span>点赞 {{ item.like_count }}</span>
                          </div>
                          <div
                            @click="replay(item)"
                            class="flex align-center eva-reply"
                          >
                            <img src="@/assets/img/z-icon34.png" />
                            <span>回复</span>
                          </div>
                        </div>
                      </div>
                      <div class="sub-box" v-show="item.flag">
                        <textarea
                          v-model="item.zjcontent"
                          class="sub-box__textarea"
                          placeholder="欢迎输入您的想法一起交流..."
                        ></textarea>
                        <div
                          @click="handleHuifu(item)"
                          class="flex sub-box-button"
                        >
                          <button>回复</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="eva-list-son"
                    v-for="items in item.reply"
                    :key="items.id"
                  >
                    <div class="flex just-start align-center eva-list-user">
                      <img :src="items.head_pic" />
                      <p>
                        {{ items.userid }}
                        <span>回复 &nbsp;&nbsp;@{{ item.userid }}</span>
                      </p>
                    </div>
                    <div>
                      <div class="eva-block">
                        <p class="eva-block__p">
                          {{ items.content }}
                        </p>
                        <div class="flex align-center eva-block-info">
                          <p class="eva-time">{{ items.add_time }}</p>
                          <div class="flex align-center">
                            <div
                              @click="handleDz(items)"
                              class="flex align-center eva-good"
                              :class="{ active: items.is_like == 1 }"
                            >
                              <img
                                :class="{ filter50: items.is_like == 0 }"
                                src="@/assets/img/z-icon33.png"
                              />
                              <span>点赞 {{ items.like_count }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <myPagination
                  :total="total"
                  class="pagination"
                  v-model="page"
                ></myPagination>
              </div>
            </div>
          </div>
        </div>
        <div class="syb-right">
          <div class="syb-right-user">
            <div class="flex just-start align-center syb-user-top">
              <img :src="publisher?.head_pic" />
              <div class="syb-user-info">
                <p>发布人：{{ publisher.name }}</p>
                <p>
                  共发布<span>{{ publisher.address_count }}个</span>地址
                </p>
                <p>
                  信誉分：<span
                    >{{ publisher.credit_score }}分
                    {{ publisher.evaluate }}</span
                  >
                </p>
              </div>
            </div>
            <button
              v-if="dzdetailInfo.is_mobile == 0"
              @click="handleLogin()"
              class="syb-user__button"
            >
              点击获取联系电话
            </button>
            <button v-else class="syb-user__button">
              {{ dzdetailInfo.mobile }}
            </button>
            <p class="syb-user__p">联系我时，请说是旺点来上看到的，谢谢！</p>
          </div>
          <div class="syb-right-his">
            <p class="syb-his-top">历史评价</p>
            <div class="left-comment" v-if="evaluateList1total > 0">
              <div class="comment-list">
                <ul>
                  <li v-for="item in evaluateList1" :key="item.id">
                    <div class="flex">
                      <div class="flex just-start align-center">
                        <img :src="item?.head_pic" class="comment-list-head" />
                        <div class="comment-user">
                          <p>{{ item.name }}</p>
                          <p>{{ item.add_time }}</p>
                        </div>
                      </div>
                      <div class="flex left-start">
                        <div
                          v-for="(items, index) in [1, 2, 3, 4, 5]"
                          :key="index"
                          class="start"
                          :class="{ flash: item.stars > index }"
                        ></div>
                      </div>
                    </div>
                    <div class="comnent-gray">
                      {{ item.content }}
                    </div>
                  </li>
                </ul>
              </div>
              <div class="mt25">
                <myPagination
                  type="small"
                  class="pagination"
                  :total="evaluateList1total"
                  :pageSize="3"
                  :pagerCount="3"
                  v-model="evaluatelist.page"
                ></myPagination>
              </div>
            </div>
          </div>
          <div class="syb-que">
            <p class="syb-que__p">旺点问答</p>
            <div class="syb-que-list">
              <ul>
                <li
                  v-for="item in questionslist1"
                  :key="item.id"
                  @click="lookmore(item)"
                >
                  <div class="flex align-center">
                    <div class="flex just-start syb-que-title">
                      <img src="@/assets/img/z-icon37.png" />
                      <p>{{ item.title }}</p>
                    </div>
                    <img
                      src="@/assets/img/z-icon38.png"
                      :class="['syb-que-arr', { active: item.flag }]"
                    />
                  </div>
                  <div class="syb-que-desc" v-show="item.flag">
                    {{ item.content }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="syb-que">
            <p class="syb-que__p">更多创业点子</p>
            <div class="syb-que-list">
              <ul>
                <li
                  @click="handleUrlDz(item)"
                  v-for="item in recommendList1"
                  :key="item.id"
                >
                  <div class="flex align-center">
                    <div class="flex just-start syb-que-title">
                      <img src="@/assets/img/z-icon39.png" />
                      <p>{{ item.title }}</p>
                    </div>
                    <img
                      src="@/assets/img/z-icon38.png"
                      :class="['syb-que-arr', { active: item.flag }]"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="syb-suggest">
        <p class="syb-suggest-top">地址转让推荐</p>
        <div
          class="flex align-center suggest-list"
          v-for="item in addressList"
          :key="item.id"
          @click="handleUrl(item)"
        >
          <div class="flex just-start align-center">
            <div class="suggest-list-date">
              <p>{{ item.day }}</p>
              <p>{{ item.years }}</p>
            </div>
            <div class="suggest-list-main">
              <div class="flex just-start align-center suggest-list-title">
                <p class="suggest-list__p">
                  <span>{{ item.cat_name }}</span
                  >（编号：{{ item.address_sn }}）{{ item.title }}
                </p>
                <div class="suggest-list-img">
                  <img src="@/assets/img/icon26.png" />
                  <p class="suggest-list-num">火热值：{{ item.fiery }}</p>
                </div>
              </div>
              <p class="suggest-list-type">
                地址类别：<span>{{ item.cat_name }}</span
                >期限：<span>{{ item.term }}</span
                >地址性质：<span>{{ item.nature_name }}</span>
              </p>
            </div>
          </div>
          <div
            v-if="item.price == 0 && item.final_price == 0"
            class="suggest-list-button"
          >
            <button>
              <p>价格面议</p>
              <p></p>
            </button>
          </div>

          <div v-else class="flex align-center suggest-list-button">
            <button>
              <p>{{ item.price }}</p>
              <p>起购价(¥)</p>
            </button>
            <span>~</span>
            <button>
              <p>{{ item.final_price }}</p>
              <p>当前最高(¥)</p>
            </button>
          </div>
        </div>
        <!-- <div class="flex align-center suggest-list">
          <div class="flex just-start align-center">
            <div class="suggest-list-date">
              <p>23</p>
              <p>2023.10</p>
            </div>
            <div class="suggest-list-main">
              <div class="flex just-start align-center suggest-list-title">
                <p class="suggest-list__p">
                  <span class="hire">出租</span
                  >（编号：732545621）浙江省拱墅区复地壹中心A座4楼412室
                </p>
                <div class="suggest-list-img">
                  <img src="@/assets/img/icon26.png" />
                  <p class="suggest-list-num">火热值：3241</p>
                </div>
              </div>
              <p class="suggest-list-type">
                地址类别：<span>售卖</span>期限：<span>无</span>地址性质：<span
                  >写字楼</span
                >
              </p>
            </div>
          </div>
          <div class="flex align-center suggest-list-button">
            <button>
              <p>0</p>
              <p>起购价(¥)</p>
            </button>
            <span>~</span>
            <button>
              <p>2000</p>
              <p>当前最高(¥)</p>
            </button>
          </div>
        </div> -->
        <a
          href="address.html"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          "
          class="syb-suggest__button"
          >查看更多地址信息</a
        >
      </div>
    </div>
    <Modal ref="prop-modal">
      <div class="prop modal">
        <div class="modal-container">
          <div class="modal-main block">
            <p class="prop-title">道具列表</p>
            <img
              src="@/assets/img/z-icon9.png"
              class="prop-close"
              @click="$refs['prop-modal'].manageModal(false)"
            />
            <div>
              <ul class="flex">
                <li v-for="item in propsList" :key="item.id">
                  <div class="flex just-start prop-intro">
                    <img :src="item?.thumb" />
                    <div class="prop-intro-text">
                      <p>{{ item.title }}</p>
                      <p>{{ item.coins }}旺点币</p>
                      <p>
                        {{ item.types == 2 ? "减少" : "增加"
                        }}{{ item.fiery }}火热值
                      </p>
                    </div>
                  </div>
                  <button @click="handleBuy(item)">立即购买</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <Modal ref="zf-modal">
      <div class="modal zfmodal" id="zf-modal">
        <div class="modal-container">
          <div class="modal-main zf-main" style="height: auto">
            <img
              src="@/assets/img/z-icon9.png"
              class="zf-main-close"
              @click="$refs['zf-modal'].manageModal(false)"
            />
            <p class="zf-main-title">点子解锁</p>
            <form action="">
              <div class="flex just-start align-center">
                <div class="relative" style="margin: 20px 0 35px 0">
                  点子尚未解锁，是否解锁，解锁后不支持撤销！
                </div>
              </div>

              <div class="flex">
                <button
                  @click="
                    $refs['zf-modal'].manageModal(false);
                    jsId = '';
                  "
                  type="button"
                  class="zf-main-button"
                  style="margin-bottom: 20px"
                  id="confirm-zfb"
                >
                  取消
                </button>
                <button
                  @click="ideas_unlock()"
                  type="button"
                  class="zf-main-button"
                  style="margin-bottom: 20px"
                  id="confirm-zfb"
                >
                  确定
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>

    <Modal ref="zf-modal1">
      <div class="modal zfmodal" id="zf-modal">
        <div class="modal-container">
          <div class="modal-main zf-main" style="height: auto">
            <img
              src="@/assets/img/z-icon9.png"
              class="zf-main-close"
              @click="$refs['zf-modal1'].manageModal(false)"
            />
            <p class="zf-main-title">温馨提示</p>
            <form action="">
              <div class="flex just-start align-center">
                <div
                  class="relative"
                  style="margin: 20px 0 35px 0; text-align: center; width: 100%"
                >
                  <p>
                    本次查看需要
                    <span class="ff72">{{
                      dzdetailInfo.ideas_mobile_unlock
                    }}</span>
                    枚旺点币
                  </p>
                  <p style="margin-top: 15px">
                    当前剩余
                    <span class="ff72">{{ dzdetailInfo.coins }}</span> 枚
                  </p>
                </div>
              </div>

              <div class="flex">
                <button
                  @click="jiesuophone()"
                  type="button"
                  class="zf-main-button"
                  style="margin-bottom: 20px"
                  id="confirm-zfb"
                >
                  购买
                </button>
                <button
                  @click="
                    $refs['zf-modal1'].manageModal(false);
                    jsId = '';
                  "
                  type="button"
                  class="zf-main-button fanhui994"
                  style="margin-bottom: 20px"
                  id="confirm-zfb"
                >
                  返回
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import myPagination from "@/components/myPagination.vue";
import { getUrlData } from "@/util/utils";
import { Message } from "element-ui";

export default {
  name: "syb_detail",

  components: { myPagination },
  data() {
    return {
      phones: 0,
      page: 1,
      total: 0,
      pagerCount: 3,
      dzdetailInfo: {},
      publisher: {},
      siteid: "",
      list: [
        {
          title: "如何买地址?",
          desc: "如何买地址如何买地址如何买地址如何买地址如何买地址如何买地址",
          flag: false,
        },
        {
          title: "如何买地址?",
          desc: "如何买地址如何买地址如何买地址如何买地址如何买地址如何买地址",
          flag: false,
        },
      ],
      evalist: [
        {
          flag: false,
        },
      ],
      commentList: [],
      pllist: {
        ideas_id: 0,
        comment_id: 0,
        token: "",
        content: "",
      },
      dalist: {
        type: 0,
        comment_id: 0,
        token: "",
      },
      zjcontent: "",
      recommendList: {
        page: 1,
        id: "",
      },
      recommendList1: [],
      questionslist1: [],
      evaluatelist: {
        token: "",
        publish_id: "",
        typeid: 1,
        page: 1,
      },
      propsList: [],
      evaluateList1: [],
      addressList: [],
      evaluateList1total: 0,
      crumbsTitle: [
        {
          path: "syb.html",
          name: "创业点子",
        },
      ],
      jsId: "",
    };
  },
  watch: {
    page(news) {
      this.page = news;
      this.comment_list();
    },

    "evaluatelist.page"(news) {
      this.evaluatelist.page = news;
      this.evaluateList();
    },
  },
  created() {
    this.loading = this.showLoading();
    Promise.all([
      this.xqdetail(),
      this.comment_list(),
      this.recomMend(),
      this.questionslist(),

      this.propslist(),
      this.addresslist(),
    ]).finally(() => {
      this.loading.close();
      this.loading = null;
      this.typePrevStatus == 1;
    });
  },

  methods: {
    handleLogin() {
      if (this.$store.state.token == undefined) {
        Message.warning("请先登录！");
        return;
      }

      this.$refs["zf-modal1"].manageModal(true);
    },
    ideas_unlock() {
      let loading = this.showLoading();
      this.token = JSON.parse(localStorage.getItem("userInfo"))?.token;

      this.$https
        .get("/api/Ideas/ideas_unlock", {
          params: {
            id: this.jsId,
            token: this.token,
          },
        })
        .then((res) => {
          Message.success(res.message);
          this.$refs["zf-modal"].manageModal(false);

          setTimeout(() => {
            window.location.href = "syb_detail.html?id=" + this.jsId;
          }, 1000);
        })
        .finally(() => {
          loading.close();
        });
    },
    jiesuophone() {
      let loading = this.showLoading();
      this.$https
        .get("/api/Ideas/mobile_unlock", {
          params: {
            id: this.id,
            token: this.$store.state.token,
          },
        })
        .then((res) => {
          // console.log(res);
          this.addressList = res.data;
          this.$refs["zf-modal1"].manageModal(false);
          this.xqdetail();
        })
        .finally(() => {
          loading.close();
        });
    },
    handleUrlDz(item) {
      if (this.$store.state.token == undefined && item.type == 1) {
        Message.warning("请先登录！");
        return;
      }

      if (item.type == 1) {
        this.jsId = item.id;
        this.$refs["zf-modal"].manageModal(true);
        return;
      }
      window.location.href = "syb_detail.html?id=" + item.id;
    },
    handleUrl(item) {
      window.location.href = "address_detail.html?id=" + item.id;
    },
    addresslist() {
      let loading = this.showLoading();
      this.$https
        .get("/api/Ideas/addresslist")
        .then((res) => {
          console.log(res);
          this.addressList = res.data;
        })
        .finally(() => {
          loading.close();
        });
    },

    sydj() {
      if (this.$store.state.token == undefined) {
        Message.warning("请先登录！");
        return;
      }

      if (this.propsList.length > 0) {
        this.$refs["prop-modal"].manageModal(true);
      } else {
        Message.warning("道具更新中，敬请期待！");
      }
    },

    handleBuy(item) {
      let loading = this.showLoading();
      this.$https
        .get("/api/Currency/props", {
          params: {
            token: this.token,
            id: item.id,
            common_id: this.id,
            typeid: 4,
          },
        })
        .then((res) => {
          Message.success("购买成功");
          this.xqdetail(), this.$refs["prop-modal"].manageModal(false);
          console.log(res);
        })
        .finally(() => {
          loading.close();
        });
    },
    propslist() {
      let loading = this.showLoading();
      this.$https
        .get("/api/Currency/propslist", {
          params: {
            cat_id: 2,
          },
        })
        .then((res) => {
          this.propsList = res.data;
          console.log(res);
        })
        .finally(() => {
          loading.close();
        });
    },
    evaluateList() {
      // 历史评价
      // let loading = this.showLoading();
      this.evaluatelist.token = this.token;
      console.log(this.dzdetailInfo);
      this.evaluatelist.publish_id = this.dzdetailInfo.user_id;
      this.$https
        .get("/api/Users/evaluatelist", {
          params: {
            ...this.evaluatelist,
          },
        })
        .then((res) => {
          this.evaluateList1total = res.count;
          this.evaluateList1 = res.data;
        })
        .finally(() => {
          // loading.close();
        });
    },
    questionslist() {
      // 旺店回答
      let loading = this.showLoading();
      this.$https
        .get("/api/Currency/questionslist")
        .then((res) => {
          console.log(res);
          this.questionslist1 = res.data.map((item) => {
            item.flag = false;
            return item;
          });
        })
        .finally(() => {
          loading.close();
        });
    },
    recomMend() {
      // 更多创业点子
      let loading = this.showLoading();
      this.recommendList.id = this.id;
      this.$https
        .get("/api/Ideas/recommend", {
          params: {
            id: this.recommendList.id,
            page: this.recommendList.page,
            token: this.$store.state.token,
          },
        })
        .then((res) => {
          console.log(res);
          this.recommendList1 = res.data.map((item) => {
            item.flag = false;
            return item;
          });
        })
        .finally(() => {
          loading.close();
        });
    },
    handleDz(item) {
      this.dalist.comment_id = item.id;
      this.dalist.type = item.is_like;
      let type = 0;
      if (this.dalist.type == 1) {
        type = 2;
      } else {
        type = 1;
      }
      //点赞
      let loading = this.showLoading();
      this.$https
        .get("/api/Ideas/comment_like", {
          params: {
            comment_id: this.dalist.comment_id,
            token: this.token,
            type: type,
          },
        })
        .then((res) => {
          console.log(res);
          this.comment_list();
        })
        .finally(() => {
          loading.close();
        });
    },
    handleHuifu(item) {
      let loading = this.showLoading();
      this.pllist.comment_id = item.id;
      this.pllist.content = item.zjcontent;
      this.$https
        .get("/api/Ideas/comment", {
          params: {
            ideas_id: this.id,
            comment_id: this.pllist.comment_id,
            token: this.token,
            content: this.pllist.content,
          },
        })
        .then((res) => {
          res;
          // console.log(res.data);
          this.pllist.content = "";
          this.pllist.comment_id = 0;
          this.comment_list();
        })
        .finally(() => {
          loading.close();
        });
    },
    handleFb() {
      let loading = this.showLoading();
      this.$https
        .get("/api/Ideas/comment", {
          params: {
            ideas_id: this.id,
            comment_id: this.pllist.comment_id,
            token: this.token,
            content: this.pllist.content,
          },
        })
        .then((res) => {
          res;
          // console.log(res.data);
          this.pllist.content = "";
          this.pllist.comment_id = 0;
          this.comment_list();
          // this.publisher = res.data.publisher;
          // this.dzdetailInfo = res.data.info;
        })
        .finally(() => {
          loading.close();
        });
    },
    xqdetail() {
      this.id = parseInt(getUrlData().id);
      this.token = JSON.parse(localStorage.getItem("userInfo"))?.token;
      let loading = this.showLoading();
      this.$https
        .get("/api/Ideas/detail", {
          params: {
            id: this.id,
            token: this.token,
          },
        })
        .then((res) => {
          console.log(res.data);
          this.publisher = res.data.publisher;
          this.dzdetailInfo = res.data.info;
          this.evaluateList();
          // let item1 = {
          //   name: this.dzdetailInfo.title,
          //   path: "",
          // };
          // this.crumbsTitle.push(item1);
        })
        .finally(() => {
          loading.close();
        });
    },
    comment_list() {
      //评论列表
      this.id = parseInt(getUrlData().id);
      this.siteid = JSON.parse(localStorage.getItem("userallinfo"))?.siteid;
      this.token = JSON.parse(localStorage.getItem("userInfo"))?.token;
      let loading = this.showLoading();
      this.$https
        .get("/api/Ideas/comment_list", {
          params: {
            siteid: this.siteid,
            ideas_id: this.id,
            token: this.token,
            page: this.page,
          },
        })
        .then((res) => {
          console.log(res.data);
          this.total = res.count;
          this.commentList = res.data.map((item) => {
            item.flag = false;
            item.zjcontent = "";
            return item;
          });
        })
        .finally(() => {
          loading.close();
        });
    },
    lookmore(item) {
      item.flag = !item.flag;
    },
    replay(item) {
      item.flag = !item.flag;
      this.zjcontent = "";
    },
  },
};
</script>

<style  scoped>
.filter50 {
  filter: brightness(25%) invert(45%);
}
</style>