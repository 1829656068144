import { isObjectEmpty } from "./utils"
import store from "@/store"

export default {
    data () {
        return {
            pathname: '',
            userInfo: {},
            baseUrl: null
        }
    },
    created () {
        this.userInfo = localStorage.getItem('userInfo') || {}
        this.pathname = location.pathname
    },
    mounted () {
        this.setPathName()
        this.baseUrl = window.baseUrl
    },
    methods: {
        setPathName () {
            let pathname = location.pathname === '/' ? '/index.html' : location.pathname
            let pathnameReg = new RegExp(/\/([a-z]+)(_?)([a-z]+).html/g)
            let match = pathname.match(pathnameReg)
            pathname = match[0].slice(1, -5)
            store.state.nowPathName = pathname
        },
        showLoading (options) {
            const text = options?.text || ''
            const target = options?.target || document.body
            return this.$loading({
                lock: true,
                target: target,
                text: text,
                background: 'rgba(255, 255, 255, 0.8)'
            })
        },
        replaceLink (options) {
            const path = options?.path || store.state.nowPathName
            const query = options?.query || {}
            let url = '/' + path + '.html'
            if (!isObjectEmpty(query)) {
                url = url + '?'
                for (const key in query) {
                    if (Object.hasOwnProperty.call(query, key)) {
                        const element = query[key];
                        url += key + '=' + element + '&'
                    }
                }
                url = url.slice(0, -1)
            }
            window.history.replaceState(query, '', url)
        }
    }
}