<template>
  <main class="agent my-background">
    <section class="section1 index-container my-screen">
      <div class="screen-item screen-select">
        <p class="label"><span>服务区域</span>:</p>
        <div>
          <mySelect
            @change="upDatatile"
            :modelValue="prvName"
            :data="prvList"
            width="176"
            placeholder="---请选择省---"
          ></mySelect>
          <mySelect
            @change="upDataCity"
            :modelValue="cityName"
            :data="cityList"
            width="176"
            placeholder="---请选择市---"
          ></mySelect>
          <mySelect
            @change="upDataArea"
            :modelValue="areaName"
            :data="areaList"
            width="176"
            placeholder="---请选择区---"
          ></mySelect>
        </div>
      </div>
      <div class="screen-item screen-checkbox">
        <p class="label"><span>服务项目</span>:</p>
        <el-checkbox-group v-model="val1">
          <el-checkbox
            v-for="item in serversList"
            :key="item.orderid"
            :label="item.orderid"
            name="type"
            >{{ item.title }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div class="screen-item screen-search">
        <button class="my-button" @click="daibanList()">搜索</button>
      </div>
    </section>
    <section class="section2 index-container my-sort">
      <div class="left">
        <div
          class="item"
          :class="{ active: sort_order == index }"
          @click="toggles(index)"
          v-for="(item, index) in sortData"
          :key="item.id"
        >
          <span>{{ item.name }}</span>
          <img src="../../assets/img/icon35.png" alt="" />
        </div>
        <!-- <div class="item active">
          <span>按时间排序</span>
          <img src="../../assets/img/icon35.png" alt="" />
        </div>
        <div class="item">
          <span>按热度排序</span>
          <img src="../../assets/img/icon35.png" alt="" />
        </div> -->
      </div>
      <a @click="handleUrl()" class="right">免费入驻</a>
    </section>
    <section class="section3 index-container">
      <div class="list">
        <a
          :href="'agent_detail.html?id=' + item.id"
          class="item"
          v-for="item in datailList"
          :key="item.id"
        >
          <img v-if="item.picurl" class="item-img" :src="item?.picurl" />
          <div class="item-info">
            <div class="item-title my-hot">
              <p class="text">{{ item.title }}</p>
              <img class="icon" src="../../assets/img/icon26.png" alt="" />
              <span class="hot">火热值：{{ item.fiery }}</span>
            </div>
            <p class="item-text">
              <span v-for="(item1, index1) in item.wcd_name_arr" :key="item1"
                >{{ item1 }}&emsp;{{
                  index1 < item.wcd_name_arr.length - 1 ? "|" : ""
                }}&emsp;</span
              >
            </p>
            <div class="item-bottom">
              <p class="item-address">
                <img src="../../assets/img/icon27.png" alt="" />{{
                  item.city_name
                }}
              </p>
              <p class="item-tel" v-if="item.mobile">
                <img src="../../assets/img/icon28.png" alt="" />联系电话：<b>{{
                  item.mobile
                }}</b>
              </p>
            </div>
          </div>
        </a>
      </div>
      <myPagination
        :total="total"
        class="pagination"
        v-model="page"
      ></myPagination>
    </section>
  </main>
</template>

<script>
import mySelect from "@/components/mySelect.vue";
import myPagination from "@/components/myPagination.vue";
import { getRegionList } from "@/util/utils";
import { Message } from "element-ui";
export default {
  name: "index",
  components: { mySelect, myPagination },
  data() {
    return {
      sortData: [
        {
          id: 1,
          name: "按时间排序",
        },
        {
          id: 2,
          name: "按热度排序",
        },
      ],
      val1: [],
      page: 1,
      prvList: [],
      cityList: [],
      areaList: [],
      datailList: [], //详情列表数组
      serversList: [], //服务列表
      loading: null,
      prvName: null,
      cityName: null,
      areaName: null,
      typePrevStatus: 0,
      total: 0,
      sort: 0,
      sort_order: 1,
    };
  },
  async created() {
    this.loading = this.showLoading();
    Promise.all([
      this.getRegionList1(),
      this.getWeCanDo(),
      this.daibanList(),
    ]).finally(() => {
      this.loading.close();
      this.loading = null;
      this.typePrevStatus == 1;
    });
  },
  watch: {
    // sort(news) {
    //   this.sort = news;
    //   this.daibanList();
    // },
    page(news) {
      this.page = news;
      this.daibanList();
    },
  },

  methods: {
    handleUrl() {
      if (this.$store.state.token == undefined) {
        Message.error("请先登录！");
        return;
      }

      window.location.href = "agent_release.html";
    },
    toggles(index) {
      switch (index) {
        case 0:
          //时间排序
          this.sort_order = 0;
          if (this.sort == 2) {
            this.sort = 1;
          } else {
            this.sort = 2;
          }
          break;
        case 1:
          // 热度排序
          this.sort = 0;

          if (this.sort_order == 2) {
            this.sort_order = 1;
          } else {
            this.sort_order = 2;
          }
          break;

        default:
          break;
      }
      setTimeout(() => {
        this.daibanList();
      }, 300);
    },
    async getRegionList1() {
      let prvList = await getRegionList(0, 1, 0);
      this.prvList = [{ id: -1, parent_id: 0, name: "全国" }, ...prvList];
    },
    getWeCanDo() {
      //获取服务列表
      let loading = this.showLoading();

      this.$https
        .get("/api/Daiban/getWeCanDo")
        .then((res) => {
          this.serversList = res.data;
          console.log(res);
        })
        .finally(() => {
          loading.close();
        });
    },
    daibanList() {
      let loading = this.showLoading();
      // console.log(this.prvName);
      // if (this.val1.length > 0) {
      //   console.log(this.val1);
      //   console.log(this.val1.toString(), "$$$$$$$$$");
      // }

      this.$https
        .get("/api/Daiban/daibanList", {
          params: {
            province: this.prvName ? this.prvName : "",
            city: this.cityName ? this.cityName : "",
            district: this.areaName ? this.areaName : "",
            wecando_id: this.val1.length > 0 ? this.val1.toString() : "",
            page: this.page,
            time_order: this.sort == 1 ? "desc" : this.sort == 2 ? "asc" : "",
            fiery_order:
              this.sort_order == 1 ? "desc" : this.sort_order == 2 ? "asc" : "",
            // sort: this.sort,
          },
        })
        .then((res) => {
          this.datailList = res.data;
          this.total = res.count;
        })
        .finally(() => {
          this.wecando_id = [];
          loading.close();
        });
    },
    upDatatile(val) {
      this.prvName = val;
      this.typePrevStatus = 1;
      this.areaName = null;
      this.cityName = null;
      this.areaList = [];
      this.getRegionList();
    },
    upDataCity(val) {
      console.log(val);
      this.cityName = val;
      this.typePrevStatus = 2;
      this.areaName = null;
      this.getRegionList();
    },
    upDataArea(val) {
      this.areaName = val;
      this.typePrevStatus = 2;
      this.getRegionList();
    },
    getRegionList() {
      this.$https
        .get("/api/Region/getRegionList", {
          params: {
            parent_id: this.typePrevStatus == 1 ? this.prvName : this.cityName,
            level:
              this.typePrevStatus == 0 ? 1 : this.typePrevStatus == 1 ? 2 : 3,
            typeid: 0,
          },
        })
        .then((res) => {
          if (this.typePrevStatus == 2) {
            this.areaList = res.data;
          } else {
            this.cityList = res.data;
          }
        })
        .finally(() => {
          // this.daibanList();
        });
    },

    // getNewsSideList(type, limit) {
    //   return this.$https.get(
    //     "/api/News/list?posid=2&page=1&limit=" + limit + "&type=" + type
    //   );
    // },
    // getDetail() {
    //   const loading = this.showLoading();
    //   this.$https
    //     .get("/api/News/detail?id=" + this.id)
    //     .then((res) => {
    //       this.info = res.data.info;
    //     })
    //     .finally(() => {
    //       loading.close();
    //     });
    // },
    // //获取query
    // getQueryParameter(key) {
    //   const queryString = window.location.search;
    //   const urlParams = new URLSearchParams(queryString);
    //   return urlParams.get(key);
    // },
  },
};
</script>

<style lang="scss" scoped>
</style>