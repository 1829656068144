import { render, staticRenderFns } from "./release.vue?vue&type=template&id=4c149b3f&scoped=true"
import script from "./release.vue?vue&type=script&lang=js"
export * from "./release.vue?vue&type=script&lang=js"
import style0 from "./release.vue?vue&type=style&index=0&id=4c149b3f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c149b3f",
  null
  
)

export default component.exports