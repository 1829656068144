<template>
    <main class="address_detail my-background">
        <section class="section1 index-container">
            <div class="crumbs">
                <img src="../../assets/img/z-icon1.png">
                当前所在位置：<a href="/">旺点来</a> > <a href="address.html">找地址</a> > <a href="javascript:(0)">{{ info.address }}</a> > <a class="active">{{ info.title }}</a>
            </div>
        </section>
        <section class="section2 index-container">
            <div class="pad">
                <div class="top">
                    <div class="top-info">
                        <p class="top-title"><span>{{ info.sell_name }}</span><template v-if="info.address_sn">（编号：{{ info.address_sn }}）</template>{{ info.title }}</p>
                        <p class="top-text line1">更新时间：{{ info.update_time }}&emsp;&emsp;火热值：<span>{{ info.fiery }}</span></p>
                    </div>
                    <div class="top-save" @click="toggleCollect">
                        <div class="top-img-container">
                            <img v-if="info.is_collect == 0" src="../../assets/img/icon36.png" alt="" >
                            <img v-else src="../../assets/img/z-icon18.png" alt="" >
                        </div>
                        <span class="line1">收藏</span>
                    </div>
                </div>
                <ul class="list">
                    <li
                        class="list-item"
                        v-for="item,index in info.hottags"
                        :key="index">{{ item }}</li>
                </ul>
                <div class="content" v-if="info.sell_model != 4"><!-- 普通模式 -->
                    <div class="left style1">出售价：<span>
                        <template v-if="info.sell_model == 1">
                            &ensp;<b>免费转</b>
                        </template>
                        <template v-if="info.sell_model == 2">
                            &ensp;<b>面议</b>
                        </template>
                        <template v-if="info.sell_model == 3">
                            ¥&ensp;<b>{{ info.price }}</b>
                        </template>
                    </span></div>
                    <div class="right">
                        <button class="right-btn my-button" @click="sendBuy">发起交易<span>请双方谈妥价格后再发起交易</span></button>
                    </div>
                </div>
                <div class="content" v-else><!-- 拍卖模式 -->
                    <div class="left style2">
                        <div class="left-item">
                            <p><span class="price">{{ info.price }}</span><span class="tip">（每次加价不得少于1元）</span></p>
                            <p class="text">出售价(¥)</p>
                        </div>
                        <div class="left-item">
                            <p><span class="price">{{ info.final_price }}</span><span class="tip">（共<b>{{ info.num }}</b>人参与抢购）</span></p>
                            <p class="text">当前抢购价(¥)</p>
                        </div>
                    </div>
                    <div class="right">
                        <p class="right-tip" v-if="info.is_flashsale == 1"><img src="../../assets/img/icon37.png">抢购已结束，恭喜您抢购成功</p>
                        <button class="right-btn my-button" @click="sendBuy" v-if="info.is_flashsale == 1">发起交易<span>请双方谈妥价格后再发起交易</span></button>
                        <button v-if="info.is_flashsale == 0" class="right-btn my-button" :class="{end: info.is_count_down == 0, plain: info.is_count_down == 1}" @click="panicBuy">我要抢购</button>
                        <button v-if="info.is_flashsale == 1 || info.is_flashsale == 2" class="right-btn end">抢购已结束</button>
                        <!-- <button v-if="info.is_flashsale == 0 || info.is_flashsale == 1" class="right-btn my-button" @click="$refs.modal3.manageModal(true)">使用道具</button> -->
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="bottom-left">
                    <p>使用旺点来交易，全程保障您的资金安全</p>
                    <ul>
                        <li><img src="../../assets/img/icon38.png">安全保障</li>
                        <li><img src="../../assets/img/icon39.png">方便省心</li>
                        <li><img src="../../assets/img/icon40.png">极速退款</li>
                    </ul>
                </div>
                <div class="bottom-right" v-if="info.sell_model == 4 && info.is_count_down < 2">
                    <template v-if="info.is_count_down == 0">
                        距离开始抢购&ensp;
                    </template>
                    <template v-if="info.is_count_down == 1">
                        距离抢购结束&ensp;
                    </template>
                    <span class="active">{{ countDown.hours }}</span>
                    <span>:</span>
                    <span class="active">{{ countDown.minutes }}</span>
                    <span>:</span>
                    <span class="active">{{ countDown.seconds }}</span>
                    <el-statistic
                        style="position: absolute;width: 0;opacity: 0;"
                        ref="statistic"
                        @finish="getDetail"
                        @change="changeTime"
                        format="HH:mm:ss"
                        :value="count_down"
                        time-indices>
                    </el-statistic>
                </div>
            </div>
        </section>
        <section class="section3 index-container">
            <div class="left">
                <div class="top">
                    <span
                        class="top-item"
                        :class="{active: contentIndex == index}"
                        @click="contentIndex = index;scrollElement(`#bottom-item${index}`)"
                        v-for="item,index in contentArr"
                        :key="index">{{ item }}</span>
                </div>
                <div class="bottom">
                    <div class="bottom-item" id="bottom-item0">
                        <p class="bottom-title">基本信息</p>
                        <div class="bottom-content">
                            <p>地址名称：{{ info.title }}</p>
                            <p>地址类别：{{ info.cat_name }}</p>
                            <p>期限：{{ info.term }}</p>
                            <template v-if="info.sell_model == 1">
                                <p>价格：免费转</p>
                            </template>
                            <template v-else-if="info.sell_model == 2">
                                <p>价格：面议</p>
                            </template>
                            <template v-else>
                                <p>价格：￥{{ info.price }}元</p>
                            </template>
                            <p>地址性质：{{ info.nature_name }}</p>
                            <p>地址描述：<span v-html="info.address_desc"></span></p>
                        </div>
                    </div>
                    <div class="bottom-item" id="bottom-item1">
                        <p class="bottom-title">入驻信息</p>
                        <div class="bottom-content">
                            <p>入驻类型：{{ info.settled_type }}</p>
                            <p>入驻描述：<span v-html="info.settled_desc"></span></p>
                        </div>
                    </div>
                    <div class="bottom-item" id="bottom-item2">
                        <p class="bottom-title">税务信息</p>
                        <div class="bottom-content">
                            <p>纳税类型：{{ info.tax_type }}</p>
                            <p>这个地址能否开发票：{{ info.is_invoice }}</p>
                            <p>这个地址能否出口退税：{{ info.is_rebate }}</p>
                        </div>
                    </div>
                    <div class="bottom-item" id="bottom-item3">
                        <p class="bottom-title">地址展示</p>
                        <div class="bottom-imgs">
                            <img
                                v-for="item,index in info.images"
                                :key="index"
                                @click="lookImg(item)"
                                :src="item">
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="right-item my-user">
                    <div class="user-info">
                        <img class="user-tx" :src="publisher.head_pic" alt="">
                        <div>
                            <p class="user-name">发布人：{{ publisher.name }}</p>
                            <p class="user-text">共发布<span>{{ publisher.address_count }}个</span>地址</p>
                            <p class="user-text">信誉分：<span>{{ publisher.credit_score }}分  {{ publisher.evaluate }}</span></p>
                        </div>
                    </div>
                    <button class="user-tel" @click="clickGetTel">{{ info.is_mobile == 0 ? '点击获取联系电话' : info.mobile }}</button>
                    <p class="user-tip">联系我时，请说是旺点来上看到的，谢谢！</p>
                </div>
                <Evaluate
                    :id="info.user_id"></Evaluate>
                <Answers></Answers>
                <div class="right-item">
                    <p class="right-title">更多{{ info.city }}地址转让</p>
                    <div class="my-list2">
                        <div
                            class="item"
                            v-for="item in list"
                            :key="item.id"
                            @click="link(item.id)">
                            <div class="item-top">
                                <img class="item-img" src="../../assets/img/icon43.png" alt="">
                                <p class="item-title"><span v-if="item.address_sn">(编号：{{ item.address_sn }})</span>{{ item.title }}</p>
                                <img class="item-icon" src="../../assets/img/z-icon4.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section4 index-container">
            <p class="top-title">代办公司推荐</p>
            <div class="list">
                <a :href="'agent_detail.html?id=' + item.id" class="item" v-for="item in daibanlist" :key="item.id">
                    <img class="item-img" :src="item.picurl" alt="">
                    <div class="item-info">
                        <p class="item-title ellipsis">{{ item.title }}</p>
                        <p class="item-text" v-if="item.wcd_name_arr.length > 0">
                            <template v-for="chil,chilIndex in item.wcd_name_arr">
                                <span :key="chilIndex">{{ chil }}</span><span v-if="chilIndex != item.wcd_name_arr.length - 1"  :key="chilIndex + item.wcd_name_arr.length">  |  </span>
                            </template>
                        </p>
                    </div>
                    <p class="item-tel"><img src="../../assets/img/icon28.png" alt="" >联系电话：<b>{{ item.mobile }}</b></p>
                    <p class="item-address">{{ item.city_name }}</p>
                </a>
            </div>
            <a href="agent.html" class="index-button">查看更多代办信息</a>
        </section>
        <Modal ref="modal1">
            <div class="look-notice">
                <img @click="$refs.modal1.close()" class="close-icon" src="@/assets/img/z-icon9.png" alt="" >
                <p class="title">交易须知</p>
                <div class="list">
                    <div class="item">
                        <img class="item-icon" src="@/assets/img/icon42.png" alt="">
                        <div class="item-info">
                            <p class="item-title">如何收费？</p>
                            <p class="item-text">交易成功后收取卖方转让款的1%作为服务费（交易完成时扣除），不收取买方费用。</p>
                        </div>
                    </div>
                    <div class="item">
                        <img class="item-icon" src="@/assets/img/icon42.png" alt="">
                        <div class="item-info">
                            <p class="item-title">为什么要选择在线付款交易？</p>
                            <p class="item-text">线上的每一笔交易都将受到平台保护，待变更手续办理结束后才会打款给卖家。</p>
                        </div>
                    </div>
                    <div class="item">
                        <img class="item-icon" src="@/assets/img/icon42.png" alt="">
                        <div class="item-info">
                            <p class="item-title">卖方毁约，线上支付的地址转让款能不能退款？</p>
                            <p class="item-text">可以退款</p>
                        </div>
                    </div>
                </div>
                <button @click="buy" class="my-button">我知道了</button>
            </div>
        </Modal>
        <Modal ref="modal">
            <div class="look-tel">
                <img @click="$refs.modal.close()" class="close-icon" src="@/assets/img/z-icon9.png" alt="" >
                <p class="title">温馨提示</p>
                <p class="text">本次查看需要<span>{{ info.address_mobile_unlock }}</span>枚旺点币</p>
                <p class="text">当前剩余<span>{{ info.coins }}</span>枚</p>
                <div class="btns">
                    <button class="my-button" @click="getTel()">购买</button>
                    <button class="my-button plain" @click="$refs.modal.close()">返回</button>
                </div>
            </div>
        </Modal>
        <Modal ref="modal2">
            <div class="modal-offer">
                <img @click="$refs.modal2.close()" class="close-icon" src="@/assets/img/z-icon9.png" alt="" >
                <p class="title">出价</p>
                <div class="section2">
                    <div class="left style2">
                        <div class="left-item">
                            <p class="text">出售价(¥)</p>
                            <p class="text1"><span class="price">{{ info.price }}</span><span class="tip">（每次加价不得少于1元）</span></p>
                        </div>
                        <div class="left-item">
                            <p class="text">当前抢购价(¥)</p>
                            <p class="text1"><span class="price">{{ info.final_price }}</span><span class="tip">（共<b>{{ info.num }}</b>人参与抢购）</span></p>
                        </div>
                    </div>
                </div>
                <div class="offer-input">
                    <span>出价:</span>
                    <div class="input">
                        <span>￥</span>
                        <input v-model="offer" placeholder="请输入您的出价" type="text" />
                    </div>
                </div>
                <p class="offer-tip">需要账户余额￥{{ info.earnest_money }}作为保证金才可出价，恶意抢购将扣除保证金</p>
                <div class="btns">
                    <button class="my-button" @click="sendPrice">确认提交</button>
                    <button class="my-button plain" @click="$refs.modal2.close()">取消</button>
                </div>
            </div>
        </Modal>
        <Modal ref="modal3">
            <div class="my-prop">
                <img @click="$refs.modal3.close()" class="close-icon" src="@/assets/img/z-icon9.png" alt="" >
                <p class="title">道具列表</p>
                <div class="list">
                    <div
                        class="item"
                        v-for="item in propArr"
                        :key="item.id">
                        <div class="item-top">
                            <img :src="item.thumb" class="item-img" >
                            <div class="item-info">
                                <p class="item-title">{{ item.title }}</p>
                                <p class="item-price">{{ item.coins }}旺点币</p>
                                <p class="item-text">{{ item.types == 1 ? '增加' : '减少' }}{{ item.fiery }}火热值</p>
                            </div>
                        </div>
                        <button class="my-button item-btn" :ref="'propBtn' + item.id" @click="useProp(item.id)">立即购买</button>
                    </div>
                </div>
            </div>
        </Modal>
    </main>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Answers from './child/answers.vue'
import Evaluate from './child/evaluate.vue'
import { getUrlData } from '@/util/utils'
import { Message } from 'element-ui'
import { chain, multiply, padStart, reduce} from 'element-ui/src/utils/lodash';
export default {
    name: 'detail',
    components: { Modal, Answers, Evaluate },
    data () {
        return {
            id: null,
            publisher: {},
            list: [],
            info: {},
            daibanlist: [],
            count_down: 0,
            offer: '',
            countDown: {
                hours: 0,
                minutes: 0,
                seconds: 0
            },
            propArr: [],
            contentIndex: 0,
            contentArr: ['基本信息', '入驻信息', '税务信息', '地址展示']
        }
    },
    mounted () {
        this.id = parseInt(getUrlData().id)
        this.getDetail()
    },
    methods: {
        sendBuy () {
            if (!this.$store.state.token) {
                Message.error("请先登录")
                return
            }
            this.$refs.modal1.manageModal(true)
        },
        panicBuy () {
            if (this.info.is_count_down == 0) {
                Message.error("抢购时间未开始")
                return
            }
            this.$refs.modal2.manageModal(true)
        },
        useProp (id) {
            const loading = this.showLoading({
                target: '.my-prop'
            })
            const data = {
                token: this.$store.state.token,
                id: id,
                typeid: 1,
                common_id: this.id
            }
            this.$https.post("/api/Currency/props", data).then(() => {
                Message.success("道具使用成功")
                this.$refs.modal3.close()
            }).finally(() => {
                loading.close()
            })
        },
        clickGetTel () {
            if (!this.$store.state.token) {
                Message.error("请先登录")
                return
            }
            if (this.info.is_mobile == 0) {
                this.$refs.modal.manageModal(true)
            }
        },
        getTel () {
            if (this.info.coins < parseInt(this.info.address_mobile_unlock)) {
                location.href = "recharge.html"
                return
            }
            if (this.info.is_mobile == 1) {
                return
            }
            const data = {
                id: this.id,
                token: this.$store.state.token
            }
            this.$https.post("/api/Address/mobile_unlock", data).then(() => {
                Message.success("购买成功")
                this.getDetail()
                this.$refs.modal.close()
            })
        },
        toggleCollect () {
            const data = {
                address_id: this.id,
                token: this.$store.state.token,
                typeid: this.info.is_collect
            }
            this.$https.post("/api/Address/collect", data).then(res => {
                this.info.is_collect = res.data.is_collect
                Message.success(res.message)
            })
        },
        sendPrice () {
            const data = {
                address_id: this.id,
                token: this.$store.state.token,
                price: this.offer
            }
            this.$https.post("/api/Address/addflashsale", data).then(() => {
                this.getDetail()
                this.$refs.modal2.close()
                this.offer = ''
                Message.success("出价成功")
            })
        },
        buy () {
            location.href = `/address_bargain.html?common_id=${this.id}`
            // const loading = this.showLoading({
            //     target: '.look-notice .my-button'
            // })
            // const data = {
            //     token: this.$store.state.token,
            //     common_id: this.id,
            //     price_type: this.info.sell_model == 4 ? 2 : 1,
            //     status: this.info.sell_model == 2 || this.info.sell_model == 1 ? 2 : 1
            // }
            // this.$https.post("/api/Orders/addOrder", data).then(({ data }) => {
            //     location.href = '/address_bargain.html?id=' + data.order_id
            // }).finally(() => {
            //     loading.close()
            // })
        },
        link (id) {
            this.id = id
            this.getDetail()
            this.replaceLink({
                query: {
                    id: id
                }
            })
            document.documentElement.scrollTop = 0
        },
        changeTime (time) {
            let format = 'HH:mm:ss'
            const timeUnits = [
                ['Y', 1000 * 60 * 60 * 24 * 365], // years
                ['M', 1000 * 60 * 60 * 24 * 30], // months
                ['D', 1000 * 60 * 60 * 24], // days
                ['H', 1000 * 60 * 60], // hours
                ['m', 1000 * 60], // minutes
                ['s', 1000], // seconds
                ['S', 1] // million seconds
            ];
            let formatText = reduce(
                timeUnits,
                (con, item) => {
                const name = item[0];
                return con.replace(new RegExp(`${name}+`, 'g'), (match) => {
                    let sum = chain(time).divide(item[1]).floor(0).value();
                    time -= multiply(sum, item[1]);
                    return padStart(String(sum), String(match).length, 0);
                });
                },
                format
            );
            let timeArr = formatText.split(":")
            this.countDown = {
                hours: timeArr[0],
                minutes: timeArr[1],
                seconds: timeArr[2]
            }
        },
        getProp () {
            const data = {
                cat_id: 1
            }
            this.$https.post("/api/Currency/propslist", data).then(({ data }) => {
                this.propArr = data
            })
        },
        getDetail () {
            const loading = this.showLoading()
            const data = {
                id: this.id,
                token: this.$store.state.token
            }
            return new Promise((resolve, reject) => {
                this.$https.post("/api/Address/address_detail", data).then(({ data }) => {
                    this.info = data.info
                    this.list = data.list
                    this.publisher = data.publisher
                    this.daibanlist = data.daibanlist
                    if (data.info.sell_model == 4 && data.info.is_count_down == 0) {
                        this.count_down = Date.now() + ((data.info.start_count_down ) * 1000)
                    }
                    if (data.info.is_count_down == 1) {
                        this.count_down = Date.now() + (data.info.count_down * 1000)
                    }
                    // if (data.info.sell_model == 4) {
                    //     this.getProp()
                    // }
                    resolve()
                }).catch((res) => {
                    if (res.message === '地址详情不存在') {
                        setTimeout(() => {
                            location.href = '/'
                        }, 800)
                    }
                    reject()
                }).finally(() => {
                    loading.close()
                })
            })
        },
        scrollElement (element, reduceElement) {
            if (!element) return
            let el = document.querySelector(element)
            let elementTop = el.offsetTop
            let elementParent = el.offsetParent
            while (elementParent) {
                elementTop += elementParent.offsetTop;
                elementParent = elementParent.offsetParent;
            }
            if (reduceElement) {
                elementTop = elementTop - document.querySelector(reduceElement).offsetHeight
            }
            document.documentElement.scrollTo({
                top: elementTop,
                behavior: 'smooth'
            })
        },
        lookImg (url) {
            this.$alert('<img src=' + url + ' style="width: 100%;max-width:100%;max-height:100%" />', {
                dangerouslyUseHTMLString: true,
                showConfirmButton: false,
                customClass: 'my-look-img'
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>