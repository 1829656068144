<template>
    <main class="index">
        <section class="section1">
            <div class="index-container">
                <div class="top">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div
                                class="swiper-slide"
                                v-for="item,index in banner"
                                :key="index">
                                <img class="wh100" :src="item.picurl" alt="" >
                            </div>
                        </div>
                        <div class="swiper-button swiper-button-prev"></div>
                        <div class="swiper-button swiper-button-next"></div>
                        <div class="swiper-pagination"></div>
                    </div>
                    <user-card></user-card>
                </div>
                <div class="list">
                    <a
                        :href="'address.html?hottags=' + item.id"
                        class="list-item"
                        :style="`background-image: url(${baseUrl + item.bgpic});`"
                        v-for="item in hottagsArr"
                        :key="item.id">
                        <img class="item-img" style="opacity: 0;" :src="item.picurl" alt="" >
                        <p class="item-text">{{ item.title }}</p>
                    </a>
                </div>
            </div>
        </section>
        <section class="section2">
            <div class="index-container">
                <p class="index-title">
                    <span
                        class="index-title-item"
                        :class="{active: addressID == 0}"
                        @click="addressID = 0">最新地址转让信息</span>
                    <span class="black">|</span>
                    <span
                        class="index-title-item"
                        :class="{active: addressID == 1}"
                        @click="addressID = 1">热门地址转让信息</span></p>
                <ul class="line1">
                    <li>已有<b>{{ detail.total_address?.total_num }}</b>已成交地址</li>
                    <li>上月成交：<b>{{ detail.total_address?.month_num }}</b>个</li>
                    <li>昨日成交：<b>{{ detail.total_address?.day_num }}</b>个</li>
                </ul>
                <div class="list my-list1">
                    <a
                        :href="'/address_detail.html?id=' + item.id"
                        class="item"
                        v-for="item in addressID == 0 ? detail.new_address : detail.hot_address"
                        :key="item.id">
                        <div class="item-time">
                            <p class="item-day">{{ item.day }}</p>
                            <p class="item-date">{{ item.years }}</p>
                        </div>
                        <div class="item-info">
                            <div class="item-title my-hot">
                                <span class="label" :class="item.cat_id == 2 ? 'green' : 'red'">{{ item.cat_name }}</span>
                                <p class="text"><span v-if="item.address_sn">（编号：{{ item.address_sn }}）</span>{{ item.title }}</p>
                                <img class="icon" src="../assets/img/icon26.png" alt="" >
                                <span class="hot">火热值：{{ item.fiery  }}</span>
                            </div>
                            <p class="item-text">地址类别：<span>{{ item.cat_name  }}</span>&emsp;&emsp;期限：<span>{{ item.term  }}</span>&emsp;&emsp;地址性质：<span>{{ item.nature_name  }}</span></p>
                        </div>
                        <div class="item-prices">
                            <div class="item-price one" v-if="item.sell_model == 1 || item.sell_model == 2">
                                <p>{{ item.sell_name }}</p>
                            </div>
                            <div class="item-prices" v-if="item.sell_model == 4">
                                <div class="item-price two">
                                    <p>{{ item.price }}</p>
                                    <p>起购价(¥)</p>
                                </div>
                                <div class="item-price two">
                                    <p>{{ item.final_price }}</p>
                                    <p>当前最高(¥)</p>
                                </div>
                            </div>
                            <div class="item-price one" v-if="item.sell_model == 3">
                                <p>¥<span>{{ item.price }}</span></p>
                            </div>
                        </div>
                    </a>
                </div>
                <a href="/address.html" class="index-button">查看更多</a>
            </div>
        </section>
        <section class="section3">
            <div class="index-container">
                <p class="index-title">求购快讯</p>
                <div class="swiper-container swiper1">
                    <div class="swiper-wrapper">
                        <a
                            class="swiper-slide hover-color"
                            :href="'/buy_detail.html?id=' + item.id"
                            v-for="item in detail.new_wantbuy"
                            :key="item.id">
                            <p class="swiper-title ellipsis">{{ item.title }}</p>
                            <p class="swiper-address"><img src="../assets/img/icon27.png" alt="" >{{ item.city }}</p>
                            <p class="swiper-tel"><img src="../assets/img/icon28.png" alt="" >联系电话：<b>{{ item.mobile }}</b></p>
                        </a>
                    </div>
                </div>
                <a href="/buy.html" class="index-button">查看更多</a>
                <div class="swiper-container index-bottom-img" :class="{hover: detail.banner_one.length > 1}">
                    <img
                        v-for="item,index in detail.banner_one"
                        :key="index"
                        class="wh100"
                        :src="item.picurl"
                        alt="" >
                </div>
            </div>
        </section>
        <section class="section4 index-container">
            <p class="index-title">最新创业点子</p>
            <div class="list">
                <div class="list-item left">
                    <a
                        :href="'/syb_detail.html?id=' + item.id"
                        class="item hover-color"
                        v-for="item in detail.new_ideas?.slice(0, 5)"
                        :key="item.id">
                        <span class="item-label">{{ item.cat_name }}</span>
                        <div class="item-info">
                            <p class="item-title ellipsis">{{ item.title }}</p>
                            <p class="item-time">{{ item.update_time }}</p>
                        </div>
                    </a>
                </div>
                <div class="list-item right">
                    <a
                        :href="'/syb_detail.html?id=' + item.id"
                        class="item hover-color"
                        v-for="item in detail.new_ideas?.slice(5, 10)"
                        :key="item.id">
                        <span class="item-label">{{ item.cat_name }}</span>
                        <div class="item-info">
                            <p class="item-title ellipsis">{{ item.title }}</p>
                            <p class="item-time">{{ item.update_time }}</p>
                        </div>
                    </a>
                </div>
            </div>
            <a href="/syb.html" class="index-button">查看更多</a>
            <div class="swiper-container index-bottom-img" :class="{hover: detail.banner_two.length > 1}">
                <img
                    v-for="item,index in detail.banner_two"
                    :key="index"
                    class="wh100"
                    :src="item.picurl"
                    alt="" >
            </div>
        </section>
        <section class="section5 index-container">
            <p class="index-title">新闻动态</p>
            <ul class="select">
                <li
                    :class="{active: cateIndex == index}"
                    @click="cateIndex = index"
                    v-for="item,index in detail.news_cate"
                    :key="item.id">{{ item.name }}</li>
            </ul>
            <div class="list">
                <a
                    class="item"
                    :href="'/new_detail.html?id=' + item.id"
                    v-for="item in detail.news_cate[cateIndex]?.news"
                    :key="item.id">
                    <img class="item-img" :src="item.picurl" alt="" >
                    <div class="item-info">
                        <p class="item-time">{{ item.add_time }}</p>
                        <p class="item-title ellipsis">{{ item.title }}</p>
                        <p class="item-text ellipsis line2">{{ item.description }}</p>
                        <p class="item-more">查看详情<img src="../assets/img/icon29.png" ></p>
                    </div>
                </a>
            </div>
            <a href="/news.html" class="index-button">查看更多</a>
        </section>
        <section class="section6">
            <div class="index-container">
                <p class="title">转让经营地址城市直达：</p>
                <ul>
                    <li
                        v-for="item,index in detail.address_city"
                        :key="index"><a :href="`address.html?province=${item.province_id}&city=${item.city_id}`">{{item.title}}转让经营地址</a></li>
                </ul>
                <div class="list">
                    <div class="item">
                        <img class="item-img" src="../assets/img/img7.jpg" alt="" >
                        <div class="item-info">
                            <p>经营性网站</p>
                            <p>备案信息</p>
                        </div>
                    </div>
                    <div class="item">
                        <img class="item-img" src="../assets/img/img8.jpg" alt="" >
                        <div class="item-info">
                            <p>不良信息</p>
                            <p>举报中心</p>
                        </div>
                    </div>
                    <div class="item">
                        <img class="item-img" src="../assets/img/img9.jpg" alt="" >
                        <div class="item-info">
                            <p>网警110</p>
                            <p>报警服务</p>
                        </div>
                    </div>
                    <div class="item">
                        <img class="item-img" src="../assets/img/img10.jpg" alt="" >
                        <div class="item-info">
                            <p>互联网协会</p>
                            <p>网络诚信联盟</p>
                        </div>
                    </div>
                    <div class="item">
                        <img class="item-img" src="../assets/img/img11.jpg" alt="" >
                        <div class="item-info">
                            <p>AA级</p>
                            <p>信用企业</p>
                        </div>
                    </div>
                    <div class="item">
                        <img class="item-img" src="../assets/img/img12.jpg" alt="" >
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import userCard from '@/components/userCard.vue'
export default {
    name: "Home",
    components: { userCard },
    data () {
        return {
            detail: {
                news_cate: [],
                banner_one: [],
                banner_two: []
            },
            addressID: 0,
            addressArr: {},
            cateIndex: 0,
            banner: [],
            hottagsArr: []
        }
    },
    mounted () {
        this.getData()
        this.getBanner()
    },
    methods: {
        getBanner () {
            const data = {
                is_pc: 1
            }
            this.$https.post("/api/Currency/banner", data).then(({ data }) => {
                this.banner = data
                this.$nextTick(() => {
                    new Swiper(".index .section1 .swiper-container", {
                        speed: 1000,
                        autoplay: {
                            delay: 3000,
                            disableOnInteraction: false
                        },
                        loop: true,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        pagination: {
                            el: ".swiper-pagination"
                        }
                    })
                })
            })
        },
        getData () {
            const loading = this.showLoading()
            const data = {
                is_pc: 1
            }
            this.$https.post("/api/Index/index", data).then(({ data }) => {
                this.detail = data
                this.cateID = data.news_cate[0].id
                this.addressArr = {
                    0: data.new_address,
                    1: data.hot_address
                }
                this.$nextTick(() => {
                    new Swiper(".index .section3 .swiper-container.swiper1", {
                        slidesPerView: 6,
                        loop: data.new_wantbuy.length > 6 ? true : false,
                        speed: 3000,
                        direction: 'vertical',
                        autoplay: {
                            delay: 0,
                            disableOnInteraction: false
                        }
                    })
                    // new Swiper(".index .index-bottom-img", {
                    //     loop: true,
                    //     speed: 3000,
                    //     direction: 'vertical',
                    //     autoplay: {
                    //         delay: 0,
                    //         disableOnInteraction: false
                    //     }
                    // })
                })
            }).finally(() => {
                loading.close()
            })
            this.$https.post("/api/Currency/categorylist", { typeid: 4 }).then(({ data }) => {
                this.hottagsArr = data
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>