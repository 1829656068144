<template>
    <main class="address my-background">
        <section class="section1 index-container my-screen">
            <div class="screen-item">
                <p class="label"><span>地址类别</span>:</p>
                <ul>
                    <li
                        v-for="item in catArr"
                        :class="{active: item.id == sendData.cat_id}"
                        @click="link('cat_id', item.id)"
                        :key="item.id"><a>{{ item.name }}</a></li>
                </ul>
                <span class="screen-toggle" @click="screenToggle($event)">展开<img src="@/assets/img/icon34.png"></span>
            </div>
            <div class="screen-item" v-if="sendData.province == 0">
                <p class="label"><span>地区</span>:</p>
                <ul>
                    <li
                        v-for="item in provinceArr"
                        :class="{active: item.id == sendData.province}"
                        @click="link('province', item.id)"
                        :key="item.id"><a>{{ item.name }}</a></li>
                </ul>
                <span class="screen-toggle" @click="screenToggle($event)">展开<img src="@/assets/img/icon34.png"></span>
            </div>
            <div class="screen-item" v-if="sendData.province != 0 && sendData.city == 0">
                <p class="label"><span>地区</span>:</p>
                <ul>
                    <li
                        v-for="item in cityArr"
                        :class="{active: item.id == sendData.city}"
                        @click="link('city', item.id)"
                        :key="item.id"><a>{{ item.name }}</a></li>
                </ul>
                <span class="screen-toggle" @click="screenToggle($event)">展开<img src="@/assets/img/icon34.png"></span>
            </div>
            <div class="screen-item" v-if="sendData.city != 0">
                <p class="label"><span>地区</span>:</p>
                <ul>
                    <li
                        v-for="item in countyArr"
                        :class="{active: item.id == sendData.district}"
                        @click="link('district', item.id)"
                        :key="item.id"><a>{{ item.name }}</a></li>
                </ul>
                <span class="screen-toggle" @click="screenToggle($event)">展开<img src="@/assets/img/icon34.png"></span>
            </div>
            <div class="screen-item">
                <p class="label"><span>价格</span>:</p>
                <ul>
                    <li
                        v-for="item in priceArr"
                        :class="{active: item.id == sendData.price_id}"
                        @click="link('price_id', item.id)"
                        :key="item.id"><a>{{ item.title }}</a></li>
                </ul>
                <span class="screen-toggle" @click="screenToggle($event)">展开<img src="@/assets/img/icon34.png"></span>
            </div>
            <div class="screen-item">
                <p class="label"><span>地址性质</span>:</p>
                <ul>
                    <li
                        v-for="item in natureArr"
                        :class="{active: item.id == sendData.nature_id}"
                        @click="link('nature_id', item.id)"
                        :key="item.id"><a>{{ item.title }}</a></li>
                </ul>
                <span class="screen-toggle" @click="screenToggle($event)">展开<img src="@/assets/img/icon34.png"></span>
            </div>
            <div class="screen-item">
                <p class="label"><span>热门标签</span>:</p>
                <ul>
                    <li
                        v-for="item in hottagsArr"
                        :class="{active: item.id == sendData.hottags}"
                        @click="link('hottags', item.id)"
                        :key="item.id"><a>{{ item.title }}</a></li>
                </ul>
                <span class="screen-toggle" @click="screenToggle($event)">展开<img src="@/assets/img/icon34.png"></span>
            </div>
            <div class="screen-item">
                <p class="label"><span>购买模式</span>:</p>
                <ul>
                    <li
                        v-for="item in sellArr"
                        :class="{active: item.id == sendData.sell_model}"
                        @click="link('sell_model', item.id)"
                        :key="item.id"><a>{{ item.name }}</a></li>
                </ul>
                <span class="screen-toggle" @click="screenToggle($event)">展开<img src="@/assets/img/icon34.png"></span>
            </div>
        </section>
        <section class="section2 index-container my-sort">
            <div class="left">
                <div
                    class="item"
                    :class="{active: item.id == sendData.sort && sendData.sort_order == 1}"
                    @click="toggle(item.id)"
                    v-for="item in sortData"
                    :key="item.id">
                    <span>{{ item.name }}</span>
                    <img src="../../assets/img/icon35.png" alt="" >
                </div>
            </div>
            <span @click="linkRelease" class="right">没找到想要的？ 发布求购</span>
        </section>
        <section class="section3 index-container">
            <div class="my-list1">
                <a
                    :href="'address_detail.html?id=' + item.id"
                    class="item"
                    v-for="item in list"
                    :key="item.id">
                    <div class="item-time">
                        <p class="item-day">{{ item.day }}</p>
                        <p class="item-date">{{ item.years }}</p>
                    </div>
                    <div class="item-info">
                        <div class="item-title my-hot">
                            <span class="label" :class="item.cat_id == 2 ? 'green' : 'red'">{{ item.cat_name }}</span>
                            <p class="text"><span v-if="item.address_sn">（编号：{{ item.address_sn }}）</span>{{ item.title }}</p>
                            <img class="icon" src="../../assets/img/icon26.png" alt="" >
                            <span class="hot">火热值：{{ item.fiery  }}</span>
                        </div>
                        <p class="item-text">地址类别：<span>{{ item.cat_name  }}</span>&emsp;&emsp;期限：<span>{{ item.term  }}</span>&emsp;&emsp;地址性质：<span>{{ item.nature_name  }}</span></p>
                    </div>
                    <div class="item-prices">
                        <div class="item-price one" v-if="item.sell_model == 1 || item.sell_model == 2">
                            <p>{{ item.sell_name }}</p>
                        </div>
                        <div class="item-prices" v-if="item.sell_model == 4">
                            <div class="item-price two">
                                <p>{{ item.price }}</p>
                                <p>起购价(¥)</p>
                            </div>
                            <div class="item-price two">
                                <p>{{ item.final_price }}</p>
                                <p>当前最高(¥)</p>
                            </div>
                        </div>
                        <div class="item-price one" v-if="item.sell_model == 3">
                            <p>¥<span>{{ item.price }}</span></p>
                        </div>
                    </div>
                </a>
            </div>
            <myPagination
                :total="total"
                :pageSize="pageSize"
                class="pagination"
                v-model="sendData.page"></myPagination>
        </section>
    </main>
</template>

<script>
import myPagination from '@/components/myPagination.vue'
import Bus from '@/util/bus'
import { getUrlData, getRegionList } from '@/util/utils'
import { Message } from 'element-ui'
export default {
    name: 'index',
    components: { myPagination },
    data () {
        return {
            pageSize: 10,
            page: 1,
            catArr: [],
            provinceArr: [],
            priceArr: [],
            cityArr: [],
            countyArr: [],
            natureArr: [],
            hottagsArr: [],
            sellArr: [],
            sortData: [
                {
                    id: 1,
                    name: '按时间排序'
                },{
                    id: 2,
                    name: '按热度排序'
                }
            ],
            sendData: {
                keyword: '',
                cat_id: 0,
                province: 0,
                city: 0,
                district: 0,
                price_id: 0,
                nature_id: 0,
                hottags: 0,
                sell_model: 0,
                sort: 2,
                sort_order: 1,
                page: 1
            },
            total: 0,
            list: []
        }
    },
    async created () {
        let query = getUrlData()
        for (const key in query) {
            if (Object.hasOwnProperty.call(query, key)) {
                const element = query[key];
                if (key == 'keyword') {
                    this.sendData[key] = decodeURI(element)
                } else {
                    this.sendData[key] = parseInt(element)
                }
                if (key === 'province') {
                    this.cityArr = await getRegionList(parseInt(element), 2)
                    this.cityArr.unshift({ id: 0, name: "不限" })
                }
                if (key === 'city') {
                    this.countyArr = await getRegionList(parseInt(element), 3)
                    this.countyArr.unshift({ id: 0, name: "不限" })
                }
            }
        }
        this.getList()
    },
    mounted () {
        Bus.$on('addressScreen', (res) => {
            this.catArr = [{id: 0, name: '不限'}, ...res[0].data] 
            this.sellArr = [{id: 0, name: '不限'}, ...res[1].data] 
            this.priceArr =[{id: 0, title: '不限'}, ...res[2].data] 
            this.natureArr = [{id: 0, title: '不限'}, ...res[3].data] 
            this.hottagsArr = [{id: 0, title: '不限'}, ...res[4].data]
            this.provinceArr = res[5]
            this.$nextTick(() => {
                this.randerToggle()
            })
        })
        Bus.$on('addressLink', (data) => {
            this.link(data.type, data.id)
        })
    },
    methods: {
        toggle (id) {
            if (this.sendData.sort == id) {
                this.sendData.sort_order = this.sendData.sort_order == 1 ? 2 : 1
            } else {
                this.sendData.sort = id
                this.sendData.sort_order = 1
            }
            if (this.sendData.page == 1) {
                this.getList()
            } else {
                this.sendData.page = 1
            }
        },
        getList () {
            const loading = this.showLoading({
                target: '.address .section3'
            })
            const data = this.sendData
            this.$https.post("/api/Address/list", data).then(res => {
                this.total = res.count
                this.list = res.data
                this.pageSize = res.limit
            }).finally(() => {
                loading.close()
            })
        },
        async link (type, id) {
            if (type === 'province') {
                this.cityArr = await getRegionList(id, 2)
                this.cityArr.unshift({ id: 0, name: "不限" })
                this.$nextTick(() => {
                    this.randerToggle()
                })
            }
            if (type === 'city') {
                this.countyArr = await getRegionList(id, 3)
                this.countyArr.unshift({ id: 0, name: "不限" })
                this.$nextTick(() => {
                    this.randerToggle()
                })
            }
            const data = {}
            data[type] = id
            this.sendData[type] = id
            this.getList()
            // this.replaceLink({
            //     query: {...getUrlData(), ...data}
            // })
        },
        screenToggle (e) {
            let that = e.currentTarget
            let parent = that.parentNode
            parent.classList.toggle("screen-toggle-active")
            if (that.innerHTML.includes('展开')) {
                that.innerHTML = that.innerHTML.replace('展开', '收起')
            } else {
                that.innerHTML = that.innerHTML.replace('收起', '展开')
            }
        },
        randerToggle () {
            let screenItems = Array.prototype.slice.call(document.querySelectorAll(".address .my-screen .screen-item"))
            screenItems.map(item => {
                let screenItemUl = item.querySelector("ul")
                if (screenItemUl.offsetHeight > 38) {
                    item.classList.add("toggle-active")
                } else {
                    item.classList.remove("toggle-active")
                }
            })
        },
        linkRelease () {
            if (this.$store.state.token) {
                location.href = 'buy_release.html'
            } else {
                Message.error("请先登录")
            }
        }
    },
    watch: {
        'sendData.page' () {
            this.getList()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>