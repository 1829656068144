<template>
    <main class="bargain my-background">
        <section class="section1 index-container">
            <div class="crumbs">
                <img src="../../../assets/img/z-icon1.png">
                当前所在位置：<a href="/">旺点来</a> > <a href="address.html">找地址</a> > <a :href="'/address_detail.html?id=' + address_info.id">{{ address_info.title }}</a> > <a class="active">发起交易</a>
            </div>
        </section>
        <section class="section2 index-container">
            <div class="select">
                <span
                    class="select-item"
                    :class="{active: index == renderIndex}"
                    :key="index"
                    @click="renderIndex = index"
                    v-for="item,index in renderArr">{{ item.title }}</span>
            </div>
            <div class="list my-list3">
                <template v-for="item,index in renderArr[renderIndex].list">
                    <div class="item" :key="index">
                        <div class="item-container">
                            <div class="item-content">{{ index + 1 }}</div>
                        </div>
                        <p class="item-text">{{ item }}</p>
                    </div>
                    <span :key="index + renderArr[renderIndex].list.length" v-if="index + 1 !== renderArr[renderIndex].list.length"></span>
                </template>
            </div>
        </section>
        <section class="section3 index-container">
            <div class="top">
                <div class="title">
                    <p class="title-text1" v-if="detail.status < 4">确认信息</p>
                    <p class="title-text1" v-else-if="detail.status == 4 && detail.pay_status == 1">订单支付</p>
                    <p class="title-text1" v-else>订单号：{{ detail.order_sn }}</p>
                    <template v-if="type == 0">
                        <p class="title-text2" v-if="detail.status == 4 && detail.pay_status == 3">您已支付成功，请等待卖家发货</p>
                        <p class="title-text2" v-if="detail.status == 5 || detail.status == 6">商家已发货，请确认收货</p>
                        <p class="title-text2" v-if="detail.status == 7 || detail.status == 8">{{ evaluateStatus ? '订单已完成' : '订单已完成，请评价' }}</p>
                    </template>
                    <template v-if="type == 1">
                        <p class="title-text2" v-if="detail.status == 4 && detail.pay_status == 3">买家已支付订单，请尽快发货</p>
                        <p class="title-text2" v-if="detail.status == 5 || detail.status == 6">您已发货，请等待买家确认收货</p>
                        <p class="title-text2" v-if="detail.status == 7 || detail.status == 8">{{ evaluateStatus ? '订单已完成' : '订单已完成，请评价' }}</p>
                    </template>
                    <p class="title-text2" v-if="detail.status == 9">已申请退款，等待客服核实</p>
                    <p class="title-text2" v-if="detail.status == 10">已成功退款，请查收余额</p>
                </div>
                <p class="tip" @click="$refs.modal.manageModal(true)"><img src="../../../assets/img/icon45.png" alt="" ><span>交易须知</span></p>
            </div>
            <div class="list" v-if="detail.status == 4 && (detail.pay_status == 1 || detail.pay_status == 2)">
                <p class="item">订单号：{{ detail.order_sn }}</p>
                <p class="item">实付金额：&ensp;<b class="color">¥{{ detail.price }}</b></p>
                <div class="item buy">
                    <span>支付方式：&ensp;</span>
                    <ul>
                        <li
                            v-for="item in buyArr"
                            :key="item.id"
                            @click="buyId = item.id"
                            :class="{active: item.id == buyId}">
                            <img :src="item.icon" alt="" >
                            <span>{{ item.title }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="middle" v-else>
                <div class="item">
                    <span class="info">地址信息</span>
                    <span class="tel">卖家电话</span>
                    <span class="price">出售价</span>
                </div>
                <div class="item">
                    <div class="info">
                        <p class="info-title">{{ address_info.title }}</p>
                        <p class="info-text">地址类别：<span>{{ address_info.cat_name }}</span>&emsp;&emsp;期限：<span>{{ address_info.term }}</span>&emsp;&emsp;地址性质：<span>{{ address_info.nature_name }}</span></p>
                    </div>
                    <span class="tel">{{ address_info.mobile }}</span>
                    <span class="price active" v-if="detail.status == 2"><span>{{ address_info.sell_model == 1 ? '免费转' : '面议' }}</span><span class="tip" @click="notice">通知卖家改价</span></span>
                    <span class="price" v-else>{{ detail.price }}</span>
                </div>
            </div>
            
            <div class="evaluate" v-if="detail.status == 7 || detail.status == 8">
                <div class="item">
                    <span>{{ evaluateStatus ? '评分' : '请进行评分'}}：</span>
                    <el-rate :disabled="evaluateStatus" v-model="evaluateData.stars"></el-rate>
                </div>
                <div class="item">
                    <span>{{ evaluateStatus ? '评价' : '请输入评价' }}：</span>
                    <textarea :disabled="evaluateStatus" v-model="evaluateData.content" placeholder="请输入评价内容..."></textarea>
                </div>
            </div>
            <div class="bottom">
                <p class="price" v-if="detail.status != 2 && detail.status != 3">实付金额：<span class="color">¥{{ detail.price }}</span></p>
                <p class="price" v-if="detail.status == 2">实付金额：<span class="red">卖家还没有改价，请通知卖家改价</span></p><!-- 发起交易 --> 
                <div class="btns" v-if="detail.status == 1 || detail.status == 2 || detail.status == 3"><!-- 发起交易 --> 
                    <button class="my-button plain" @click="back">取消交易</button>
                    <button v-if="detail.sell_model == 3 || detail.sell_model == 4" class="my-button" @click="submitOrder" ref="submitOrderBtn">提交订单</button>
                </div>
                <div class="btns" v-if="detail.status == 4 && detail.pay_status == 1"><!-- 发起交易-订单支付 --> 
                    <button class="my-button" ref="buyBut" @click="buy">立即付款</button>
                </div>
                <template v-if="type == 0">
                    <div class="btns" v-if="detail.status == 4 && detail.pay_status == 3"><!-- 发起交易-待发货 --> 
                        <button class="my-button" @click="refund">申请退款</button>
                    </div>
                    <div class="btns" v-if="detail.status == 5 || detail.status == 6"><!-- 发起交易-待收货 --> 
                        <button class="my-button plain" ref="endBut" @click="submitEnd">确认收货</button>
                        <button class="my-button" @click="refund">申请退款</button>
                    </div>
                    <div class="btns" v-if="detail.status == 9"><!-- 发起交易-退款中 -->
                        <button class="my-button" @click="unRefund">取消申请</button>
                    </div>
                </template>
                <template v-if="type == 1">
                    <div class="btns" v-if="detail.status == 4 && detail.pay_status == 3"><!-- 发起交易-待发货 --> 
                        <button class="my-button" @click="delivery">确认发货</button>
                    </div>
                </template>
                <div class="btns" v-if="(detail.status == 7 || detail.status == 8) && !evaluateStatus"><!-- 发起交易-已完成 -->
                    <button class="my-button" ref="evaluateBtn" @click="submitEvaluate">确认评价</button>
                </div>
            </div>
        </section>
        <Modal ref="modal">
            <div class="look-notice">
                <img @click="$refs.modal.close()" class="close-icon" src="../../../assets/img/z-icon9.png" alt="" >
                <p class="title">交易须知</p>
                <div class="list">
                    <div class="item">
                        <img class="item-icon" src="../../../assets/img/icon42.png" alt="">
                        <div class="item-info">
                            <p class="item-title">如何收费？</p>
                            <p class="item-text">交易成功后收取卖方转让款的1%作为服务费（交易完成时扣除），不收取买方费用。</p>
                        </div>
                    </div>
                    <div class="item">
                        <img class="item-icon" src="../../../assets/img/icon42.png" alt="">
                        <div class="item-info">
                            <p class="item-title">为什么要选择在线付款交易？</p>
                            <p class="item-text">线上的每一笔交易都将受到平台保护，待变更手续办理结束后才会打款给卖家。</p>
                        </div>
                    </div>
                    <div class="item">
                        <img class="item-icon" src="../../../assets/img/icon42.png" alt="">
                        <div class="item-info">
                            <p class="item-title">卖方毁约，线上支付的地址转让款能不能退款？</p>
                            <p class="item-text">可以退款</p>
                        </div>
                    </div>
                </div>
                <button @click="$refs.modal.close()" class="my-button">我知道了</button>
            </div>
        </Modal>
    </main>
</template>

<script>
import Modal from '@/components/Modal.vue'
import { getUrlData } from '@/util/utils';
import { Message } from 'element-ui';
export default {
    name: 'bargain',
    components: { Modal },
    data () {
        return {
            id: null,
            common_id: null,
            detail: {},
            address_info: {},
            value1: 0,
            renderIndex: 0,
            renderArr: [
                {
                    title: '交易流程',
                    list: ['发起交易', '通知卖家改价', '卖家修改价格', '在线支付', '卖家发货', '买家确认收货', '订单完成', '双方评价']
                },{
                    title: '退款流程',
                    list: ['申请退款', '等待平台核实', '退款通过', '余额提现', '完成']
                }
            ],
            buyId: 1,
            buyArr: [
                {
                    title: '支付宝支付',
                    icon: require('@/assets/img/z-icon5.png'),
                    id: 1
                },{
                    title: '微信支付',
                    icon: require('@/assets/img/z-icon6.png'),
                    id: 2
                },{
                    title: '余额支付',
                    icon: require('@/assets/img/z-icon7.png'),
                    id: 3
                }
            ],
            checkPayStatusTimer: null,
            evaluateStatus: false,
            evaluateData: {
                stars: 0,
                content: ''
            },
            type: 0, // 0: 买家,1: 卖家
            typeName: 'buyer'
        }
    },
    mounted () {
        let query = getUrlData()
        if (query.type) {
            this.type = parseInt(query.type)
            if (this.type == 1) {
                this.typeName = 'seller'
            }
        }
        this.id = parseInt(query.id) || null
        this.common_id = parseInt(query.common_id) || null
        if (this.id) {
            this.getDetail()
        }
        if (this.common_id) {
            this.getAddressDetail()
        }
    },
    methods: {
        delivery () {
            const loading = this.showLoading()
            const data = {
                token: this.$store.state.token,
                order_id: this.id
            }
            this.$https.post("/api/Orders/submitSendGoods", data).then(() => {
                Message.success('确认发货成功')
                this.detail.status = 5
            }).finally(() => {
                loading.close()
            })
        },
        unRefund () {
            const loading = this.showLoading()
            const data = {
                token: this.$store.state.token,
                order_id: this.id
            }
            this.$https.post("/api/Orders/unapplyBackMoney", data).then(() => {
                Message.success('取消申请成功')
            }).finally(() => {
                loading.close()
            })
        },
        refund () {
            const loading = this.showLoading()
            const data = {
                token: this.$store.state.token,
                order_id: this.id
            }
            this.$https.post("/api/Orders/applyBackMoney", data).then(() => {
                Message.success('申请成功')
                this.detail.status = 9
            }).finally(() => {
                loading.close()
            })
        },
        submitEvaluate () {
            const data = {
                token: this.$store.state.token,
                order_id: this.id,
                type: this.type == 0 ? 1 : 2,
                stars: this.evaluateData.stars,
                content: this.evaluateData.content
            }
            const loading = this.showLoading()
            this.$https.post("/api/Orders/evaluate", data).then((res) => {
                Message.success(res.message)
                this.evaluateStatus = true
                setTimeout(() => {
                    location.href = '/'
                }, 800)
            }).finally(() => {
                loading.close()
            })
        },
        submitEnd () {
            const data = {
                token: this.$store.state.token,
                order_id: this.id
            }
            const loading = this.showLoading()
            this.$https.post("/api/Orders/submitEnd", data).then(() => {
                this.detail.status = 7
            }).finally(() => {
                loading.close()
            })
        },
        buy () {
            const loading = this.showLoading()
            const orderData = {
                token: this.$store.state.token,
                order_id: this.id
            }
            if (this.buyId == 1) {
                this.$https.post("/api/Alipay/pay2", orderData).then((res) => {
                    window.open(res)
                }).finally(() => {
                    loading.close()
                })
            } else if (this.buyId == 2) {
                const data = orderData
                data.type = 1
                this.$https.post("/api/Weixinpay/pcPay", data).then(({ data }) => {
                    this.$alert('<img src=' + data.url + ' style="width: 100%;max-width:100%;max-height:100%" />', {
                        dangerouslyUseHTMLString: true,
                        showConfirmButton: false,
                        customClass: 'weixin-alter'
                    })
                }).finally(() => {
                    loading.close()
                })
            } else if (this.buyId == 3) {
                this.$https.post("/api/Orders/balancePay", orderData).then(() => {
                    Message.success("支付成功")
                    this.detail.pay_status = 3
                }).finally(() => {
                    loading.close()
                })
            }
        },
        submitOrder () {
            // if (this.detail.status == 2) {
            //     Message.error("联系卖家改价")
            //     return
            // }
            // const data = {
            //     token: this.$store.state.token,
            //     order_id: this.id
            // }
            // this.$https.post("/api/Orders/submitGoPay", data).then(() => {
            //     Message.success('提交订单成功')
            //     this.detail.status = 4
            // })
            const loading = this.showLoading({
                target: this.$refs.submitOrderBtn
            })
            const data = {
                token: this.$store.state.token,
                common_id: this.common_id,
                price_type: this.detail.sell_model == 3 ? 1 : 2,
                status: 4
            }
            this.$https.post("/api/Orders/addOrder", data).then(({ data }) => {
                this.id = data.order_id
                const data1 = {
                    token: this.$store.state.token,
                    order_id: data.order_id
                }
                this.$https.post("/api/Orders/submitGoPay", data1).then(() => {
                    Message.success('提交订单成功')
                    // this.getDetail()
                    location.replace(`address_bargain.html?id=${data.order_id}`)
                })
            }).finally(() => {
                loading.close()
            })
        },
        checkPayStatus () {
            const data = {
                token: this.$store.state.token,
                order_id: this.id
            }
            this.checkPayStatusTimer = setInterval(() => {
                this.$https.post("/api/Weixinpay/pay_status_check", data).then(({ data }) => {
                    // if (res == 3) {
                    //     clearInterval(this.checkPayStatusTimer)
                    //     this.checkPayStatusTimer = null
                    //     this.detail.status = 5
                    // }
                    if (data.status == 3) {
                        this.detail.price = data.price
                    }
                    if (data.status == 4 && data.pay_status == 3 && this.buyId == 2) {
                        const alert = document.querySelector(".weixin-alter")
                        const alert_btn = alert.querySelector(".el-message-box__headerbtn")
                        if (alert_btn) {
                            alert_btn.click()
                        }
                    }
                    let arr = ['status', 'pay_status', 'is_buyer_pingjia', 'is_seller_pingjia', 'buyer_comment_stars', 'seller_comment_stars', 'buyer_comment_content', 'seller_comment_content']
                    arr.map(item => {
                        if (data[item] !== this.detail[item]) {
                            this.detail[item] = data[item]
                        }
                    })
                    if (this.type == 0 && data.is_buyer_pingjia == 1) {
                        this.evaluateStatus = true
                        this.evaluateData = {
                            stars: data.buyer_comment_stars,
                            content: data.buyer_comment_content
                        }
                    }
                    if (this.type == 1 && data.is_seller_pingjia == 1) {
                        this.evaluateStatus = true
                        this.evaluateData = {
                            stars: data.seller_comment_stars,
                            content: data.seller_comment_content
                        }
                    }
                })
            }, 5000)
        },
        notice () {
            // const data = {
            //     token: this.$store.state.token,
            //     order_id: this.id
            // }
            // this.$https.post("/api/Orders/submitChangePirce", data).then(() => {
            //     Message.error("联系卖家改价")
            // })
        },
        back () {
            // const loading = this.showLoading()
            // const data = {
            //     token: this.$store.state.token,
            //     order_id: this.id
            // }
            // this.$https.post("/api/Orders/unOrder", data).then(() => {
            //     Message.success("取消订单成功")
            //     setTimeout(() => {
            //         window.history.back()
            //     }, 800)
            // }).finally(() => {
            //     loading.close()
            // })
            window.history.back()
        },
        getDetail () {
            const data = {
                token: this.$store.state.token,
                order_id: this.id
            }
            this.$https.post("/api/Orders/order_confirm", data).then(({ data }) => {
                this.detail = data
                this.address_info = data.address_info
                if (this.type == 0 && data.is_buyer_pingjia == 1) {
                    this.evaluateStatus = true
                    this.evaluateData = {
                        stars: data.buyer_comment_stars,
                        content: data.buyer_comment_content
                    }
                }
                if (this.type == 1 && data.is_seller_pingjia == 1) {
                    this.evaluateStatus = true
                    this.evaluateData = {
                        stars: data.seller_comment_stars,
                        content: data.seller_comment_content
                    }
                }
                this.checkPayStatus()
            })
        },
        getAddressDetail () {
            const loading = this.showLoading()
            const data = {
                id: this.common_id,
                token: this.$store.state.token
            }
            this.$https.post("/api/Address/address_detail", data).then(({ data }) => {
                let info = data.info
                let detail = {
                    status: info.sell_model <= 2 ? 2 : 1,
                    pay_status: 1,
                    price: info.price,
                    sell_model: info.sell_model
                }
                this.detail = detail
                this.address_info = info
            }).finally(() => {
                loading.close()
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>