<template>
  <div class="release my-background">
    <section class="section1 index-container">
      <Crumbs></Crumbs>
    </section>
    <section class="section3 index-container my-form">
      <p class="top-title">
        发布创业点子&emsp;&emsp;<span
          >发布创业点子，热度高的点子可获得丰富收益</span
        >
      </p>
      <div class="item">
        <div class="item-title">
          <span>基础信息</span>
        </div>
        <div class="form">
          <div class="form-item">
            <div class="form-left tip">点子名称：</div>
            <div class="form-right">
              <el-input
                v-model="form.title"
                placeholder="请输入"
                style="width: 340px"
              ></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">点子类型：</div>
            <div class="form-right">
              <el-checkbox-group @change="handleChange" v-model="dzlist">
                <el-checkbox
                  v-for="item in dztype"
                  :key="item.id"
                  :label="item.id"
                  name="type"
                  >{{ item.title }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">行业类别：</div>
            <div class="form-right">
              <el-checkbox-group @change="handleChange1" v-model="hylist">
                <el-checkbox
                  v-for="item in dztype1"
                  :key="item.id"
                  :label="item.id"
                  name="type"
                  >{{ item.title }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
          <!-- <div class="form-item">
            <div class="form-left tip">解锁金额：</div>
            <div class="form-right">
              <el-input placeholder="请输入" style="width: 340px"></el-input>
            </div>
          </div> -->
          <div class="form-item">
            <div class="form-left tip">点子内容：</div>
            <div class="form-right">
              <div style="border: 1px solid #ccc">
                <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editor"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
                />
                <Editor
                  style="height: 500px; overflow-y: hidden"
                  v-model="html"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onCreated"
                />
              </div>
              <!-- <el-input
                v-model="form.content"
                type="textarea"
                rows="6"
                resize="none"
                placeholder="请输入"
              ></el-input> -->
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="item-title">
          <span>联系方式</span>
        </div>
        <div class="form">
          <div class="form-item">
            <div class="form-left tip">联系电话：</div>
            <div class="form-right">
              <el-input
                v-model="form.mobile"
                placeholder="请输入手机号"
              ></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-left tip">联系人：</div>
            <div class="form-right">
              <el-input
                v-model="form.contacts"
                placeholder="请输入联系人"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="item submit">
        <div class="my-radio">
          <input v-model="xy" type="checkbox" id="item1" name="type" />
          <div class="checked"></div>
          <label for="item1"
            >我已阅读并同意<a target="_blank" href="about.html?id=1" class="xy"
              >《旺点来转让协议》</a
            ></label
          >
        </div>
        <button @click="handleFb()" class="my-button">立即发布</button>
      </div>
    </section>
    <Modal ref="modal">
      <div class="release-modal">
        <img
          @click="$refs.modal.close()"
          class="close-icon"
          src="@/assets/img/z-icon9.png"
          alt=""
        />
        <img class="icon" src="@/assets/img/icon46.png" alt="" />
        <p>发布成功，请等待审核</p>
        <p>您还可以</p>
        <div class="btns">
          <button class="my-button" @click="$refs.modal.close()">
            继续发布
          </button>
          <button class="my-button">
            <a :href="'personal.html?id=' + 3 + '&status=' + 0 + '&type=' + 1"
              >查看审核</a
            >
          </button>
          <button class="my-button">
            <a href="syb.html">返回列表</a>
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { Message } from "element-ui";
import { getUrlData } from "@/util/utils";

import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css";
// <style src="@wangeditor/editor/dist/css/style.css"></style>
export default {
  data() {
    return {
      value1: null,
      value2: null,
      text1: null,
      arr1: [
        "免费发布",
        "平台展示",
        "用户购买",
        "在线支付",
        "线下发货",
        "买家确认收货",
        "订单完成",
        "申请提现",
      ],
      dztype: [],
      dztype1: [],
      form: {
        siteid: 0,
        token: "",
        id: "",
        title: "",
        cat_id: "",
        industry_id: [],
        content: "",
        mobile: "",
        contacts: "",
        baseUrl: "",
      },
      dzlist: [],
      hylist: [],
      xy: false,
      mobileReg:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,

      editor: null,
      html: "",
      toolbarConfig: {
        excludeKeys: [
          "fullScreen",
          "uploadVideo",
          "emotion",
          "divider",
          "editVideoSize",
          "insertTable",
          "group-video",
          "imageWidth100",
          "imageWidth50",
          "imageWidth30",
          "editImage",
        ],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            server: window.baseUrl + "/api/Currency/dyupload",
            // headers: { Authorization: uploadFile.Authorization },
            "tenant-id": "1",
            fieldName: "dypic",
            customInsert(res, insertFn) {
              if (res.errcode == 1) {
                insertFn(
                  window.baseUrl + res.data,
                  "",
                  window.baseUrl + res.data
                );
              }
            },
          },
        },
      },
      mode: "default", // or 'simple'
    };
  },
  components: { Editor, Toolbar },
  mounted() {
    this.loading = this.showLoading();
    Promise.all([this.categorylist(), this.categorylist1()]).finally(() => {
      this.loading.close();
      this.loading = null;
      this.typePrevStatus == 1;
    });

    let query = getUrlData();
    if (query.id) {
      this.id = parseInt(query.id);
      this.hh();
    }
  },

  methods: {
    onCreated(editor) {
      console.log(editor.getAllMenuKeys());
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    handleChange() {
      if (this.dzlist.length > 1) {
        this.dzlist.splice(0, 1);
      }
    },
    handleChange1() {
      if (this.hylist.length > 1) {
        this.hylist.splice(0, 1);
      }
    },
    hh() {
      let loading = this.showLoading();
      this.token = JSON.parse(localStorage.getItem("userInfo")).token;
      this.$https
        .get("/api/Ideas/getdetail", {
          params: {
            token: this.token,
            id: this.id,
          },
        })
        .then((res) => {
          console.log(res);
          let {
            contacts,
            content,
            cat_id,
            id,
            mobile,
            fiery,
            industry_id,
            title,
          } = res.data;
          this.form.title = title;
          this.form.id = id;
          this.form.cat_id = cat_id;
          this.form.mobile = mobile;
          this.form.content = content;
          this.form.industry_id = industry_id;
          this.form.contacts = contacts;
          this.form.fiery = fiery;
          // 模拟 ajax 请求，异步渲染编辑器
          setTimeout(() => {
            this.html = decodeURI(content);
          }, 1500);
          if (cat_id.toString().indexOf(",") == -1) {
            this.dzlist = [cat_id];
          } else {
            let list = cat_id.toString().split(",");
            this.dzlist = list;
          }

          if (industry_id.toString().indexOf(",") == -1) {
            this.hylist = [industry_id];
          } else {
            let list = industry_id.toString().split(",");
            this.hylist = list;
          }
          // this.upDatatile(province);
          // this.upDataCity(city);
          // setTimeout(() => {
          //   this.upDataArea(district);
          // }, 350);
        })
        .finally(() => {
          loading.close();
        });
    },
    categorylist1() {
      let loading = this.showLoading();

      this.$https
        .get("/api/Currency/categorylist", {
          params: {
            typeid: 8,
          },
        })
        .then((res) => {
          this.dztype1 = [...res.data];
        })
        .finally(() => {
          loading.close();
        });
    },
    categorylist() {
      let loading = this.showLoading();

      this.$https
        .get("/api/Currency/categorylist", {
          params: {
            typeid: 7,
          },
        })
        .then((res) => {
          this.dztype = [...res.data];
        })
        .finally(() => {
          loading.close();
        });
    },
    getText(str) {
      return str
        .replace(/<[^<p>]+>/g, "") // 将所有<p>标签 replace ''
        .replace(/<[</p>$]+>/g, "") // 将所有</p>标签 replace ''
        .replace(/&nbsp;/gi, "") // 将所有 空格 replace ''
        .replace(/<[^<br/>]+>/g, ""); // 将所有 换行符 replace ''
    },
    isNull(str) {
      if (str == "") return true;
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      return re.test(str);
    },
    handleFb() {
      // 举例
      let text = this.getText(this.html);
      console.log(this.isNull(text));
      const reg = this.mobileReg;
      if (this.form.title == "") {
        Message.error("请输入标题");
        return;
      }

      if (this.dzlist.length == 0) {
        Message.error("请选择点子类型");
        return;
      }
      if (this.hylist.length == 0) {
        Message.error("请选择行业类别");
        return;
      }

      // if (this.html == "<p><br></p>") {
      //   Message.error("请输入点子内容");
      //   return;
      // }

      if (this.isNull(text)) {
        Message.error("请输入点子内容");
        return;
      }

      if (this.form.mobile == "") {
        Message.error("请输入手机号");
        return;
      } else if (!reg.test(this.form.mobile)) {
        Message.error("请输入正确的手机号");
        return;
      }
      if (this.form.contacts == "") {
        Message.error("请输入联系人");
        return;
      }
      if (this.xy == false) {
        Message.error("请勾选协议");
        return;
      }
      const editor = this.editor;

      let loading = this.showLoading();
      this.form.cat_id = this.dzlist.toString();
      this.form.industry_id = this.hylist.toString();
      this.form.token = JSON.parse(localStorage.getItem("userInfo"))?.token;
      this.form.siteid = JSON.parse(
        localStorage.getItem("userallinfo")
      )?.siteid;
      this.form.content = editor.getHtml();
      this.form.baseUrl = window.baseUrl;

      this.$https
        .post("/api/Ideas/ideas", {
          ...this.form,
        })
        .then((res) => {
          // console.log(res);
          Message.success(res.message);
          this.$refs.modal.manageModal(true);
          Object.keys(this.form).forEach((item) => {
            this.form[item] = "";
          });

          this.html = "";
          this.hylist = [];
          this.dzlist = [];
          this.xy = false;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
<style lang="scss" scoped>
:deep(.w-e-text-container) {
  color: #606266;

  font-family: "PingFang", "PingFang SC", "syht", "Microsoft YaHei", "黑体",
    "宋体", sans-serif;
}
:deep(.el-textarea__inner) {
  font-family: "PingFang", "PingFang SC", "syht", "Microsoft YaHei", "黑体",
    "宋体", sans-serif;
}
:deep(.el-input__inner) {
  font-family: "PingFang", "PingFang SC", "syht", "Microsoft YaHei", "黑体",
    "宋体", sans-serif;
}
.el-textarea {
  font-family: "PingFang", "PingFang SC", "syht", "Microsoft YaHei", "黑体",
    "宋体", sans-serif;
}
.release-modal {
  width: 658px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 84px 0 74px;
  text-align: center;
  .icon {
    margin-bottom: 10px;
  }
  p {
    font-size: 22px;
    line-height: 1.3;
  }
  .btns {
    margin-top: 32px;
  }
  .my-button {
    width: 130px;
    height: 42px;
    border-radius: 21px;
    & + .my-button {
      margin-left: 10px;
    }
  }
}
</style>