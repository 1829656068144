<template>
  <header>
    <div class="header-top">
      <div class="index-container">
        <div class="header-top-left">
          <img src="../assets/img/icon1.png" alt="" />
          <p class="line1">~欢迎来到旺点来！公司地址转让，壳公司地址转让网，闲置公司地址转让平台,资质地址转让,公司地址收购网！</p>
        </div>
        <div class="header-top-right">
          <div class="header-top-links">
            <el-popover
              placement="bottom-start"
              title="微信小程序"
              trigger="hover">
              <img style="width: 150px;" src="../assets/img/icon52.jpg">
              <a slot="reference" style="cursor: pointer;">手机版</a>
            </el-popover>
            <!-- <a href="">网站地图</a> -->
          </div>
          <div class="header-top-login">
            <img
              v-if="!$store.state.token"
              src="../assets/img/icon2.png"
              alt=""
            />
            <a href="personal.html" v-else>
              <img src="../assets/img/icon2.png" alt="" />
            </a>
            <div
              v-if="!$store.state.token"
              class="header-top-login-item"
              @click="
                $refs.modal.manageModal(true);
                formType = 1;
              "
            >
              登录
            </div>
            <span v-if="!$store.state.token"></span>
            <div
              v-if="!$store.state.token"
              class="header-top-login-item"
              @click="
                $refs.modal.manageModal(true);
                formType = 4;
              "
            >
              注册
            </div>
            <div
              style="margin-left: 20px; cursor: pointer"
              v-if="$store.state.token"
              @click="exit"
            >
              退出登录
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-middle index-container">
      <a href="/">
        <img class="header-middle-logo" src="@/assets/img/icon5.png" alt="" />
      </a>
      <div class="header-middle-right">
        <div class="header-middle-form">
          <div class="header-middle-search">
            <input
              class="header-middle-input"
              type="text"
              v-model="sendData.keyword"
              @keydown.enter="search"
              placeholder="输入你要找的地址 如：杭州市上城区"
            />
            <button class="header-middle-search-btn" @click="search">
              <img src="../assets/img/icon3.png" alt="" />找地址
            </button>
          </div>
          <el-dropdown>
            <button class="header-middle-button">
              免费发布<img src="../assets/img/icon4.png" alt="" />
            </button>
            <el-dropdown-menu slot="dropdown" class="header-nav-dropdown btn">
              <el-dropdown-item>
                <p @click="linkRelease('buy_release.html')">我想买</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p @click="linkRelease('address_release.html')">我想卖</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p @click="linkRelease('agent_release.html')">我能办</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p @click="linkRelease('syb_release.html')">我有点子</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="header-middle-hot line1">
          <span>热门搜索：</span>
          <span
            class="hover-color"
            v-for="item in hotArr"
            :key="item.id"
            @click="link('keyword', item.title)"
            >{{ item.title }}</span
          >
        </div>
      </div>
    </div>
    <div class="header-bottom index-container">
      <div class="header-select">
        <div
          class="header-select-container"
          :class="{
            'pointer-none active':
              pathname.includes('index') ||
              pathname.includes('news') ||
              pathname === '/',
            active: selectActive,
          }"
          @click="toggle()"
        >
          地址转让分类<img src="../assets/img/icon4.png" alt="" />
        </div>
        <div class="header-select-options">
          <div class="header-select-option">
            <span class="header-select-label">地址类别</span>
            <p class="header-select-ul">
              <template v-for="item in catArr">
                <a
                  :class="{ active: item.id == sendData.cat_id }"
                  v-if="item.id !== 0"
                  @click="link('cat_id', item.id)"
                  :key="item.id"
                  >{{ item.name }}</a
                >
              </template>
            </p>
            <div class="header-select-shadow">
              <span class="header-select-label">地址类别</span>
              <ul class="header-select-ul big">
                <template v-for="item in catArr">
                  <li
                    :class="{ active: item.id == sendData.cat_id }"
                    v-if="item.id !== 0"
                    @click="link('cat_id', item.id)"
                    :key="item.id"
                  >
                    <a>{{ item.name }}</a>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="header-select-option">
            <span class="header-select-label">省份</span>
            <p class="header-select-ul">
              <template v-for="item in provinceArr">
                <a
                  :class="{ active: item.id == sendData.province }"
                  v-if="item.id !== 0"
                  @click="link('province', item.id)"
                  :key="item.id"
                  >{{ item.name }}</a
                >
              </template>
            </p>
            <div class="header-select-shadow">
              <span class="header-select-label">省份</span>
              <ul class="header-select-ul big">
                <template v-for="item in provinceArr">
                  <li
                    :class="{ active: item.id == sendData.province }"
                    v-if="item.id !== 0"
                    @click="link('province', item.id)"
                    :key="item.id"
                  >
                    <a>{{ item.name }}</a>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="header-select-option">
            <span class="header-select-label">价格</span>
            <p class="header-select-ul">
              <template v-for="item in priceArr">
                <a
                  :class="{ active: item.id == sendData.price_id }"
                  v-if="item.id !== 0"
                  @click="link('price_id', item.id)"
                  :key="item.id"
                  >{{ item.title }}</a
                >
              </template>
            </p>
            <div class="header-select-shadow">
              <span class="header-select-label">价格</span>
              <ul class="header-select-ul big">
                <template v-for="item in priceArr">
                  <li
                    :class="{ active: item.id == sendData.price_id }"
                    v-if="item.id !== 0"
                    @click="link('price_id', item.id)"
                    :key="item.id"
                  >
                    <a>{{ item.title }}</a>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="header-select-option">
            <span class="header-select-label">地址性质</span>
            <p class="header-select-ul">
              <template v-for="item in natureArr">
                <a
                  :class="{ active: item.id == sendData.nature_id }"
                  v-if="item.id !== 0"
                  @click="link('nature_id', item.id)"
                  :key="item.id"
                  >{{ item.title }}</a
                >
              </template>
            </p>
            <div class="header-select-shadow">
              <span class="header-select-label">地址性质</span>
              <ul class="header-select-ul big">
                <template v-for="item in natureArr">
                  <li
                    :class="{ active: item.id == sendData.nature_id }"
                    v-if="item.id !== 0"
                    @click="link('nature_id', item.id)"
                    :key="item.id"
                  >
                    <a>{{ item.title }}</a>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="header-select-option">
            <span class="header-select-label">热门标签</span>
            <p class="header-select-ul">
              <template v-for="item in hottagsArr">
                <a
                  :class="{ active: item.id == sendData.hottags }"
                  @click="link('hottags', item.id)"
                  v-if="item.id !== 0"
                  :key="item.id"
                  >{{ item.title }}</a
                >
              </template>
            </p>
            <div class="header-select-shadow">
              <span class="header-select-label">热门标签</span>
              <ul class="header-select-ul big">
                <template v-for="item in hottagsArr">
                  <li
                    :class="{ active: item.id == sendData.hottags }"
                    @click="link('hottags', item.id)"
                    v-if="item.id !== 0"
                    :key="item.id"
                  >
                    <a>{{ item.title }}</a>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="header-select-option">
            <span class="header-select-label">购买模式</span>
            <p class="header-select-ul">
              <template v-for="item in sellArr">
                <a
                  :class="{ active: item.id == sendData.sell_model }"
                  v-if="item.id !== 0"
                  @click="link('sell_model', item.id)"
                  :key="item.id"
                  >{{ item.name }}</a
                >
              </template>
            </p>
            <div class="header-select-shadow">
              <span class="header-select-label">购买模式</span>
              <ul class="header-select-ul big">
                <template v-for="item in sellArr">
                  <li
                    :class="{ active: item.id == sendData.sell_model }"
                    v-if="item.id !== 0"
                    @click="link('sell_model', item.id)"
                    :key="item.id"
                  >
                    <a>{{ item.name }}</a>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="header-nav">
        <template v-for="(item, index) in navs">
          <a
            class="header-nav-item"
            :class="{
              active:
                pathname.includes(item.path) ||
                (pathname == '/' && item.path == 'index'),
            }"
            :key="index"
            v-if="item.title !== '免费发布'"
            :href="item.path == 'index' ? '/' : item.path + '.html'"
            >{{ item.title }}</a
          >
          <el-dropdown class="header-nav-item" v-else :key="index">
            <span class="el-dropdown-link">{{ item.title }}</span>
            <el-dropdown-menu slot="dropdown" class="header-nav-dropdown">
              <el-dropdown-item>
                <p @click="linkRelease('buy_release.html')">我想买</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p @click="linkRelease('address_release.html')">我想卖</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p @click="linkRelease('agent_release.html')">我能办</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p @click="linkRelease('syb_release.html')">我有点子</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </div>
    </div>
    <Modal ref="modal">
      <div class="modal-login">
        <img
          @click="
            $refs.modal.close();
            $refs.form.resetFields();
          "
          class="close-icon"
          src="../assets/img/z-icon9.png"
          alt=""
        />
        <p class="title">{{ formTypeObj[formType].title }}</p>
        <el-form ref="form" class="form" :model="form" :rules="rules">
          <el-form-item
            class="form-input"
            v-if="formTypeObj[formType].render.includes('mobile')"
            prop="mobile"
          >
            <img class="form-icon" src="../assets/img/icon30.png" />
            <el-input
              maxlength="11"
              placeholder="请输入手机号"
              v-model="form.mobile"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="form-input"
            v-if="formTypeObj[formType].render.includes('code')"
            prop="code"
          >
            <img class="form-icon" src="../assets/img/icon32.png" />
            <el-input placeholder="请输入验证码" v-model="form.code"></el-input>
            <span
              class="yzm"
              @click="getCode"
              :class="{ 'pointer-none': yzmText !== '获取验证码' }"
              >{{ yzmText }}</span
            >
          </el-form-item>
          <el-form-item
            class="form-input"
            v-if="formTypeObj[formType].render.includes('password')"
            prop="password"
          >
            <img class="form-icon" src="../assets/img/icon31.png" />
            <el-input
              maxlength="20"
              type="password"
              placeholder="请输入密码"
              v-model="form.password"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="form-input"
            v-if="formTypeObj[formType].render.includes('newPassword')"
            prop="newPassword"
          >
            <img class="form-icon" src="../assets/img/icon31.png" />
            <el-input
              maxlength="20"
              type="password"
              placeholder="设置6-20位登录密码"
              v-model="form.newPassword"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="form-input"
            v-if="formTypeObj[formType].render.includes('newPasswords')"
            prop="newPasswords"
          >
            <img class="form-icon" src="../assets/img/icon31.png" />
            <el-input
              maxlength="20"
              type="password"
              placeholder="请再次输入新密码"
              v-model="form.newPasswords"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="form-input"
            v-if="formTypeObj[formType].render.includes('vertify')"
            prop="vertify"
          >
            <img class="form-icon" src="../assets/img/icon32.png" />
            <el-input
              maxlength="4"
              placeholder="请输入验证码"
              v-model="form.vertify"
            ></el-input>
            <myCode
              ref="myCode"
              v-model="yzmCode"
              :key="formType"
              class="yzm"
            ></myCode>
          </el-form-item>
          <div
            class="form-xy my-radio"
            v-if="formTypeObj[formType].render.includes('xy')"
          >
            <input type="checkbox" v-model="form.xy" id="item1" name="type" />
            <div class="checked"></div>
            <label for="item1"
              >勾选同意<a href="about.html?id=6" class="xy"
                >《用户服务协议》</a
              ></label
            >
          </div>
          <el-form-item>
            <el-button
              class="form-submit"
              type="primary"
              v-throttle="rulesLogin"
              >{{ formType == 4 ? "注册" : "登录" }}</el-button
            >
          </el-form-item>
          <div class="form-list" v-if="formType == 1 || formType == 3">
            <span class="list-item" v-if="formType == 1" @click="formType = 3"
              >验证码登录</span
            >
            <span class="list-item" v-if="formType == 3" @click="formType = 1"
              >密码登录</span
            >
            <span class="list-item" @click="formType = 2">忘记密码？</span>
          </div>
          <p class="form-bottom" v-if="formType == 4">
            已有账号，<span @click="formType = 1">立即登录</span>
          </p>
          <p class="form-bottom" v-if="formType == 1 || formType == 2">
            没有账号，<span @click="formType = 4">立即注册</span>
          </p>
        </el-form>
      </div>
    </Modal>
  </header>
</template>

<script>
import Modal from "./Modal.vue";
import myCode from "./myCode.vue";
import Bus from "../util/bus";
import { getRegionList, getUrlData } from "@/util/utils";
import { Message } from "element-ui";
export default {
  name: "Header",
  components: { Modal, myCode },
  data() {
    const mobileRules = (rule, value, callback) => {
      const reg = this.mobileReg;
      if (!value) {
        callback(new Error("请输入手机号"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号!"));
      } else {
        callback();
      }
    };
    const newPasswordsRules = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const codeRules = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入图形验证码"));
      } else if (value !== this.yzmCode) {
        callback(new Error("图形验证码不正确!"));
        this.$refs.myCode.drawPic();
      } else {
        callback();
      }
    };
    return {
      mobileReg:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      navs: [
        {
          title: "首页",
          path: "index",
        },
        {
          title: "找地址",
          path: "address",
        },
        {
          title: "找代办",
          path: "agent",
        },
        {
          title: "求购",
          path: "buy",
        },
        {
          title: "创业点子",
          path: "syb",
        },
        {
          title: "新闻资讯",
          path: "news",
        },
        {
          title: "商务合作",
          path: "coo",
        },
        {
          title: "免费发布",
          path: "",
        },
      ],
      selectActive: false,
      form: {
        mobile: "",
        password: "",
        newPassword: "",
        newPasswords: "",
        vertify: "",
        xy: false,
        code: "",
      },
      formType: 1,
      formTypeObj: {
        1: {
          title: "旺点来登录",
          render: ["mobile", "password", "vertify", "xy"],
        },
        2: {
          title: "忘记密码",
          render: ["mobile", "code", "newPassword", "newPasswords", "vertify"],
        },
        3: {
          title: "旺点来登录",
          render: ["mobile", "code", "vertify", "xy"],
        },
        4: {
          title: "旺点来注册",
          render: [
            "mobile",
            "code",
            "newPassword",
            "newPasswords",
            "vertify",
            "xy",
          ],
        },
      },
      rules: {
        mobile: [{ validator: mobileRules, trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        newPassword: [
          { required: true, message: "请输入6-20位登录密码", trigger: "blur" },
          { min: 6, max: 20, message: "请输入6-20位登录密码", trigger: "blur" },
        ],
        newPasswords: [{ validator: newPasswordsRules, trigger: "blur" }],
        vertify: [{ validator: codeRules, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      yzmText: "获取验证码",
      yzmTimer: null,
      yzmCode: null,
      catArr: [],
      provinceArr: [],
      priceArr: [],
      natureArr: [],
      hottagsArr: [],
      sellArr: [],
      sendData: {
        keyword: "",
        cat_id: 0,
        province: 0,
        price_id: 0,
        nature_id: 0,
        hottags: 0,
        sell_model: 0,
      },
      hotArr: [],
    };
  },
  mounted() {
    Bus.$on("login", (data) => {
      this.$refs.modal.manageModal(true);
      this.formType = data;
    });
    this.getList();
    this.$https
      .post("/api/Currency/categorylist", { typeid: 11 })
      .then(({ data }) => {
        this.hotArr = data;
      });
    if (this.$store.state.nowPathName == "address") {
      let query = getUrlData();
      if (query.keyword) {
        this.sendData.keyword = decodeURI(query.keyword);
      }
    }
  },
  methods: {
    exit() {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("userallinfo");
      this.$store.state.userInfo = {};
      this.$store.state.token = null;
      Message.success("退出成功");
      setTimeout(() => {
        location.href = "/";
      }, 1000);
    },
    search() {
      if (
        !this.sendData.keyword &&
        !this.$store.state.nowPathName == "address"
      ) {
        Message.error("请输入关键词");
        return;
      }
      this.link("keyword", this.sendData.keyword);
    },
    link(type, id) {
      if (this.$store.state.nowPathName == "address") {
        let data = {
          type: type,
          id: id,
        };
        this.sendData[type] = id;
        Bus.$emit("addressLink", data);
      } else {
        location.href = `/address.html?${type}=${id}`;
      }
    },
    async getList() {
      this.provinceArr = await getRegionList(0, 1, 1);
      this.provinceArr = [{ id: 0, name: "不限" }, ...this.provinceArr];
      const urls = [];
      let typeid = [0, 2];
      typeid.map((item) => {
        urls.push(this.$https.post("/api/Address/category", { typeid: item }));
      });
      typeid = [1, 2, 3];
      typeid.map((item) => {
        urls.push(
          this.$https.post("/api/Currency/categorylist", { typeid: item })
        );
      });
      Promise.all(urls).then((res) => {
        this.catArr = res[0].data;
        this.sellArr = res[1].data;
        this.priceArr = res[2].data;
        this.natureArr = res[3].data;
        this.hottagsArr = res[4].data;
        const data = [...res, this.provinceArr];
        Bus.$emit("addressScreen", data);
      });
    },
    login(loading) {
      let data = null;
      let url = "";
      let message = "登录成功";
      if (this.formType == 1) {
        data = {
          mobile: this.form.mobile,
          password: this.form.password,
        };
        url = "/api/Users/loginPwd";
      } else if (this.formType == 2) {
        data = {
          forget_mobile: this.form.mobile,
          password: this.form.newPassword,
          repassword: this.form.newPasswords,
          code: this.form.code,
        };
        url = "/api/Users/forgetPwd";
        message = "修改成功";
      } else if (this.formType == 3) {
        data = {
          mobile: this.form.mobile,
          code: this.form.code,
        };
        url = "/api/Users/loginCode";
      } else if (this.formType == 4) {
        data = {
          registermobile: this.form.mobile,
          password: this.form.newPasswords,
          code: this.form.code,
        };
        url = "/api/Users/register";
        message = "注册成功";
      }
      this.$https
        .post(url, data)
        .then((res) => {
          this.$message.success(message);
          this.$refs.form.resetFields();
          if (this.formType == 4 || this.formType == 2) {
            this.formType = 1;
          } else {
            this.$refs.modal.close();
            this.$store.state.userInfo = res.data;
            this.$store.state.token = res.data.token;
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            Bus.$emit("getUserInfo", true);
            window.location.reload();
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    rulesLogin() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.xy || this.formType == 2) {
            const loading = this.showLoading({
              target: ".modal-login .form-submit",
            });
            this.login(loading);
          } else {
            this.$message.error("请勾选协议");
          }
        } else {
          return false;
        }
      });
    },
    getCode() {
      if (!this.mobileReg.test(this.form.mobile)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      let type = "";
      if (this.formType == 2) {
        type = "forgetPwd";
      } else if (this.formType == 3) {
        type = "login";
      } else if (this.formType == 4) {
        type = "register";
      }
      const data = {
        mobile: this.form.mobile,
        type: type,
      };
      this.$https.post("/api/Users/getSmsSend", data).then(() => {
        this.$message.success("发送成功");
        let miao = 60;
        this.yzmText = `${miao}秒后重试`;
        this.yzmTimer = setInterval(() => {
          miao = miao - 1;
          if (miao >= 0) {
            this.yzmText = `${miao}秒后重试`;
          } else {
            this.yzmText = `获取验证码`;
            clearInterval(this.yzmTimer);
            this.yzmTimer = null;
          }
        }, 1000);
      });
    },
    toggle() {
      this.selectActive = !this.selectActive;
    },
    linkRelease(url) {
      if (this.$store.state.token) {
        location.href = url;
      } else {
        Message.error("请先登录");
      }
    },
  },
  watch: {
    formType() {
      let form = {
        mobile: this.form.mobile,
        password: "",
        newPassword: "",
        newPasswords: "",
        vertify: "",
        xy: this.form.xy,
        code: "",
      };
      this.form = form;
      this.$nextTick(() => {
        this.$refs.myCode.drawPic();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>