<template>
    <div
        class="my-pagination"
        :class="type">
        <el-pagination
            :page-size="pageSize"
            :current-page="modelvalue"
            layout="prev, pager, next"
            :pager-count="pagerCount"
            :total="total"
            hide-on-single-page
            @current-change="change">
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: 'MyPagination',
    model: {
        prop: 'modelvalue',
        event: 'change'
    },
    props: {
        type: { // 分页器尺寸，默认40*40，small为35*35
            type: String,
            default: 'default'
        },
        pagerCount: { // 分页器的按钮数量
            type: Number,
            default: 7
        },
        pageSize: { // 分页器每页显示条目个数
            type: Number,
            default: 10
        },
        total: { // 总条目数
            type: Number,
            default: 100
        },
        modelvalue: { // v-model相关的参数
            type: Number,
            default: 1
        }
    },
    methods: {
        change (index) {
            this.$emit('change', index)
        }
    }
}
</script>

<style lang="scss" scoped>
.my-pagination{
    &.small{
        ::v-deep .el-pagination{
            .el-pager li{
                width: 35px;
                line-height: 35px;
                height: 35px;
                margin: 0 4px;
                font-size: 14px;
                font-size: 14px;
                min-width: initial;
            }
            button{
                width: 35px;
                height: 35px;
            }
        }
    }
    ::v-deep .el-pagination{
        padding: 0;
        text-align: center;
        .el-pager li{
            width: 40px;
            line-height: 40px;
            height: 40px;
            background-color: #fff;
            padding: 0;
            color: #666666;
            margin: 0 8px;
            border: solid 1px #e6e6e6;
            font-size: 16px;
            &.active,&:hover{
                color: #fff;
                background-color: var(--color);
                border: none;
            }
        }
        button{
            width: 40px;
            height: 40px;
            border: solid 1px #e6e6e6;
            padding: 0;
            &:hover:not(:disabled){
                color: #fff;
                border: none;
                background-color: var(--color);
            }
        }
        .el-icon{
            font-size: 13px;
        }
    }
}
</style>