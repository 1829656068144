<template>
    <div>
        <div class="news-detail">
        <div class="index-container">
            <div class="crumbs">
                <img src="@/assets/img/z-icon1.png">
                当前所在位置：<a href="/">旺点来</a> 
                &gt;<a href="/news.html">新闻资讯</a>
            </div>
            <div class="flex">
                <Left></Left>
                
                <div class="news-detail-main">
                    <div class="detail-main-top">
                        <p class="detail-main-title">{{ title }}</p>
                    </div>
                    <div class="detail-main-ul">
                        <div class="detail-main-li" v-for="item in list" :key="item.id">
                            <a :href="'/new_detail.html?id='+item.id" class="flex just-start">
                                <img :src="item.picurl" class="news-li__img">
                                <div class="news-li-content">
                                    <p class="news-li-time">{{ item.add_time }}</p>
                                    <p class="news-li-title">{{ item.title }}</p>
                                    <p class="news-li-desc">{{ item.description }}</p>
                                    <div class="flex just-start align-center news-li-more">
                                        查看详情
                                        <img src="@/assets/img/z-icon28.png" class="news-li-more__img">
                                    </div>
                                </div>
                            </a>
                        </div>
                        <!-- <div class="detail-main-li">
                            <a href="" class="flex just-start">
                                <img src="@/assets/img/img6.jpg" class="news-li__img">
                                <div class="news-li-content">
                                    <p class="news-li-time">2023.10.23</p>
                                    <p class="news-li-title">杭州人向往的公园｜杭铁越秀·星缦和润开启教育护航与场景探索</p>
                                    <p class="news-li-desc">露营、遛娃、采风、运动……是否有这么一个地方能把这些生活中的快乐元素全部集齐露营、遛娃、采风、运动……是否有这么一个地方能把这</p>
                                    <div class="flex just-start align-center news-li-more">
                                        查看详情
                                        <img src="@/assets/img/z-icon28.png" class="news-li-more__img">
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="detail-main-li">
                            <a href="" class="flex just-start">
                                <img src="@/assets/img/img6.jpg" class="news-li__img">
                                <div class="news-li-content">
                                    <p class="news-li-time">2023.10.23</p>
                                    <p class="news-li-title">杭州人向往的公园｜杭铁越秀·星缦和润开启教育护航与场景探索</p>
                                    <p class="news-li-desc">露营、遛娃、采风、运动……是否有这么一个地方能把这些生活中的快乐元素全部集齐露营、遛娃、采风、运动……是否有这么一个地方能把这</p>
                                    <div class="flex just-start align-center news-li-more">
                                        查看详情
                                        <img src="@/assets/img/z-icon28.png" class="news-li-more__img">
                                    </div>
                                </div>
                            </a>
                        </div> -->
                    </div>
                    <div class="mt25">
                        <MyPagination :total="count"></MyPagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import Left from './component/left.vue'
export default {
    components: {Left},
    data() {
        return {
            cate: null,
            type: 0,
            title: '全部',
            list: [],
            page: 1,
            limit: 10,
            cateid: 0,
            count: 0
        }
    },
    created() {
        this.cateid = this.getQueryParameter('cateid') || 0
        this.title = this.getQueryParameter('title') || '全部'
        this.type = this.getQueryParameter('type') || 0
        //获取新闻列表
        this.getNewsList()
    },
    methods: {
        
        getNewsList() {
            const loading = this.showLoading()
            const url = `/api/News/list?catid=${this.cateid}&page=${this.page}&limit=${this.limit}&type=${this.type}`
            this.$https.get(url).then(res => {
                this.list = res.data
                this.count = res.count
            }).finally(() => {
                loading.close()
            })
        },
        //获取query
        getQueryParameter(key) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            return urlParams.get(key);
        }
    }
}
</script>