<template>
  <el-select
    :value="modelValue"
    :style="style"
    clearable
    :class="readOnly ? 'readOnly' : ''"
    :placeholder="placeholder"
    size="large"
    :disabled="disabled"
    @change="select"
  >
    <el-option
      v-for="item in data"
      :key="item[valueName ? valueName : 'name']"
      :label="item[labelName  ? labelName  : 'name']"
      :value="item[valueName ? valueName : 'id']"
    />
  </el-select>
</template>

<script>
export default {
  name: "mySelect",
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    data: Array,
    placeholder: String,
    width: String,
    height: String,
    borderColor: String,
    labelName: String,
    valueName: String,
    disabled: Boolean,
    readOnly: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Number, String],
    },
  },
  data() {
    return {
      value: null,
      style: null,
    };
  },
  mounted() {
    // console.log(this.data);
    const style = `
        width: ${this.width}px;
        --height: ${this.height ? this.height : "40"}px;
        --el-border-color: ${this.borderColor ? this.borderColor : "#E6E6E6"};
        `;
    this.style = style;
  },
  methods: {
    select(val) {
      this.$emit("change", val);
      // console.log(val);
      // this.$emit('select',val)
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-select {
  .el-input__wrapper {
    height: var(--height);
    padding: 1px 18px;
  }
  .el-input__inner {
    font-size: 14px;
  }
  .el-icon-arrow-up:before {
    display: inline-block;
    font-size: 9px;
    margin-top: 4px;
    vertical-align: text-top;
    content: "";
    width: 10px;
    height: 6px;
    background: url(../assets/img/arrow-xiangxia.png) no-repeat center center;
    transform: rotateZ(180deg);
    background-size: 100% 100%;
    opacity: 0.6;
  }
  // .el-input .el-select__caret{
  //     transform: rotateZ(0deg);
  //     &.is-reverse{
  //         transform: rotateZ(180deg);
  //     }
  // }
  .el-input.is-focus .el-input__inner,
  .el-input__inner:focus {
    border-color: var(--color);
  }
}
.readOnly {
  pointer-events: none;
  ::v-deep .select-trigger {
    .el-input__suffix {
      display: none;
    }
    .el-input__wrapper {
      box-shadow: none;
      padding: 0;
      background-color: transparent;
    }
    .el-input__inner {
      text-align: center;
    }
  }
}
</style>